import React from "react";
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";

import { isScopes } from "./../../services/Auth";

import PositionService from "./../../services/Position";

const LastExporterErrorSelectedItemCommandItem = ({
  options: {
    exporterError
  },
  props: {
    currentUser,
    databaseTest
  },
  funcs: {
    disableDatabaseTest,
    disableLoader,
    enableDatabaseTest,
    enableLoader
  }
}) => {
  const { t } = useTranslation();

  const resendExporter = async (id) => {
    if(databaseTest) {
      return await PositionService.resendExporterTest({ id, token: currentUser.token });
    }
    return await PositionService.resendExporter({ id, token: currentUser.token });
  };

  return (
    <ul>
      {
        isScopes({ currentUser, scopes: ["is:master"], every: false }) ? (
          <li>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>{t("Title.ExporterError.Resend")}</Tooltip>}
              trigger={isMobile ? null : ["hover", "focus"]}
            >
              <button
                className={`commands-button ${(exporterError.response.status || exporterError.response.resending) ? "active" : ""}`}
                type="button"
                onClick={async () => {
                  if(!exporterError.response.status && !exporterError.response.resending) {
                    enableLoader();
                    await resendExporter(exporterError.id);
                    disableLoader();
                  }
                }}
              >
                <div className="commands-icon"><i className="fas fa-cloud-upload-alt" /></div>
              </button>
            </OverlayTrigger>
          </li>
        ) : null
      }
    </ul>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    databaseTest: state.databaseTest.databaseTest
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastExporterErrorSelectedItemCommandItem);
