import DateUtil from "./../../utils/Date";
import { getNipleFraud, getNipleTestCheck, getNipleOperation, getNipleOperationBasic, getReason } from "./../../utils/Position";
import { isScopes } from "./../Auth";

export function getCols(currentUser) {
  let cols = [{
    type: "string",
    label: "Address",
    width: "25%"
  }, {
    type: "string",
    label: "Date",
    width: "25%"
  }, {
    type: "string",
    label: "Speed",
    width: "25%"
  }, {
    type: "string",
    label: "Title.Niple.Maintenance",
    width: "25%"
  }];
  if(isScopes({ currentUser, scopes: ["read:patrimonies:basic"], every: false })) {
    cols = [{
      type: "string",
      label: "Address",
      width: "20%"
    }, {
      type: "string",
      label: "Date",
      width: "20%"
    }, {
      type: "string",
      label: "Speed",
      width: "20%"
    }, {
      type: "string",
      label: "Title.Niple.Maintenance",
      width: "20%"
    }, {
      type: "string",
      label: "Title.Operation",
      width: "20%"
    }];
  }
  else if(isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })) {
    cols = [{
      type: "string",
      label: "Address",
      width: "10%"
    }, {
      type: "string",
      label: "Date",
      width: "10%"
    }, {
      type: "string",
      label: "Ignition",
      width: "10%"
    }, {
      type: "string",
      label: "Speed",
      width: "5%"
    }, {
      type: "string",
      label: "Title.Niple.Maintenance",
      width: "5%"
    }, {
      type: "string",
      label: "Title.Reason",
      width: "5%"
    }, {
      type: "string",
      label: "Title.Operation",
      width: "5%"
    }, {
      type: "string",
      label: "Title.Niple.Fraud",
      width: "10%"
    }, {
      type: "string",
      label: "Title.Niple.Test",
      width: "10%"
    }, {
      type: "string",
      label: "Title.Niple.Gyroscope.Descritive",
      width: "10%"
    }, {
      type: "string",
      label: "Title.Hatch.Gyroscope.Descritive",
      width: "10%"
    }, {
      type: "string",
      label: "Title.Hatch.ExternalPower",
      width: "5%"
    }, {
      type: "string",
      label: "Title.Hatch.Distance",
      width: "5%"
    }];
  }
  return cols;
}

export function getRows(docs, t, currentUser) {
  const rows = [];
  docs.map(item => {
    const nipleBasic = isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? false : true;
    const niplesOperation = [];
    const niplesFraud = [];
    const niplesTest = [];
    const niplesGyroscope = [];

    if(typeof item.nipleNetwork.niples !== "undefined") {
      item.nipleNetwork.niples.map((niple, index) => {
        niplesOperation.push(t(nipleBasic ? getNipleOperationBasic(item, niple) : getNipleOperation(item, niple)));
        niplesFraud.push(t(getNipleFraud(item, niple)));
        niplesTest.push(t(getNipleTestCheck(item, niple)));
        niplesGyroscope.push(`${t("Title.Niple.Gyroscope.X")}: ${niple.gyroscope ? niple.gyroscope.x : -1} | ${t("Title.Niple.Gyroscope.Y")}: ${niple.gyroscope ? niple.gyroscope.y : - 1} | ${t("Title.Niple.Gyroscope.Z")}: ${niple.gyroscope ? niple.gyroscope.z : -1}`);
        return true;
      });
    }

    const hatchsGyroscope = [];
    const hatchsExternalPower = [];
    const hatchsDistance = [];

    if(typeof item.nipleNetwork.hatchs !== "undefined") {
      item.nipleNetwork.hatchs.map((hatch, index) => {
        hatchsGyroscope.push(`${t("Title.Hatch.Gyroscope.X")}: ${hatch.gyroscope ? hatch.gyroscope.x : -1} | ${t("Title.Hatch.Gyroscope.Y")}: ${hatch.gyroscope ? hatch.gyroscope.y : -1} | ${t("Title.Hatch.Gyroscope.Z")}: ${hatch.gyroscope ? hatch.gyroscope.z : -1}`);
        hatchsExternalPower.push(`${!hatch.externalPower ? t("Title.Active") : t("Title.Inactive")}`);
        hatchsDistance.push(hatch.sensors.distance);
        return true;
      });
    }

    if(isScopes({ currentUser, scopes: ["read:patrimonies:basic"], every: false })) {
      return rows.push([{
        id: item.id,
        value: item.gps.address
      }, {
        value: DateUtil.to({ date: new Date(item.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})
      }, {
        value: `${item.gps.speed} Km/h`
      }, {
        value: item.tracker.maintenance ? t("Active") : t("Inactive")
      }, {
        value: niplesOperation.join(", ")
      }]);
    }
    else if(isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })) {
      return rows.push([{
        id: item.id,
        value: item.gps.address
      }, {
        value: DateUtil.to({ date: new Date(item.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})
      }, {
        value: item.tracker.ignition ? t("IgnitionOn") : t("IgnitionOff")
      }, {
        value: `${item.gps.speed} Km/h`
      }, {
        value: item.tracker.maintenance ? t("Active") : t("Inactive")
      }, {
        value: `${t(getReason(item))} (${item.gps.reason})`
      }, {
        value: niplesOperation.join(", ")
      }, {
        value: niplesFraud.join(", ")
      }, {
        value: niplesTest.join(", ")
      }, {
        value: niplesGyroscope.join(", ")
      }, {
        value: hatchsGyroscope.join(", ")
      }, {
        value: hatchsExternalPower.join(", ")
      }, {
        value: hatchsDistance.join(", ")
      }]);
    }
    return rows.push([{
      id: item.id,
      value: item.gps.address
    }, {
      value: DateUtil.to({ date: new Date(item.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})
    }, {
      value: `${item.gps.speed} Km/h`
    }, {
      value: item.tracker.maintenance ? t("Active") : t("Inactive")
    }]);
  });
  return rows;
}
