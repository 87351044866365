import React from "react";
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import "./index.css";

import { Creators as LastPositionActions } from "./../../store/ducks/lastPositions";

const LastPositionPatrimonyType = ({
  props: {
    lastPositionPatrimonyType,
    lastPositionNipleEnabled,
    lastPositionVehicleEnabled
  },
  funcs: {
    setLastPositionPatrimonyType
  }
}) => {
  const { t } = useTranslation();
  return (
    <ul className="last-position-patrimony-type">
      {
        (lastPositionNipleEnabled && (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Patrimony.Type.NipleView")}</Tooltip>}
            trigger={isMobile ? null : ["hover", "focus"]}
          >
            <li className={lastPositionPatrimonyType ? "active" : ""} onClick={() => setLastPositionPatrimonyType(true)}>
              {t("Title.Patrimony.Type.Niple") }
            </li>
          </OverlayTrigger>
        ))}
      {
        (lastPositionVehicleEnabled && (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Patrimony.Type.VehicleView")}</Tooltip>}
            trigger={isMobile ? null : ["hover", "focus"]}
          >
            <li className={!lastPositionPatrimonyType ? "active" : ""} onClick={() => setLastPositionPatrimonyType(false)}>
              { t("Title.Patrimony.Type.Vehicle") }
            </li>
          </OverlayTrigger>
        ))
      }
    </ul>
  );
};

const mapStateToProps = state => ({
  props: {
    lastPositionPatrimonyType: state.lastPositions.positionsSearchQuery.niple,
    lastPositionNipleEnabled: state.lastPositions.positionsNipleEnabled,
    lastPositionVehicleEnabled: state.lastPositions.positionsVehicleEnabled
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LastPositionActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastPositionPatrimonyType);
