import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import { Creators as NotificationsActions } from "../../store/ducks/notifications";

import { isScopes } from "../../services/Auth";
import { maskCPF } from "../../utils/CPF";
import { maskCNPJ } from "../../utils/CNPJ";
import Date from "../../utils/Date";
import { isValidForm } from "../../utils/Form";
import { isEmpty, ucFirstAll } from "../../utils/String";
import { clearObject, mergeObject } from "../../utils/Object";

import ClientService from "../../services/Client";
import ClientSchemaReduced from "../../services/Client/SchemaReduced";
import VirtualFenceService from "../../services/VirtualFence";
import VirtualFenceSchema from "../../services/VirtualFence/Schema";

import VirtualFenceMap from "./VirtualFenceMap";

const VirtualFence = ({
  options,
  props: { currentUser },
  funcs: { addNotice }
}) => {
  const { t } = useTranslation();
  const clientService = new ClientService();
  const virtualFenceService = new VirtualFenceService();

  const {
    virtualFence: __virtualFence = {},
    close: __close = () => {}
  } = options;

  const [virtualFence, setVirtualFence] = useState(mergeObject(JSON.parse(JSON.stringify(VirtualFenceSchema)), __virtualFence));

  useEffect(() => {
    setTimeout(() => {
      if(!isScopes({ currentUser, scopes: [
        "is:master",
        "read:virtualFences:all"
      ], every: false })) {
        setClientSelectedOption({
          dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client),
          label: currentUser.client.type === "LEG" ? (`${currentUser.client.legal.socialReason} | CNPJ: ${maskCNPJ(currentUser.client.legal.cnpj)}`) : (`${currentUser.client.personal.name} | CPF: ${maskCPF(currentUser.client.personal.cpf)}`),
          value: currentUser.client.id
        });
        setVirtualFence(prevState => ({
          ...prevState,
          client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client)
        }));
      }
    }, 1000);
  }, []); /* eslint-disable-line */

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [clientSelectedOption, setClientSelectedOption] = useState(virtualFence.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), virtualFence.client),
    label: virtualFence.client.type === "LEG" ? (`${virtualFence.client.legal.socialReason} | CNPJ: ${maskCNPJ(virtualFence.client.legal.cnpj)}`) : (`${virtualFence.client.personal.name} | CPF: ${maskCPF(virtualFence.client.personal.cpf)}`),
    value: virtualFence.client.id
  });

  const [globalOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [patrimonyTypeOptions] = useState([
    { value: "ALL", label: t("Title.Patrimony.Type.All") },
    { value: "ATV", label: t("Title.Patrimony.Type.Niple") },
    { value: "OTH", label: t("Title.Patrimony.Type.Vehicle") }
  ]);

  const [typeOptions] = useState([
    { value: "DEF", label: t("Title.VirtualFence.Default") },
    { value: "RES", label: t("Title.VirtualFence.Rest") },
    { value: "RIS", label: t("Title.VirtualFence.Risk") },
    { value: "WAI", label: t("Title.VirtualFence.Wait") }
  ]);

  const [running, setRunning] = useState(false);

  const buttonDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? (`${e.legal.socialReason} | CNPJ: ${maskCNPJ(e.legal.cnpj)}`) : `${e.personal.name} | CPF: ${maskCPF(e.personal.cpf)}`,
        value: e.id
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let { valid, message } = isValidForm(e);
      if(!valid) {
        if(!message) {
          message = t("Error.Fields.Empty");
        }
        addNotice({
          title: t("Title.VirtualFence"),
          content: message
        });
      }
      else {
        let response = false;
        if(isCreate()) {
          response = await virtualFenceService.create({
            data: handleSubmitForm({ virtualFence }),
            token: currentUser.token
          });
        }
        else {
          response = await virtualFenceService.update({
            id: virtualFence.id,
            data: handleSubmitForm({ virtualFence }),
            token: currentUser.token
          });
        }

        if(response.success) {
          addNotice({
            title: t("Title.VirtualFence"),
            content: isCreate() ? t("Success.Insert") : t("Success.Update")
          });
          setTimeout(() => {
            __close();
          }, 200);
        }
        else {
          addNotice({
            title: t("Title.VirtualFence"),
            content: t(`Error.VirtualFence.${response.error.type}.${ucFirstAll(response.error.details[0].path)}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ virtualFence }) => {
    let newVirtualFence = Object.assign({}, JSON.parse(JSON.stringify(virtualFence)));
    newVirtualFence = clearObject({ data: newVirtualFence });
    newVirtualFence.id = undefined;
    return newVirtualFence;
  };

  const inputDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const isCreate = () => {
    return virtualFence.id === "";
  };

  return (
    <Form id="forms-virtual-fence" noValidate onSubmit={handleSubmit}>
      <Card>
        <Card.Header>{t("Title.GeneralData")}</Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-virtual-fence-active">
                <Form.Label>{t("Label.Active")}:</Form.Label>
                <Select
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  isDisabled={inputDisabled()}
                  onChange={e => {
                    const active = e.value;
                    setVirtualFence(prevState => ({
                      ...prevState,
                      active
                    }));
                  }}
                  options={activeOptions}
                  value={activeOptions.find(x => x.value === virtualFence.active)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-user-client">
                <Form.Label>{t("Title.Client")}:</Form.Label>
                <AsyncSelect
                  className={`menu-outer-bottom ${!isEmpty(virtualFence.client.id) ? "is-valid" : "is-invalid"}`}
                  classNamePrefix="select"
                  cacheOptions
                  defaultOptions
                  isDisabled={inputDisabled() || !isScopes({
                    currentUser,
                    scopes: [
                      "is:master",
                      "read:virtualFences:all"
                    ],
                    every: false
                  })}
                  loadOptions={clientLoadOptions}
                  loadingMessage={() => t("React.Select.Wait")}
                  noOptionsMessage={() => t("React.Select.NoOptions")}
                  onChange={e => {
                    if(e === null) {
                      setClientSelectedOption(e);
                      setVirtualFence(prevState => ({
                        ...prevState,
                        client: JSON.parse(JSON.stringify(ClientSchemaReduced))
                      }));
                    }
                    else {
                      const { dataAux, label, value } = e;
                      setClientSelectedOption({ dataAux, label, value });
                      setVirtualFence(prevState => ({
                        ...prevState,
                        client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                      }));
                    }
                  }}
                  placeholder={t("Title.Client.Select")}
                  value={clientSelectedOption}
                  required={true}
                />
                <Form.Control.Feedback className={`${!isEmpty(virtualFence.client.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Client")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {
              isScopes({
                currentUser,
                scopes: [
                  "is:master",
                  "read:virtualFences:all"
                ],
                every: false
              }) ? (
                <Row>
                  <Col>
                    <Form.Group controlId="forms-virtual-fence-global">
                      <Form.Label>{t("Label.Global")}:</Form.Label>
                      <Select
                        className="menu-outer-bottom"
                        classNamePrefix="select"
                        isDisabled={inputDisabled()}
                        onChange={e => {
                          const global = e.value;
                          setVirtualFence(prevState => ({
                            ...prevState,
                            global
                          }));
                        }}
                        options={globalOptions}
                        value={globalOptions.find(x => x.value === virtualFence.global)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ) : null
            }
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-virtual-fence-name">
                <Form.Label>{t("Label.Name")}:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Placeholder.Name")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    const name = e.target.value;
                    setVirtualFence(prevState => ({
                      ...prevState,
                      name: name.toUpperCase()
                    }));
                  }}
                  autoComplete="off"
                  value={virtualFence.name}
                  isValid={!isEmpty(virtualFence.name)}
                  isInvalid={isEmpty(virtualFence.name)}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.Name")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-virtual-fence-patrimony-type">
                <Form.Label>{t("Label.Patrimony.Type")}:</Form.Label>
                <Select
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  isDisabled={inputDisabled()}
                  onChange={e => {
                    const type = e.value;
                    setVirtualFence(prevState => ({
                      ...prevState,
                      patrimony: {
                        ...prevState.patrimony,
                        type
                      }
                    }));
                  }}
                  options={patrimonyTypeOptions}
                  value={patrimonyTypeOptions.find(x => x.value === virtualFence.patrimony.type)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-virtual-fence-type">
                <Form.Label>{t("Label.Type")}:</Form.Label>
                <Select
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  isDisabled={inputDisabled()}
                  onChange={e => {
                    const type = e.value;
                    setVirtualFence(prevState => ({
                      ...prevState,
                      type,
                      time: type === "RES" ? "05:00:00" : ""
                    }));
                  }}
                  options={typeOptions}
                  value={typeOptions.find(x => x.value === virtualFence.type)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-virtual-fence-time">
                <Form.Label>{t("Label.Time")}:</Form.Label>
                <InputMask
                  id="forms-virtual-fence-time"
                  className={`form-control ${isEmpty(virtualFence.time) || (!isEmpty(virtualFence.time) && Date.isTime(virtualFence.time)) ? "is-valid" : "is-invalid"}`}
                  type="text"
                  placeholder={t("Placeholder.Time")}
                  disabled={(inputDisabled() || virtualFence.type === "DEF") ? true : false}
                  onChange={e => {
                    const time = e.target.value;
                    setVirtualFence(prevState => ({
                      ...prevState,
                      time
                    }));
                  }}
                  mask="99:99:99"
                  autoComplete="off"
                  value={virtualFence.time}
                />
                <Form.Control.Feedback type="invalid">
                  { t("Feedback.Format.Time") }
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-virtual-fence-run-min-hour">
                <Form.Label>{t("Label.NotifyMinHour")}:</Form.Label>
                <InputMask
                  id="forms-virtual-fence-run-min-hour"
                  className={`form-control ${(isEmpty(virtualFence.notifyMinHour) && isEmpty(virtualFence.notifyMaxHour)) || ((!isEmpty(virtualFence.notifyMinHour) && Date.isTime(virtualFence.notifyMinHour) && Date.timeToSeconds(virtualFence.notifyMinHour) !== Date.timeToSeconds(virtualFence.notifyMaxHour)) && (!isEmpty(virtualFence.notifyMaxHour) && Date.isTime(virtualFence.notifyMaxHour) && Date.timeToSeconds(virtualFence.notifyMaxHour) !== Date.timeToSeconds(virtualFence.notifyMinHour))) ? "is-valid" : "is-invalid"}`}
                  type="text"
                  placeholder={t("Placeholder.NotifyMinHour")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    const notifyMinHour = e.target.value;
                    setVirtualFence(prevState => ({
                      ...prevState,
                      notifyMinHour
                    }));
                  }}
                  mask="99:99:99"
                  autoComplete="off"
                  defaultValue={virtualFence.notifyMinHour}
                />
                <Form.Control.Feedback type="invalid">
                  { t("Feedback.Format.NotifyMinHour") }
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-virtual-fence-run-max-hour">
                <Form.Label>{t("Label.NotifyMaxHour")}:</Form.Label>
                <InputMask
                  id="forms-virtual-fence-run-max-hour"
                  className={`form-control ${(isEmpty(virtualFence.notifyMinHour) && isEmpty(virtualFence.notifyMaxHour)) || ((!isEmpty(virtualFence.notifyMinHour) && Date.isTime(virtualFence.notifyMinHour) && Date.timeToSeconds(virtualFence.notifyMinHour) !== Date.timeToSeconds(virtualFence.notifyMaxHour)) && (!isEmpty(virtualFence.notifyMaxHour) && Date.isTime(virtualFence.notifyMaxHour) && Date.timeToSeconds(virtualFence.notifyMaxHour) !== Date.timeToSeconds(virtualFence.notifyMinHour))) ? "is-valid" : "is-invalid"}`}
                  type="text"
                  placeholder={t("Placeholder.NotifyMaxHour")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    const notifyMaxHour = e.target.value;
                    setVirtualFence(prevState => ({
                      ...prevState,
                      notifyMaxHour
                    }));
                  }}
                  mask="99:99:99"
                  autoComplete="off"
                  defaultValue={virtualFence.notifyMaxHour}
                />
                <Form.Control.Feedback type="invalid">
                  { t("Feedback.Format.NotifyMaxHour") }
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-virtual-fence-permited-min-hour">
                <Form.Label>{t("Label.PermitedMinHour")}:</Form.Label>
                <InputMask
                  id="forms-virtual-fence-permited-min-hour"
                  className={`form-control ${(isEmpty(virtualFence.permitedMinHour) && isEmpty(virtualFence.permitedMaxHour)) || ((!isEmpty(virtualFence.permitedMinHour) && Date.isTime(virtualFence.permitedMinHour) && Date.timeToSeconds(virtualFence.permitedMinHour) !== Date.timeToSeconds(virtualFence.permitedMaxHour)) && (!isEmpty(virtualFence.permitedMaxHour) && Date.isTime(virtualFence.permitedMaxHour) && Date.timeToSeconds(virtualFence.permitedMaxHour) !== Date.timeToSeconds(virtualFence.permitedMinHour))) ? "is-valid" : "is-invalid"}`}
                  type="text"
                  placeholder={t("Placeholder.PermitedMinHour")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    const permitedMinHour = e.target.value;
                    setVirtualFence(prevState => ({
                      ...prevState,
                      permitedMinHour
                    }));
                  }}
                  mask="99:99:99"
                  autoComplete="off"
                  defaultValue={virtualFence.permitedMinHour}
                />
                <Form.Control.Feedback type="invalid">
                  { t("Feedback.Format.PermitedMinHour") }
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-virtual-fence-permited-max-hour">
                <Form.Label>{t("Label.PermitedMaxHour")}:</Form.Label>
                <InputMask
                  id="forms-virtual-fence-permited-max-hour"
                  className={`form-control ${(isEmpty(virtualFence.permitedMinHour) && isEmpty(virtualFence.permitedMaxHour)) || ((!isEmpty(virtualFence.permitedMinHour) && Date.isTime(virtualFence.permitedMinHour) && Date.timeToSeconds(virtualFence.permitedMinHour) !== Date.timeToSeconds(virtualFence.permitedMaxHour)) && (!isEmpty(virtualFence.permitedMaxHour) && Date.isTime(virtualFence.permitedMaxHour) && Date.timeToSeconds(virtualFence.permitedMaxHour) !== Date.timeToSeconds(virtualFence.permitedMinHour))) ? "is-valid" : "is-invalid"}`}
                  type="text"
                  placeholder={t("Placeholder.PermitedMaxHour")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    const permitedMaxHour = e.target.value;
                    setVirtualFence(prevState => ({
                      ...prevState,
                      permitedMaxHour
                    }));
                  }}
                  mask="99:99:99"
                  autoComplete="off"
                  defaultValue={virtualFence.permitedMaxHour}
                />
                <Form.Control.Feedback type="invalid">
                  { t("Feedback.Format.PermitedMaxHour") }
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <VirtualFenceMap
                locationOptions={{
                  polygon: virtualFence.location,
                  virtualFence,
                  setVirtualFence: (virtualFence) => {
                    setVirtualFence(virtualFence);
                  }
                }
              } />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Form.Group className="default-form-button">
        <Button
          variant="dark"
          type="submit"
          disabled={buttonDisabled()}
        >
          {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
        </Button>
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(VirtualFence));
