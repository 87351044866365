import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as MenusActions } from "./../../store/ducks/menus";

import { isScopes } from "./../../services/Auth";
import MenuReportsItem from "./../MenuReportsItem";

import MyReportsReport from "./../../reports/MyReports";
import PatrimonyReport from "./../../reports/Patrimony";
import UserReport from "./../../reports/User";

const MenuReports = ({
  props: { currentUser },
  funcs: { menuHandleOpen, menuReportsHandleClose }
}) => {
  const { t } = useTranslation();
  const [selectedReport, setSelectedReport] = useState({
    selected: false,
    options: false
  });

  useEffect(() => {
    if(selectedReport.selected) {
      if(selectedReport.options.scoped(currentUser) === false) {
        changeSelectedReport(false);
      }
    }
  }, [currentUser]); /* eslint-disable-line */

  const changeSelectedReport = (item) => {
    setSelectedReport({
      selected: false,
      options: false
    });
    if(item !== false) {
      setTimeout(() => {
        setSelectedReport(item);
      }, 100);
    }
  };

  const dynamicOptions = [
    {
      id: "mrp-option-patrimony",
      title: t("Title.Patrimony"),
      icon: <i className="fas fa-car" />,
      onClick: () => {
        const id = "mrp-option-patrimony";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            title: t("Title.Patrimony"),
            render: () => <PatrimonyReport />,
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:reports:patrimonies"], every: false })
          }
        });
      },
      selected: () => selectedReport.selected === "mrp-option-patrimony",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:reports:patrimonies"], every: false })
    },
    {
      id: "mrp-option-user",
      title: t("Title.User"),
      icon: <i className="fas fa-user" />,
      onClick: () => {
        const id = "mrp-option-user";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            title: t("Title.User"),
            render: () => <UserReport />,
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:reports:users"], every: false })
          }
        });
      },
      selected: () => selectedReport.selected === "mrp-option-user",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:reports:users"], every: false })
    }
  ];

  const fixedOptions = [
    {
      id: "mrp-option-close",
      title: t("Title.Close"),
      icon: <i className="fas fa-times" />,
      onClick: () => {
        menuReportsHandleClose();
        menuHandleOpen();
      },
      selected: () => true,
      scoped: () => true
    },
    {
      id: "mrp-option-my-reports",
      title: t("Title.MyReports"),
      icon: <i className="fas fa-file" />,
      onClick: () => {
        const id = "mrp-option-my-reports";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            title: t("Title.MyReports"),
            render: () => <MyReportsReport />
          }
        });
      },
      selected: () => selectedReport.selected === "mrp-option-my-reports",
      scoped: () => true
    }
  ];

  const renderBody = () => {
    if(selectedReport.selected) {
      return selectedReport.options.render();
    }
    return null;
  };

  const renderTitle = () => {
    if(selectedReport.selected) {
      return <h3>{selectedReport.options.title}</h3>;
    }
    return null;
  };

  return (
    <div className="menu-reports">
      <div className={`mrp-reports-options-absolute ${selectedReport.selected ? "active" : ""}`}>
        <div className="mrp-reports-options-absolute-content">
          <div className="mrp-roa-content-title">{renderTitle()}</div>
          <div className="mrp-roa-content-body">{renderBody()}</div>
        </div>
      </div>
      <div className="mrp-reports-options-fixed">
        <ul>
          {
            fixedOptions.map(option => {
              if(option.scoped()) {
                return (
                  <MenuReportsItem key={option.id} {...option} onClick={option.onClick} />
                );
              }
              return null;
            })
          }
        </ul>
      </div>
      <div className="mrp-reports-options-dynamic">
        <ul>
          {
            dynamicOptions.map(option => {
              if(option.scoped()) {
                return (
                  <MenuReportsItem key={option.id} {...option} onClick={option.onClick} />
                );
              }
              return null;
            })
          }
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, MenusActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(MenuReports));
