import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import Grid from "./../Grid";
import { sortPositionsPolyline } from "./../../utils/Position";

import { getCols as getColsAnchor, getRows as getRowsAnchor } from "./../../services/Route/Anchor";
import { getCols as getColsBattery, getRows as getRowsBattery } from "./../../services/Route/Battery";
import { getCols as getColsBlock, getRows as getRowsBlock } from "./../../services/Route/Block";
import { getCols as getColsCourse, getRows as getRowsCourse } from "./../../services/Route/Course";
import { getCols as getColsIgnition, getRows as getRowsIgnition } from "./../../services/Route/Ignition";
import { getCols as getColsJammer, getRows as getRowsJammer } from "./../../services/Route/Jammer";
import { getCols as getColsMaintenance, getRows as getRowsMaintenance } from "./../../services/Route/Maintenance";
import { getCols as getColsNiple, getRows as getRowsNiple } from "./../../services/Route/Niple";
import { getCols as getColsPanic, getRows as getRowsPanic } from "./../../services/Route/Panic";
import { getCols as getColsPump, getRows as getRowsPump } from "./../../services/Route/Pump";
//import { getCols as getColsRoute, getRows as getRowsRoute } from "./../../services/Route/Route";
import { getCols as getColsSpeed, getRows as getRowsSpeed } from "./../../services/Route/Speed";
import { getCols as getColsStop, getRows as getRowsStop } from "./../../services/Route/Stop";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as MenusActions } from "./../../store/ducks/menus";
import { Creators as RoutesActions } from "./../../store/ducks/routes";
import { Creators as UsersActions } from "./../../store/ducks/users";

const MenuSingleRoutePositions = ({
  props: { currentUser, lastPositionSelected, singleRoute, singleRouteMenu, singleRouteMenuSelected, singleRouteMenuPositionsSelected },
  funcs: { addSingleRoute, addSingleRouteMenuPositionsMarkers, closeMenuPositions, disableLoader, enableLoader, menuHandleClose, menuHandleOpen, openMenuPositions, removeSingleRoute, removeSingleRouteMenuPositionsMarkers, selectMenuSingleRoute }
}) => {
  const { t } = useTranslation();

  const [grid, setGrid] = useState(null);
  const [idSelected, setIdSelected] = useState(false);

  const [gridTypes] = useState([
    {
      id: "anchor",
      getCols: () => getColsAnchor(),
      getRows: ({ docs, t }) => getRowsAnchor(docs, t)
    },
    {
      id: "battery",
      getCols: () => getColsBattery(),
      getRows: ({ docs, t }) => getRowsBattery(docs, t)
    },
    {
      id: "block",
      getCols: () => getColsBlock(),
      getRows: ({ docs, t }) => getRowsBlock(docs, t)
    },
    {
      id: "course",
      getCols: () => getColsCourse(),
      getRows: ({ docs, t }) => getRowsCourse(docs, t)
    },
    {
      id: "espRestarted",
      getCols: () => getColsNiple(currentUser),
      getRows: ({ docs, t }) => getRowsNiple(docs, t, currentUser)
    },
    {
      id: "hatchOpen",
      getCols: () => getColsNiple(currentUser),
      getRows: ({ docs, t }) => getRowsNiple(docs, t, currentUser)
    },
    {
      id: "ignitionOff",
      getCols: () => getColsIgnition(),
      getRows: ({ docs, t }) => getRowsIgnition(docs, t)
    },
    {
      id: "ignitionOn",
      getCols: () => getColsIgnition(),
      getRows: ({ docs, t }) => getRowsIgnition(docs, t)
    },
    {
      id: "jammer",
      getCols: () => getColsJammer(),
      getRows: ({ docs, t }) => getRowsJammer(docs, t)
    },
    {
      id: "route",
      getCols: () => getColsNiple(currentUser),
      getRows: ({ docs, t }) => getRowsNiple(docs, t, currentUser)
    },
    {
      id: "speed",
      getCols: () => getColsSpeed(),
      getRows: ({ docs, t }) => getRowsSpeed(docs, t)
    },
    {
      id: "stop",
      getCols: () => getColsStop(),
      getRows: ({ docs, t }) => getRowsStop(docs, t)
    },
    {
      id: "stopRework",
      getCols: () => getColsStop(),
      getRows: ({ docs, t }) => getRowsStop(docs, t)
    },
    {
      id: "panic",
      getCols: () => getColsPanic(),
      getRows: ({ docs, t }) => getRowsPanic(docs, t)
    },
    {
      id: "pump",
      getCols: () => getColsPump(),
      getRows: ({ docs, t }) => getRowsPump(docs, t)
    },
    {
      id: "maintenance",
      getCols: () => getColsMaintenance(currentUser),
      getRows: ({ docs, t }) => getRowsMaintenance(docs, t, currentUser)
    },
    {
      id: "nipleTestTpw",
      getCols: () => getColsNiple(currentUser),
      getRows: ({ docs, t }) => getRowsNiple(docs, t, currentUser)
    },
    {
      id: "nipleTestTpe",
      getCols: () => getColsNiple(currentUser),
      getRows: ({ docs, t }) => getRowsNiple(docs, t, currentUser)
    },
    {
      id: "nipleFraud",
      getCols: () => getColsNiple(currentUser),
      getRows: ({ docs, t }) => getRowsNiple(docs, t, currentUser)
    },
    {
      id: "nipleClosed",
      getCols: () => getColsNiple(currentUser),
      getRows: ({ docs, t }) => getRowsNiple(docs, t, currentUser)
    },
    {
      id: "nipleClosedBasic",
      getCols: () => getColsNiple(currentUser),
      getRows: ({ docs, t }) => getRowsNiple(docs, t, currentUser)
    },
    {
      id: "nipleDischarge",
      getCols: () => getColsNiple(currentUser),
      getRows: ({ docs, t }) => getRowsNiple(docs, t, currentUser)
    },
    {
      id: "nipleDischargeTest",
      getCols: () => getColsNiple(currentUser),
      getRows: ({ docs, t }) => getRowsNiple(docs, t, currentUser)
    },
    {
      id: "nipleDischargeBasic",
      getCols: () => getColsNiple(currentUser),
      getRows: ({ docs, t }) => getRowsNiple(docs, t, currentUser)
    },
    {
      id: "nipleCharge",
      getCols: () => getColsNiple(currentUser),
      getRows: ({ docs, t }) => getRowsNiple(docs, t, currentUser)
    },
    {
      id: "nipleChargeTest",
      getCols: () => getColsNiple(currentUser),
      getRows: ({ docs, t }) => getRowsNiple(docs, t, currentUser)
    },
    {
      id: "nipleChargeBasic",
      getCols: () => getColsNiple(currentUser),
      getRows: ({ docs, t }) => getRowsNiple(docs, t, currentUser)
    }
  ]);

  const getCols = ({ singleRouteMenuSelected }) => {
    const findGetCols = gridTypes.find(x => x.id === singleRouteMenuSelected.id);
    if(typeof findGetCols !== "undefined") {
      return findGetCols.getCols();
    }
    return [];
  };

  const getDocs = ({ singleRoute, singleRouteMenuSelected }) => {
    if(singleRouteMenuSelected.id === "route") {
      return sortPositionsPolyline(singleRoute.route, singleRoute.orderAsc);
    }
    return sortPositionsPolyline(singleRouteMenuSelected.value, singleRoute.orderAsc);
  };

  const getRows = ({ docs, singleRouteMenuSelected }) => {
    const findGetRows = gridTypes.find(x => x.id === singleRouteMenuSelected.id);
    if(typeof findGetRows !== "undefined") {
      return findGetRows.getRows({ docs, t });
    }
    return [];
  };

  useEffect(() => {
    setIdSelected(singleRouteMenuSelected.id);
    setGrid(null);

    const cols = getCols({ singleRouteMenuSelected });
    const docs = getDocs({ singleRoute, singleRouteMenuSelected });
    const rows = getRows({ docs, singleRouteMenuSelected });

    setTimeout(() => {
      setGrid(<Grid options={{
        actions: [],
        api: false,
        cols,
        docs,
        multipleSelect: true,
        rows,
        title: "",
        onSelect: (e) => addSingleRouteMenuPositionsMarkers(e),
        onDeselect: (e) => removeSingleRouteMenuPositionsMarkers(e),
        onSearch: (e) => {
          const docs = getDocs({ singleRoute, singleRouteMenuSelected });
          const rows = getRows({ docs, singleRouteMenuSelected });
          const docsSelected = [];
          const rowsSelected = [];
          var col, confirm, row, value;
          for(var i = 0; i < rows.length; i++) {
            row = rows[i];
            confirm = false;
            for(var j = 0; j < row.length; j++) {
              col = row[j];
              value = col.value.toString();
              if(value.toLowerCase().indexOf(e.toString().toLowerCase()) !== -1) {
                confirm = true;
              }
              if((j + 1) === row.length) {
        				if(confirm) {
                  docsSelected.push(docs[i]);
                  rowsSelected.push(rows[i]);
                }
              }
            }
          }
          return {
            docs: docsSelected,
            rows: rowsSelected
          };
        }
      }} />);
    }, 100);
  }, [idSelected, singleRouteMenuSelected]); /* eslint-disable-line */

  return (
    <div className="menu-single-route-positions">{grid}</div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    lastPositionSelected: state.lastPositions.positionSelected,
    singleRoute: state.routes.singleRoute,
    singleRouteMenu: state.routes.singleRouteMenu,
    singleRouteMenuSelected: state.routes.singleRouteMenuSelected,
    singleRouteMenuPositionsSelected: state.routes.singleRouteMenuPositionsSelected
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, MenusActions, RoutesActions, UsersActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(MenuSingleRoutePositions));
