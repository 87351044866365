import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { useHandleReset } from "../../hooks/Menu";
import "./index.css";

import { Creators as ExportedsActions } from "./../../store/ducks/exporteds";
import { Creators as MenusActions } from "./../../store/ducks/menus";
import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as RoutesActions } from "./../../store/ducks/routes";
import { Creators as TranshipmentsActions } from "./../../store/ducks/transhipments";
import { Creators as UsersActions } from "./../../store/ducks/users";

import { isScopes } from "./../../services/Auth";
import DateUtil from "./../../utils/Date";
import LastPositionActionItem from "./../LastPositionActionItem";
import PositionService from "./../../services/Position";
import UserService from "./../../services/User";

const LastPositionAction = ({
  props: {
    calendars,
    commands,
    currentToken,
    currentUser,
    databaseTest,
    lastPositionSelected,
    menu,
    profiles,
    registers,
    reports,
    simulators,
    singleExported,
    singleRoute,
    singleTranshipment
  },
  funcs: {
    addSingleExported,
    addSingleRoute,
    addSingleTranshipment,
    disableLoader,
    enableLoader,
    menuHandleClose,
    menuHandleOpen,
    menuCalendarsHandleClose,
    menuCalendarsHandleOpen,
    menuCommandsHandleClose,
    menuCommandsHandleOpen,
    menuProfilesHandleClose,
    menuProfilesHandleOpen,
    menuRegistersHandleClose,
    menuRegistersHandleOpen,
    menuReportsHandleClose,
    menuReportsHandleOpen,
    menuSimulatorsHandleClose,
    menuSimulatorsHandleOpen,
    removeSingleExported,
    removeSingleRoute,
    removeSingleTranshipment,
    setCurrentUser
  }
}) => {
  const { t } = useTranslation();
  const handleResetMenu = useHandleReset();
  const userService = new UserService();

  const handleExit = async (e) => {
    await userService.logout({
      fcmToken: currentToken,
      token: currentUser.token
    });
    setCurrentUser(false);
  };

  const handleRoute = async (e) => {
    if(lastPositionSelected && !singleRoute) {
      handleResetMenu();
      enableLoader();
      const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
      const endDate = new Date(`${todayDate} 23:55:00`);
      const startDate = new Date(`${todayDate} 00:00:00`);
      const patrimony = lastPositionSelected.position.patrimony;
      const downtimeTolerance = typeof patrimony.parameters !== "undefined" ? patrimony.parameters.downtimeTolerance : undefined;
      const speedTolerance = typeof patrimony.parameters !== "undefined" ? patrimony.parameters.speedTolerance : undefined;
      const orderAsc = false;
      const response = databaseTest ? await PositionService.findRouteTest({
        endDate,
        orderAsc,
        page: 1,
        patrimony: patrimony.id,
        startDate,
        token: currentUser.token
      }) : await PositionService.findRoute({
        endDate,
        orderAsc,
        page: 1,
        patrimony: patrimony.id,
        startDate,
        token: currentUser.token
      });
      const { docs, page, pages } = response.positions;
      addSingleRoute(endDate, { orderAsc, page, pages }, lastPositionSelected, docs, startDate, { downtimeTolerance, speedTolerance });
      menuHandleClose();
      disableLoader();
    }
    else {
      removeSingleRoute();
      menuHandleOpen();
    }
  };

  const handleExported = async (e) => {
    if(lastPositionSelected && !singleExported) {
      handleResetMenu();
      enableLoader();
      const endDate = DateUtil.endDate({ format: null });
      const startDate = DateUtil.startDate({ format: null });
      const patrimony = lastPositionSelected.position.patrimony.id;
      const orderAsc = false;
      const response = databaseTest ? await PositionService.findExportedRouteTest({
        endDate,
        orderAsc,
        page: 1,
        patrimony,
        startDate,
        token: currentUser.token
      }) : await PositionService.findExportedRoute({
        endDate,
        orderAsc,
        page: 1,
        patrimony,
        startDate,
        token: currentUser.token
      });
      const { docs, page, pages } = response.positions;
      addSingleExported(endDate, { orderAsc, page, pages }, lastPositionSelected, docs, startDate);
      menuHandleClose();
      disableLoader();
    }
    else {
      removeSingleExported();
      menuHandleOpen();
    }
  };

  const handleCalendars = async (e) => {
    if(!calendars) {
      handleResetMenu();
      enableLoader();
      menuCalendarsHandleOpen();
      menuHandleClose();
      disableLoader();
    }
    else {
      menuCalendarsHandleClose();
      menuHandleOpen();
    }
  };

  const handleCommands = async (e) => {
    if(!commands) {
      handleResetMenu();
      enableLoader();
      menuCommandsHandleOpen();
      menuHandleClose();
      disableLoader();
    }
    else {
      menuCommandsHandleClose();
      menuHandleOpen();
    }
  };

  const handleProfiles = async (e) => {
    if(!profiles) {
      handleResetMenu();
      enableLoader();
      menuProfilesHandleOpen();
      menuHandleClose();
      disableLoader();
    }
    else {
      menuProfilesHandleClose();
      menuHandleOpen();
    }
  };

  const handleRegisters = async (e) => {
    if(!registers) {
      handleResetMenu();
      enableLoader();
      menuRegistersHandleOpen();
      menuHandleClose();
      disableLoader();
    }
    else {
      menuRegistersHandleClose();
      menuHandleOpen();
    }
  };

  const handleReports = async (e) => {
    if(!reports) {
      handleResetMenu();
      enableLoader();
      menuReportsHandleOpen();
      menuHandleClose();
      disableLoader();
    }
    else {
      menuReportsHandleClose();
      menuHandleOpen();
    }
  };

  const handleSimulators = async (e) => {
    if(!simulators) {
      handleResetMenu();
      enableLoader();
      menuSimulatorsHandleOpen();
      menuHandleClose();
      disableLoader();
    }
    else {
      menuSimulatorsHandleClose();
      menuHandleOpen();
    }
  };

  const handleTranshipment = async (e) => {
    if(lastPositionSelected && !singleTranshipment) {
      handleResetMenu();
      enableLoader();
      const endDate = DateUtil.endDate({ format: null });
      const startDate = DateUtil.startDate({ format: null });
      const patrimony = lastPositionSelected.position.patrimony.id;
      const response = databaseTest ? await PositionService.findTranshipmentTest({
        endDate,
        patrimony,
        startDate,
        token: currentUser.token
      }) : await PositionService.findTranshipment({
        endDate,
        patrimony,
        startDate,
        token: currentUser.token
      });
      addSingleTranshipment(endDate, lastPositionSelected, response, startDate);
      menuHandleClose();
      disableLoader();
    }
    else {
      removeSingleTranshipment();
      menuHandleOpen();
    }
  };

  const actions = [
    {
      id: "lpa-route",
      title: t("Title.Route"),
      icon: <i className="fas fa-route" />,
      onClick: e => handleRoute(e),
      selected: () => singleRoute ? true : false,
      disabled: () => lastPositionSelected ? false : true,
      scoped: () => true
    },
    {
      id: "lpa-exported",
      title: t("Title.ExportedRoute"),
      icon: <i className="fas fa-map-marked-alt" />,
      onClick: e => handleExported(e),
      selected: () => singleExported ? true : false,
      disabled: () => lastPositionSelected ? false : true,
      scoped: () => isScopes({ currentUser, scopes: ["is:master"], every: false })
    },
    {
      id: "lpa-transhipment",
      title: t("Title.Transhipment"),
      icon: <i className="fas fa-exchange-alt" />,
      onClick: e => handleTranshipment(e),
      selected: () => singleTranshipment ? true : false,
      disabled: () => lastPositionSelected ? false : true,
      scoped: () => false
    },
    {
      id: "lpa-simulator",
      title: t("Title.Simulator"),
      icon: <i className="fas fa-vials" />,
      onClick: e => handleSimulators(e),
      selected: () => simulators ? true : false,
      disabled: () => false,
      scoped: () => false
    },
    {
      id: "lpa-registers",
      title: t("Title.Registers"),
      icon: <i className="far fa-clipboard" />,
      onClick: e => handleRegisters(e),
      selected: () => registers,
      disabled: () => false,
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers"], every: false })
    },
    {
      id: "lpa-calendars",
      title: t("Title.Calendars"),
      icon: <i className="far fa-calendar" />,
      onClick: e => handleCalendars(e),
      selected: () => calendars,
      disabled: () => false,
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:calendars"], every: false })
    },
    {
      id: "lpa-commands",
      title: t("Title.Commands"),
      icon: <i className="fa-solid fa-rss" />,
      onClick: e => handleCommands(e),
      selected: () => commands,
      disabled: () => false,
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:commands"], every: false })
    },
    {
      id: "lpa-reports",
      title: t("Title.Reports"),
      icon: <i className="far fa-file-pdf" />,
      onClick: e => handleReports(e),
      selected: () => reports,
      disabled: () => false,
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:reports"], every: false })
    },
    {
      id: "lpa-profiles",
      title: t("Title.Profiles"),
      icon: <i className="fas fa-user-alt" />,
      onClick: e => handleProfiles(e),
      selected: () => profiles,
      disabled: () => false,
      scoped: () => !isScopes({ currentUser, scopes: ["is:guest"], every: false })
    },
    {
      id: "lpa-exit",
      title: t("Title.Exit"),
      icon: <i className="fas fa-sign-out-alt" />,
      onClick: e => handleExit(e),
      selected: () => false,
      disabled: () => false,
      scoped: () => true
    }
  ];

  useEffect(() => {
    actions.map(action => {
      if(!action.scoped() && action.selected()) {
        return handleResetMenu();
      }
      return null;
    });
  }, [currentUser]); /* eslint-disable-line */

  return (
    <div className={`last-position-action-wrap ${menu.toggled ? "active": ""}`}>
      <ul>
        {
          actions.map(action => {
            if(action.scoped()) {
              return (
                <LastPositionActionItem key={action.id} {...action} onClick={action.onClick} />
              );
            }
            return null;
          })
        }
      </ul>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    calendars: state.menus.calendars.toggled,
    commands: state.menus.commands.toggled,
    currentToken: state.users.currentToken,
    currentUser: state.users.currentUser,
    databaseTest: state.databaseTest.databaseTest,
    lastPositionSelected: state.lastPositions.positionSelected,
    menu: state.menus.menu,
    profiles: state.menus.profiles.toggled,
    registers: state.menus.registers.toggled,
    reports: state.menus.reports.toggled,
    simulators: state.menus.simulators.toggled,
    singleExported: state.exporteds.singleExported,
    singleRoute: state.routes.singleRoute,
    singleTranshipment: state.transhipments.singleTranshipment
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(
    Object.assign({},
      ExportedsActions,
      MenusActions,
      LoadersActions,
      RoutesActions,
      TranshipmentsActions,
      UsersActions
    ), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastPositionAction);
