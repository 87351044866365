import ClientSchemaReduced from "./../Client/SchemaReduced";

const schema = {
  active: true,
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  global: false,
  id: "",
  location: {
    type: "Polygon",
    coordinates: [[]]
  },
  name: "",
  notifyMinHour: null,
  notifyMaxHour: null,
  patrimony: {
    patrimonies: [],
    type: "ALL"
  },
  permitedMinHour: null,
  permitedMaxHour: null,
  time: "",
  type: "DEF"
};

export default schema;
