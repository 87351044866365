import { createActions, createReducer } from "reduxsauce";
import { filterByWord, sortVirtualFences } from "./../../utils/VirtualFence";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addVirtualFences: ["virtualFences"],
  updateVirtualFences: ["id", "onClick"],
  searchVirtualFence: ["search"],
  removeVirtualFences: [],
  setVirtualFences: ["virtualFences"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  virtualFences: [],
  virtualFencesSearch: [],
  virtualFencesSearchQuery: {
    word: ""
  },
  virtualFencesSearchQueryEnabled: false,
  virtualFencesSelected: false,
  virtualFencesLoad: false
};

const addVirtualFences = (state = INITIAL_STATE, action) => {
  const { virtualFences } = action;
  return {
    ...state,
    virtualFences
  };
};

const updateVirtualFences = (state = INITIAL_STATE, action) => {
  const { id, onClick } = action;
  if (state.virtualFencesLoad && (id !== null && onClick !== null)) {
    const index = state.virtualFences.findIndex(x => x.id === id);
    if (index !== -1) {
      const virtualFences = state.virtualFences;
      virtualFences[index].onClick = onClick;
      const { virtualFencesSearch } = searchVirtualFences({
        ...state,
        virtualFences,
        virtualFencesSelected: false
      }, { search: state.virtualFencesSearchQuery });

      return {
        ...state,
        virtualFences: sortVirtualFences(virtualFences),
        virtualFencesSearch: sortVirtualFences(virtualFencesSearch)
      }
    }
  }
  return state;
};

const removeVirtualFences = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    virtualFences: [],
    virtualFencesSearch: [],
    virtualFencesSearchQuery: {
      word: ""
    },
    virtualFencesSearchQueryEnabled: false,
    virtualFencesSelected: false,
    virtualFencesLoad: false
  };
};

const searchVirtualFences = (state = INITIAL_STATE, action) => {
  const { search } = action;
  const { enabled: virtualFencesSearchEnabled, results: virtualFencesSearchResults } = filterByWord(state.virtualFences, search);
  return {
    ...state,
    virtualFencesSearch: virtualFencesSearchResults,
    virtualFencesSearchQuery: search,
    virtualFencesSearchQueryEnabled: virtualFencesSearchEnabled
  };
};

const setVirtualFences = (state = INITIAL_STATE, action) => {
  if(state.virtualFencesLoad === false) {
    const { virtualFences } = action;
    return {
      ...state,
      virtualFences: sortVirtualFences(virtualFences.map(virtualFence => {
        virtualFence.patrimonies = [];
        return { id: virtualFence.id, virtualFence, selected: false };
      })),
      virtualFencesLoad: true
    };
  }
  return state;
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_VIRTUAL_FENCES]: addVirtualFences,
  [Types.UPDATE_VIRTUAL_FENCES]: updateVirtualFences,
  [Types.SEARCH_VIRTUAL_FENCE]: searchVirtualFences,
  [Types.REMOVE_VIRTUAL_FENCES]: removeVirtualFences,
  [Types.SET_VIRTUAL_FENCES]: setVirtualFences
});
