import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  OverlayTrigger,
  Spinner,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import { QRCode } from "react-qrcode-logo";
import "./index.css";

import DateUtil from "./../../utils/Date";
import { getDescriptionClient } from "./../../utils/Client";
import { getDescriptionPatrimony, isAutomobile, isAutomobileBoardAutoVaccum, isVessel } from "./../../utils/Patrimony";
import { getBattery, getHatchStatus, getHatchType, getJammer, getNiple, getNipleBasic, getNipleFraud, getNipleOperation, getPowerSupply, getPump, getReason, getValidMoving } from "./../../utils/Position";
import { isScopes } from "./../../services/Auth";
import { isValidForm } from "./../../utils/Form";
import { mergeObject } from "./../../utils/Object";
import { ucFirstAll, prettierJson } from "./../../utils/String";
import { isPatrimonyId  } from "./../../utils/Patrimony";
import { getStatus } from "./../../utils/PatrimonyTest";
import { getType } from "./../../utils/Tracker";

import LastPositionSelectedItemCommandItem from "./../LastPositionSelectedItemCommandItem";
import SwitchDefault from "./../Switch/Default";
import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as QrcodesActions } from "./../../store/ducks/qrcodes";
import { Creators as NotificationsActions } from "./../../store/ducks/notifications";

import DriverService from "./../../services/Driver";
import DriverSchemaReduced from "./../../services/Driver/SchemaReduced";
import PatrimonyService from "./../../services/Patrimony";
import PatrimonyTestService from "./../../services/PatrimonyTest";
import PatrimonySchema from "./../../services/Patrimony/Schema";

const LastPositionSelectedItem = ({
  options: { hasBack, hasNext, id, onClick, onClickBack, onClickNext, position, selected },
  props: { currentUser, lastPatrimonyTests },
  funcs: { addNotice, disableLoader, enableLoader, enableQrcode }
}) => {
  const { t } = useTranslation();
  const driverService = new DriverService();
  const patrimonyService = new PatrimonyService();
  const patrimonyTestService = new PatrimonyTestService();

  const [driverEdit, setDriverEdit] = useState(false);
  const [patrimonyIgnore, setPatrimonyIgnore] = useState(false);
  const [patrimony, setPatrimony] = useState(mergeObject(JSON.parse(JSON.stringify(PatrimonySchema)), position.patrimony));
  const [driverSelectedOption, setDriverSelectedOption] = useState(patrimony.driver.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(DriverSchemaReduced)), patrimony.driver),
    label: patrimony.driver.name,
    value: patrimony.driver.id
  });
  const [patrimonyTest, setPatrimonyTest] = useState(false);

  const [justifyPatrimonyTests, setJustifyPatrimonyTests] = useState("");
  const [runningPatrimonyTests, setRunningPatrimonyTests] = useState(false);
  const [positionsPatrimonyTests, setPositionsPatrimonyTests] = useState([]);

  useEffect(() => {
    patrimonyTestChange(lastPatrimonyTests);
  }, [lastPatrimonyTests]); /* eslint-disable-line */

  useEffect(() => {
    setPatrimonyIgnore(true);
    setPatrimony(mergeObject(JSON.parse(JSON.stringify(PatrimonySchema)), position.patrimony));
    setDriverSelectedOption(patrimony.driver.id === "" ? null : {
      dataAux: mergeObject(JSON.parse(JSON.stringify(DriverSchemaReduced)), patrimony.driver),
      label: patrimony.driver.name,
      value: patrimony.driver.id
    });
  }, [position]); /* eslint-disable-line */

  useEffect(() => {
    if(patrimonyIgnore) {
      setPatrimonyIgnore(false);
    }
    else {
      if(driverEdit) {
        setDriverEdit(false);
        enableLoader();
        patrimonyService.updateDriver({
          id: patrimony.id,
          data: {
            driver: patrimony.driver.id !== "" ? {
              id: patrimony.driver.id,
              name: patrimony.driver.name
            } : undefined
          },
          token: currentUser.token
        })
          .then(() => disableLoader())
          .catch(() => disableLoader());
      }
    }
  }, [patrimony]); /* eslint-disable-line */

  let hatchs = [];
  let niples = [];
  if(isScopes({ currentUser, scopes: ["read:patrimonies:basic"], every: false })) {
    const nipleBasic = getNipleBasic(position, false);
    niples = nipleBasic.niples;
  }
  else if(isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })) {
    const nipleMaster = getNiple(position, false);
    hatchs = nipleMaster.hatchs;
    niples = nipleMaster.niples;
  }

  const pump = getPump(position, false);

  const driverLoadOptions = (e, c) => {
    driverLoadOptionsDebounce(e, c);
  };

  const driverLoadOptionsDebounce = debounce(async (e, c) => {
    const drivers = await driverService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterDriver(drivers));
  }, 1000);

  const filterDriver = (drivers) => {
    return drivers.map(e => {
      return {
        dataAux: e,
        label: e.name,
        value: e.id
      };
    });
  };

  const handleSubmitPatrimonyTests = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!runningPatrimonyTests) {
      setRunningPatrimonyTests(true);
      let { message, valid } = isValidForm(e);
      if (!valid) {
        if (!message) {
          message = t("Error.Fields.Empty");
        }
        addNotice({
          title: t("Title.Tracker"),
          content: message
        });
      }
      else {
        const response = await patrimonyTestService.conclude({
          id: patrimonyTest.id,
          data: {
            justify: justifyPatrimonyTests,
            positions: positionsPatrimonyTests
          },
          token: currentUser.token
        });

        if (response.success) {
          addNotice({
            title: t("Title.PatrimonyTest"),
            content: t("Success.Update")
          });
        }
        else {
          let error = ucFirstAll(response.error.details[0].path);
          addNotice({
            title: t("Title.PatrimonyTest"),
            content: t(`Error.PatrimonyTest.${response.error.type}.${error}`)
          });
        }
      }
    }
    setRunningPatrimonyTests(false);
  };

  const patrimonyTestChange = (lastPatrimonyTests) => {
    let _patrimonyTest = lastPatrimonyTests.find(x => x.id === position.patrimony.id);
    _patrimonyTest = typeof _patrimonyTest !== "undefined" ? _patrimonyTest.patrimonyTest : false;
    if(_patrimonyTest) {
      _patrimonyTest.positionsAux = [];
      _patrimonyTest.positions.map(position => {
        const filterIndex = _patrimonyTest.positionsAux.findIndex(x => x.serial === position.tracker.serial);
        if(filterIndex !== -1) {
          return _patrimonyTest.positionsAux[filterIndex].itens.push(position);
        }
        return _patrimonyTest.positionsAux.push({
          serial: position.tracker.serial,
          title: getType(position.tracker),
          itens: [position]
        });
      });
    }
    setPatrimonyTest(_patrimonyTest);
  };

  const getTrackerChip = (position, serial) => {
    const tracker = position.patrimony.trackers.find(x => x.serial === serial);
    return {
      line: typeof tracker.chip !== "undefined" ? tracker.chip.line : "",
      serial: typeof tracker.chip !== "undefined" ? tracker.chip.serial : ""
    };
  };

  const checkPositionsPatrimonyTests = (tag, boolean = true) => {
    const positions = JSON.parse(JSON.stringify(positionsPatrimonyTests));
    const index = positions.findIndex(x => x.tag === tag);
    if (index === -1) {
      return false;
    }
    if (boolean) {
      return true;
    }
    return positions[index];
  };

  const togglePositionsPatrimonyTests = (tag) => {
    const positions = JSON.parse(JSON.stringify(positionsPatrimonyTests));
    const index = positions.findIndex(x => x.tag === tag);
    if (index === -1) {
      positions.push({
        tag,
        date: Date.now()
      });
    }
    else {
      positions.splice(index, 1);
    }
    setPositionsPatrimonyTests(positions);
  };

  return (
    <div className="last-position-selected">
      <div className="commands-close">
        <ul>
          <li>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>{t("Title.Back")}</Tooltip>}
              trigger={isMobile || !hasBack() ? null : ["hover", "focus"]}
            >
              <button
                className={"lps-button"}
                type="button"
                onClick={onClickBack}
                disabled={!hasBack()}
              >
                <div className="lps-icon"><i className="fas fa-chevron-left" /></div>
              </button>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>{t("Title.Next")}</Tooltip>}
              trigger={isMobile || !hasNext() ? null : ["hover", "focus"]}
            >
              <button
                className={"lps-button"}
                type="button"
                onClick={onClickNext}
                disabled={!hasNext()}
              >
                <div className="lps-icon"><i className="fas fa-chevron-right" /></div>
              </button>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>{t("Title.Close")}</Tooltip>}
              trigger={isMobile ? null : ["hover", "focus"]}
            >
              <button
                className={"lps-button"}
                type="button"
                onClick={onClick}
              >
                <div className="lps-icon"><i className="fas fa-times" /></div>
              </button>
            </OverlayTrigger>
          </li>
        </ul>
      </div>
      <div className="commands">
        <LastPositionSelectedItemCommandItem options={{ position }} />
      </div>
      {
        isScopes({ currentUser, scopes: ["is:master", "read:drivers", "read:drivers:all"], every: false }) &&
        isScopes({ currentUser, scopes: ["is:master", "update:patrimonies:drivers", "update:patrimonies:drivers:all"], every: false }) &&
        isPatrimonyId(patrimony.id) ? (
            <ul className="driver-box">
              <li onClick={e => setDriverEdit(true)}>
                <Form.Group controlId="forms-patrimony-driver">
                  <Form.Label>{t("Label.Driver")}{!driverEdit ? ` (${t("ClickToEdit")})` : ""}:</Form.Label>
                  <AsyncSelect
                    className="menu-outer-bottom"
                    classNamePrefix="select"
                    cacheOptions
                    defaultOptions
                    isClearable
                    isDisabled={!driverEdit}
                    loadOptions={driverLoadOptions}
                    loadingMessage={() => t("React.Select.Wait")}
                    noOptionsMessage={() => t("React.Select.NoOptions")}
                    onChange={e => {
                      if(e === null) {
                        setDriverSelectedOption(e);
                        setPatrimony(prevState => ({
                          ...prevState,
                          driver: JSON.parse(JSON.stringify(DriverSchemaReduced))
                        }));
                      }
                      else {
                        const { dataAux, label, value } = e;
                        setDriverSelectedOption({ dataAux, label, value });
                        setPatrimony(prevState => ({
                          ...prevState,
                          driver: mergeObject(JSON.parse(JSON.stringify(DriverSchemaReduced)), dataAux)
                        }));
                      }
                    }}
                    placeholder={t("React.Select.Select")}
                    value={driverSelectedOption}
                  />
                </Form.Group>
              </li>
            </ul>
          ) : null
      }
      {
        (
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
          && typeof position._events !== "undefined"
          && (
            position._events.ignitionOn > 0 ||
            position._events.ignitionOff > 0 ||
            position._events.battery > 0 ||
            position._events.maintenance > 0 ||
            position._events.espRestarted > 0 ||
            position._events.nipleFraud > 0 ||
            position._events.nipleClosed > 0 ||
            position._events.nipleDischarge > 0 ||
            position._events.nipleDischargeTest > 0 ||
            position._events.nipleCharge > 0 ||
            position._events.nipleChargeTest > 0 ||
            position._events.hatchOpen > 0
          )
          && (
            <div className="positions-item-events">
              <ul>
                {
                  (position._events.ignitionOn > 0 && (
                    <li>
                      <div className="icon"><i className="fas fa-key" style={{ color: "#087d06" }} /></div>
                      <div className="count">{position._events.ignitionOn}</div>
                    </li>
                  ))
                }
                {
                  (position._events.ignitionOff > 0 && (
                    <li>
                      <div className="icon"><i className="fas fa-key" style={{ color: "#ff0000" }} /></div>
                      <div className="count">{position._events.ignitionOff}</div>
                    </li>
                  ))
                }
                {
                  (position._events.battery > 0 && (
                    <li>
                      <div className="icon"><i className="fas fa-battery-empty" /></div>
                      <div className="count">{position._events.battery}</div>
                    </li>
                  ))
                }
                {
                  (position._events.maintenance > 0 && (
                    <li>
                      <div className="icon">M</div>
                      <div className="count">{position._events.maintenance}</div>
                    </li>
                  ))
                }
                {
                  (position._events.espRestarted > 0 && (
                    <li>
                      <div className="icon">R</div>
                      <div className="count">{position._events.espRestarted}</div>
                    </li>
                  ))
                }
                {
                  (position._events.nipleFraud > 0 && (
                    <li>
                      <div className="icon">B</div>
                      <div className="count">{position._events.nipleFraud}</div>
                    </li>
                  ))
                }
                {
                  (position._events.nipleClosed > 0 && (
                    <li>
                      <div className="icon">F</div>
                      <div className="count">{position._events.nipleClosed}</div>
                    </li>
                  ))
                }
                {
                  (position._events.nipleDischarge > 0 && (
                    <li>
                      <div className="icon">D</div>
                      <div className="count">{position._events.nipleDischarge}</div>
                    </li>
                  ))
                }
                {
                  (position._events.nipleDischargeTest > 0 && (
                    <li>
                      <div className="icon" style={{ color: "#999999" }}>D</div>
                      <div className="count">{position._events.nipleDischargeTest}</div>
                    </li>
                  ))
                }
                {
                  (position._events.nipleCharge > 0 && (
                    <li>
                      <div className="icon">C</div>
                      <div className="count">{position._events.nipleCharge}</div>
                    </li>
                  ))
                }
                {
                  (position._events.nipleChargeTest > 0 && (
                    <li>
                      <div className="icon" style={{ color: "#999999" }}>C</div>
                      <div className="count">{position._events.nipleChargeTest}</div>
                    </li>
                  ))
                }
                {
                  (position._events.hatchOpen > 0 && (
                    <li>
                      <div className="icon">A</div>
                      <div className="count">{position._events.hatchOpen}</div>
                    </li>
                  ))
                }
              </ul>
            </div>
          )
        )
      }
      {
        (
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
          && typeof position._events !== "undefined"
          && (
            position._events.lastNipleCharge ||
            position._events.lastNipleChargeTest ||
            position._events.lastNipleDischarge ||
            position._events.lastNipleDischargeTest
          )
          && (
            <div className="positions-item-events-description">
              <ul>
                {
                  (
                    position._events.lastNipleCharge
                    && (
                      <li>
                        <div className="icon">C</div>
                        <div className="description"><span>{DateUtil.to({ date: new Date(position._events.lastNipleCharge), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    )
                  )
                }
                {
                  (
                    position._events.lastNipleChargeTest
                    && (
                      <li>
                        <div className="icon" style={{ color: "#999999" }}>C</div>
                        <div className="description"><span>{DateUtil.to({ date: new Date(position._events.lastNipleChargeTest), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    )
                  )
                }
                {
                  (
                    position._events.lastNipleDischarge
                    && (
                      <li>
                        <div className="icon">D</div>
                        <div className="description"><span>{DateUtil.to({ date: new Date(position._events.lastNipleDischarge), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    )
                  )
                }
                {
                  (
                    position._events.lastNipleDischargeTest
                    && (
                      <li>
                        <div className="icon" style={{ color: "#999999" }}>D</div>
                        <div className="description"><span>{DateUtil.to({ date: new Date(position._events.lastNipleDischargeTest), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    )
                  )
                }
              </ul>
            </div>
          )
        )
      }
      <ul className="ul-last-position-selected" onClick={onClick}>
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <li>
              <div>{t("Title.Id")}:</div>
              <div><span><strong>{position.id}</strong></span></div>
            </li>
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <li>
              <div>{t("Title.Index")}:</div>
              <div><span><strong>{position.index}</strong></span></div>
            </li>
          ) : null
        }
        <li>
          <div>{t("Title.Reason")}:</div>
          <div><span><strong>{t(getReason(position))} ({position.gps.reason})</strong></span></div>
        </li>
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <li>
              <div>{t("Title.ReasonOriginal")}:</div>
              <div><span><strong>{position.gps.reasonOriginal}</strong></span></div>
            </li>
          ) : null
        }
        <li>
          <div>{t("Title.Client")}:</div>
          <div><span><strong>{getDescriptionClient(position.patrimony.client)}</strong></span></div>
        </li>
        <li>
          <div>{t("Patrimony")}:</div>
          <div><span><strong>{getDescriptionPatrimony(position.patrimony)}</strong></span></div>
        </li>
        {
          isAutomobile(position.patrimony) ? (
            <>
              <li>
                <div>{t("Title.Brand")}:</div>
                <div><span><strong>{position.patrimony.automobile.brand}</strong></span></div>
              </li>
              <li>
                <div>{t("Title.Model")}:</div>
                <div><span><strong>{position.patrimony.automobile.model}</strong></span></div>
              </li>
            </>
          ) : null
        }
        {
          isAutomobileBoardAutoVaccum(position.patrimony) ? (
            <li>
              <div>{t("Title.LAO")}:</div>
              <div><span style={{ color: position.patrimony.automobile.board.autoVaccum.license === "000000/0000" ? "#ff0000" : "#000" }}><strong>{position.patrimony.automobile.board.autoVaccum.license}</strong></span></div>
            </li>
          ) : null
        }
        {
          (isVessel(position.patrimony) && (
            <>
              <li>
                <div>{t("Title.Brand")}:</div>
                <div><span><strong>{position.patrimony.vessel.brand}</strong></span></div>
              </li>
              <li>
                <div>{t("Title.Model")}:</div>
                <div><span><strong>{position.patrimony.vessel.model}</strong></span></div>
              </li>
              <li>
                <div>{t("VesselMotors")}:</div>
                <div><span><strong>{position.patrimony.vessel.motors ?? 1}</strong></span></div>
              </li>
              <li>
                <div>{t("RegistrationNumber")}:</div>
                <div><span>{position.patrimony.vessel.registrationNumber}</span></div>
              </li>
              <li>
                <div>{t("VesselType")}:</div>
                <div><span>{t(`VesselType.${position.patrimony.vessel.type ?? "JET"}`)}</span></div>
              </li>
            </>
          ))
        }
        <li>
          <div>{t("Driver")}:</div>
          <div><span>{typeof position.patrimony.driver !== "undefined" ? position.patrimony.driver.name : t("NoDriver")}</span></div>
        </li>
        <li>
          <div>{t("Tracker")}:</div>
          <div><span>{position.tracker.serial}</span></div>
        </li>
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && typeof position.patrimony.trackers !== "undefined" ? (
            position.patrimony.trackers.filter(x =>
              x.type === "NIP" &&
              x.serial === position.tracker.serial &&
              typeof x.niple !== "undefined" &&
              typeof x.niple.chassi !== "undefined" &&
              typeof x.niple.chassi.serial !== "undefined"
            ).map((v, i) => {
              return (
                <li key={`niple-serial-number-${i}`}>
                  <div>{t("SerialNumber")}:</div>
                  <div><span>{v.niple.chassi.serial}</span></div>
                </li>
              );
            })
          ) : null
        }
        {
          (position.patrimony.installAt && (
            <li>
              <div>{t("Title.InstallAt")}:</div>
              <div><span>{DateUtil.to({ date: new Date(position.patrimony.installAt), format: "DD/MM/YYYY" })}</span></div>
            </li>
          ))
        }
        <li>
          <div>{t("Date")} ({t("GPS")}):</div>
          <div><span>{DateUtil.to({ date: new Date(position.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
        </li>
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <li>
              <div>{t("Date")} ({t("Enter")}):</div>
              <div><span>{DateUtil.to({ date: new Date(position.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
            </li>
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <li>
              <div>{t("Date")} ({t("DateCorrection")}):</div>
              <div><span>{position.tracker.dateCorrection ? t("Active") : t("Inactive")}</span></div>
            </li>
          ) : null
        }
        <li>
          <div>{t("Coordinate")}:</div>
          <div><span>{position.gps.coordinate.latitude} | {position.gps.coordinate.longitude}</span></div>
        </li>
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <>
              <li>
                <div>{t("Title.Gps.Valid")}:</div>
                <div><span>{position.gps.gpsSignal ? t("Active") : t("Inactive")}</span></div>
              </li>
              <li>
                <div>{t("Title.Gps.AntennaFailure")}:</div>
                <div><span>{position.gps.gpsAntennaFailure ? t("Active") : t("Inactive")}</span></div>
              </li>
              <li>
                <div>{t("Title.Gps.AntennaDisconnected")}:</div>
                <div><span>{position.gps.gpsAntennaDisconnected ? t("Active") : t("Inactive")}</span></div>
              </li>
            </>
          ) : null
        }
        <li>
          <div>{t("Course")}:</div>
          <div><span>{position.gps.course}°</span></div>
        </li>
        <li>
          <div>{t("Ignition")}:</div>
          <div><span>{position.tracker.ignition ? t("IgnitionOn") : t("IgnitionOff")}</span></div>
        </li>
        {
          ((typeof position._engineControl !== "undefined" && position._engineControl) && (
            <>
              {
                ((typeof position._engineControl.currentHours !== "undefined") && (
                  <li>
                    <div>{t("Title.MaintenanceMotor.Hours")}:</div>
                    <div><span style={{ color: position.tracker.maintenanceNeed ? "#ff0000" : "#000" }}><strong>{position._engineControl.currentHours} de {position.patrimony.parameters.maintenanceMotorMaxHours}h</strong></span></div>
                  </li>
                ))
              }
              {
                ((typeof position._engineControl.currentMonths !== "undefined") && (
                  <li>
                    <div>{t("Title.MaintenanceMotor.Months")}:</div>
                    <div><span style={{ color: position.tracker.maintenanceNeed ? "#ff0000" : "#000" }}><strong>{position._engineControl.currentMonths} de {position.patrimony.parameters.maintenanceMotorMaxMonths} meses</strong></span></div>
                  </li>
                ))
              }
            </>
          ))
        }
        <li>
          <div>{t("Speed")}:</div>
          <div><span>{position.gps.speed} Km/h</span></div>
        </li>
        <li>
          <div>{t("Anchor")}:</div>
          <div><span>{position.tracker.anchor ? t("Active") : t("Inactive")}</span></div>
        </li>
        <li>
          <div>{t("Block")}:</div>
          <div><span>{position.tracker.block ? t("Active") : t("Inactive")}</span></div>
        </li>
        {
          (pump &&
            <li>
              <div>{t("Title.Pump")}:</div>
              <div><span>{t(pump.operation)}</span></div>
            </li>
          )
        }
        <li>
          <div>{t("Battery")}:</div>
          <div><span>{getBattery(position) ? t("BatteryLow") : t("BatteryHigh")}</span></div>
        </li>
        <li>
          <div>{t("PowerSupply")}:</div>
          <div><span>{getPowerSupply(position)}</span></div>
        </li>
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <li>
              <div>{t("DelayedLocation")}:</div>
              <div><span>{position.tracker.delayedLocation ? t("Active") : t("Inactive")}</span></div>
            </li>
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <li>
              <div>{t("Title.Tracker.Help.Input1")}:</div>
              <div><span>{position.tracker.input1 ? t("Active") : t("Inactive")}</span></div>
            </li>
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <li>
              <div>{t("Title.Tracker.Help.Input2")}:</div>
              <div><span>{position.tracker.input2 ? t("Active") : t("Inactive")}</span></div>
            </li>
          ) : null
        }
        <li>
          <div>{t("Jammer")}:</div>
          <div><span>{getJammer(position) ? t("Active") : t("Inactive")}</span></div>
        </li>
        <li>
          <div>{t("Panic")}:</div>
          <div><span>{position.tracker.panic ? t("Active") : t("Inactive")}</span></div>
        </li>
        <li>
          <div>{t("Title.Niple.Maintenance")}:</div>
          <div><span>{position.tracker.maintenance ? t("Active") : t("Inactive")}</span></div>
        </li>
        <li>
          <div>{t("Moving")}:</div>
          <div><span>{position.tracker.moving ? t("Active") : t("Inactive")}</span></div>
        </li>
        <li>
          <div>{t("Title.VirtualFence")}:</div>
          <div><span>{typeof position.virtualFence !== "undefined" ? position.virtualFence.name : t("Inactive")}</span></div>
        </li>
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:basic", "read:patrimonies:description"], every: false }) && niples.map((niple, index) => {
            return (
              <li key={`lps-niple-op-${index}`}>
                <div>{t("Title.Niple")} {(index + 1)}:</div>
                <div><span>{t(niple.operationBoard ? niple.operationBoard : niple.operationBoardFraud)}</span></div>
              </li>
            );
          })
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && hatchs.map((hatch, index) => {
            return (
              <li key={`lps-hatch-op-${index}`}>
                <div>{t("Title.Hatch")} {(index + 1)}:</div>
                <div><span>{t(hatch.operation)}</span></div>
              </li>
            );
          })
        }
        <div className="address">
          { position.gps.address }
        </div>
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <>
              <li className="niple-box">
                <ul>
                  <li className="niple-box-title">
                    <div>{t("Title.Rs232")}:</div>
                  </li>
                  <li>
                    <div>{t("Title.Rs232.Parsed")}:</div>
                    <div><span>{position.rs232 ? t(`Title.Rs232.Parsed.${position.rs232.parsed}`) : t(`Title.Rs232.Parsed.NOT`)}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Rs232.Content")}:</div>
                    {
                      ((!position.rs232 || (position.rs232 && (!position.rs232.content || position.rs232.content === ""))) && (
                        <div><span>{t(`Title.Rs232.Parsed.NOT`)}</span></div>
                      ))
                    }
                  </li>
                  {
                    ((position.rs232 && position.rs232.content && position.rs232.content !== "") && (
                      <li className="box-full-text-li">
                        <div className="box-full-text"><pre>{position.rs232.parsed === "CON" ? prettierJson(Buffer.from(position.rs232.content, "hex").toString()) : position.rs232.content}</pre></div>
                      </li>
                    ))
                  }
                </ul>
              </li>
            </>
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <li className="niple-box">
              <ul>
                <li className="niple-box-title">
                  <div>{t("Title.VirtualFence")}:</div>
                </li>
                <li>
                  <div>{t("Title.VirtualFence.Id")}:</div>
                  <div><span>{typeof position.virtualFence !== "undefined" ? position.virtualFence.id : ""}</span></div>
                </li>
                <li>
                  <div>{t("Title.VirtualFence.Name")}:</div>
                  <div><span>{typeof position.virtualFence !== "undefined" ? position.virtualFence.name : ""}</span></div>
                </li>
              </ul>
            </li>
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <li className="niple-box">
              <ul>
                <li className="niple-box-title">
                  <div>{t("Title.Chip")}:</div>
                </li>
                <li>
                  <div>{t("Title.Line")}:</div>
                  <div><span>{getTrackerChip(position, position.tracker.serial).line}</span></div>
                </li>
                <li>
                  <div>{t("Title.Serial")}:</div>
                  <div><span>{getTrackerChip(position, position.tracker.serial).serial}</span></div>
                </li>
              </ul>
            </li>
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && typeof position.blockerNetwork !== "undefined" ? (
            position.blockerNetwork.blockers.map((blocker, index) => {
              return (
                <li className="niple-box" key={`lps-blocker-description-${index}`}>
                  <ul>
                    <li><div>Blocker {index + 1}:</div></li>
                    <li><div>Id: <span>{blocker.id}</span></div></li>
                    <li><div>Connection: <span>{blocker.connection ? "ON" : "OFF"}</span></div></li>
                    <li><div>Status: <span>{blocker.status ? "ON" : "OFF"}</span></div></li>
                  </ul>
                </li>
              );
            })
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && typeof position.command !== "undefined" ? (
            <li className="niple-box" key={`lps-command-description-${position.id}`}>
	          <ul>
	            <li><div>Command:</div></li>
	            <li><div>String: <span>{position.command.string}</span></div></li>
	            <li><div>Sender: <span>{position.command.sender}</span></div></li>
	          </ul>
	        </li>
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && typeof position.nipleNetwork !== "undefined" ? (
            position.nipleNetwork.niples.map((niple, index) => {
              const type = position.patrimony.trackers.find(x => x.serial === niple.serial);
              const chip = typeof type !== "undefined" ? (typeof type.chip !== "undefined" ? type.chip : false) : false;
              const externalPowerReason = [];
              if (niple.fraud === "EPF" || position.tracker.charging) externalPowerReason.push("Niple");
              if (getBattery(position)) externalPowerReason.push("Rastreador");
              return (
                <li className="niple-box" key={`lps-niple-description-${index}`}>
                  <ul>
                    <li className="niple-box-title">
                      <div>{t("Title.Niple")} {(index + 1)}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Serial")}:</div>
                      <div><span>{niple.serial}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Version")}:</div>
                      <div><span>{niple.version}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Date")}:</div>
                      <div><span>{DateUtil.to({ date: new Date(niple.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Coordinate")}:</div>
                      <div><span>{niple.gps.coordinate.latitude} | {niple.gps.coordinate.longitude}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.ExternalPower")}:</div>
                      <div><span>{(niple.fraud === "EPF" || position.tracker.charging || getBattery(position)) ? t("Title.Inactive") : t("Title.Active")}</span></div>
                    </li>
                    {
                      (externalPowerReason && externalPowerReason.length > 0 && (
                        <li>
                          <div>{t("Title.Niple.ExternalPowerReason")}:</div>
                          <div><span>{externalPowerReason.join(", ")}</span></div>
                        </li>
                      ))
                    }
                    <li>
                      <div>{t("Title.Niple.Fraud")}:</div>
                      <div><span>{t(getNipleFraud(position, niple))}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Operation")}:</div>
                      <div><span>{t(getNipleOperation(position, niple))}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Charge")}:</div>
                      <div><span>{!niple.sensors.charge ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Discharge")}:</div>
                      <div><span>{!niple.sensors.discharge ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Center")}:</div>
                      <div><span>{!niple.sensors.defaultPosition ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Head")}:</div>
                      <div><span>{!niple.sensors.defaultHead ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li className="niple-box-title-full">
                      <div>{t("Title.Niple.Electrodes")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.S1")}:</div>
                      <div><span>{niple.sensors.s1}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.S2")}:</div>
                      <div><span>{niple.sensors.s2}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.S3")}:</div>
                      <div><span>{niple.sensors.s3}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.S4")}:</div>
                      <div><span>{niple.sensors.s4}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.S5")}:</div>
                      <div><span>{niple.sensors.s5}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.S6")}:</div>
                      <div><span>{niple.sensors.s6}</span></div>
                    </li>
                    <li className="niple-box-title-full">
                      <div>{t("Title.Niple.Gyroscope")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Gyroscope.X")}:</div>
                      <div><span>{niple.gyroscope.x}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Gyroscope.Y")}:</div>
                      <div><span>{niple.gyroscope.y}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Gyroscope.Z")}:</div>
                      <div><span>{niple.gyroscope.z}</span></div>
                    </li>
                    <li className="niple-box-title-full">
                      <div>{t("Title.Niple.Chip")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Chip.Line")}:</div>
                      <div><span>{chip ? chip.line : ""}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Chip.Serial")}:</div>
                      <div><span>{chip ? chip.serial : ""}</span></div>
                    </li>
                    {
                      typeof niple.chassi !== "undefined" ? (
                        <>
                          <li className="niple-box-title-full">
                            <div>{t("Title.Chassi")}:</div>
                          </li>
                          <li>
                            <div>{t("Title.Chassi.Id")}:</div>
                            <div><span>{niple.chassi.id}</span></div>
                          </li>
                          <li>
                            <div>{t("Title.Chassi.Serial")}:</div>
                            <div><span>{niple.chassi.serial}</span></div>
                          </li>
                        </>
                      ) : null
                    }
                    {
                      typeof niple.rfid !== "undefined" ? (
                        <>
                          <li className="niple-box-title-full">
                            <div>{t("Title.Rfid")}:</div>
                          </li>
                          <li>
                            <div>{t("Title.Rfid.Id")}:</div>
                            <div><span>{niple.rfid.id}</span></div>
                          </li>
                          <li>
                            <div>{t("Title.Rfid.Number")}:</div>
                            <div><span>{niple.rfid.number}</span></div>
                          </li>
                          <li>
                            <div>{t("Title.Rfid.Serial")}:</div>
                            <div><span>{niple.rfid.serial}</span></div>
                          </li>
                        </>
                      ) : null
                    }
                  </ul>
                </li>
              );
            })
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && typeof position.nipleNetwork !== "undefined" ? (
            position.nipleNetwork.hatchs.map((hatch, index) => {
              const type = position.patrimony.trackers.find(x => x.serial === hatch.serial);
              const chip = typeof type !== "undefined" ? (typeof type.chip !== "undefined" ? type.chip : false) : false;
              const externalPowerReason = [];
              if (hatch.externalPower) externalPowerReason.push("Escotilha");
              if (getBattery(position)) externalPowerReason.push("Rastreador");
              return (
                <li className="niple-box" key={`lps-hatch-description-${index}`}>
                  <ul>
                    <li className="niple-box-title">
                      <div>{t("Title.Hatch")} {(index + 1)}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Type")}:</div>
                      <div><span>{t(getHatchType(type, true))}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Serial")}:</div>
                      <div><span>{hatch.serial}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Date")}:</div>
                      <div><span>{DateUtil.to({ date: new Date(hatch.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Status")}:</div>
                      <div><span>{getHatchStatus(hatch) && !getValidMoving(position) ? t("Title.Hatch.Open") : t("Title.Hatch.Closed")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.ExternalPower")}:</div>
                      <div><span>{(hatch.externalPower || getBattery(position)) ? t("Title.Inactive") : t("Title.Active")}</span></div>
                    </li>
                    {
                      (externalPowerReason && externalPowerReason.length > 0 && (
                        <li>
                          <div>{t("Title.Hatch.ExternalPowerReason")}:</div>
                          <div><span>{externalPowerReason.join(", ")}</span></div>
                        </li>
                      ))
                    }
                    <li className="niple-box-title-full">
                      <div>{t("Title.Hatch.Distance")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Distance.Bigger")}:</div>
                      <div><span>{hatch.sensors.greaterDistance}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Distance.Smaller")}:</div>
                      <div><span>{hatch.sensors.shortestDistance}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Distance.Current")}:</div>
                      <div><span>{hatch.sensors.distance}</span></div>
                    </li>
                    <li className="niple-box-title-full">
                      <div>{t("Title.Hatch.Niple")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Niple.Serial")}:</div>
                      <div><span>{hatch.niple.serial}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Niple.Connection")}:</div>
                      <div><span>{hatch.niple.connection ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Niple.CRC")}:</div>
                      <div><span>{hatch.niple.crc ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Niple.Coordinates")}:</div>
                      <div><span>{hatch.niple.last.coordinate.latitude} | {hatch.niple.last.coordinate.longitude}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Niple.Date")}:</div>
                      <div><span>{DateUtil.to({ date: new Date(hatch.niple.last.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                    </li>
                    <li className="niple-box-title-full">
                      <div>{t("Title.Hatch.Gyroscope")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Gyroscope.X")}:</div>
                      <div><span>{hatch.gyroscope.x}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Gyroscope.Y")}:</div>
                      <div><span>{hatch.gyroscope.y}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Gyroscope.Z")}:</div>
                      <div><span>{hatch.gyroscope.z}</span></div>
                    </li>
                    <li className="niple-box-title-full">
                      <div>{t("Title.Hatch.Chip")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Chip.Line")}:</div>
                      <div><span>{chip ? chip.line : ""}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Chip.Serial")}:</div>
                      <div><span>{chip ? chip.serial : ""}</span></div>
                    </li>
                  </ul>
                </li>
              );
            })
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && typeof position.trackersBackup !== "undefined" ? (
          	 position.trackersBackup.map((trackerBackup, index) => {
              const type = position.patrimony.trackers.find(x => x.serial === trackerBackup.serial);
              const chip = typeof type !== "undefined" ? (typeof type.chip !== "undefined" ? type.chip : false) : false;
              return (
                <li className="niple-box" key={`lps-tracker-backup-description-${index}`}>
                  <ul>
                    <li className="niple-box-title">
                      <div>{t("Title.Tracker.Help")} {(index + 1)}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Serial")}:</div>
                      <div><span>{trackerBackup.serial}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Date")}:</div>
                      <div><span>{DateUtil.to({ date: new Date(trackerBackup.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Coordinate")}:</div>
                      <div><span>{trackerBackup.coordinate.latitude} | {trackerBackup.coordinate.longitude}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Ignition")}:</div>
                      <div><span>{trackerBackup.ignition ? t("IgnitionOn") : t("IgnitionOff")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Input1")}:</div>
                      <div><span>{trackerBackup.input1 ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Input2")}:</div>
                      <div><span>{trackerBackup.input2 ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Jammer")}:</div>
                      <div><span>{trackerBackup.jammer ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Battery")}:</div>
                      <div><span>{trackerBackup.battery ? t("Title.Tracker.Help.BatteryLow") : t("Title.Tracker.Help.BatteryHigh")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Moving")}:</div>
                      <div><span>{trackerBackup.moving ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li className="niple-box-title-full">
                      <div>{t("Title.Tracker.Help.Chip")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Chip.Line")}:</div>
                      <div><span>{chip ? chip.line : ""}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Chip.Serial")}:</div>
                      <div><span>{chip ? chip.serial : ""}</span></div>
                    </li>
                  </ul>
                </li>
              );
            })
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master"], every: false }) && patrimonyTest ? (
            <li className="niple-box" onClick={e => e.stopPropagation()}>
              <ul>
                {
                  patrimonyTest ? (
                    <>
                      <li className="niple-box-title-full">
                        <div>{t("Title.PatrimonyTest")}:</div>
                      </li>
                      <li className="grid-3-1-5 gray">
                        <div>{t("Title.PatrimonyTest.Status")}:</div>
                        <div><span>{t(getStatus(patrimonyTest))}</span></div>
                      </li>
                      <li className="niple-box-title-full gray">
                        <div>{t("Title.Justify")}:</div>
                      </li>
                      <li className="niple-box-title-full gray">
                        <div>
                          <span>
                            {
                              patrimonyTest.status === "CON" ? (
                                <Form.Control style={{ border: 0, backgroundColor: "transparent" }} as="textarea" readOnly={true} value={patrimonyTest.justify} />
                              ) : (
                                <Form onSubmit={handleSubmitPatrimonyTests}>
                                  <Form.Group>
                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      value={justifyPatrimonyTests}
                                      onChange={e => setJustifyPatrimonyTests(e.target.value)}
                                    />
                                  </Form.Group>
                                  <Form.Group className="default-form-button">
                                    <Button
                                      variant="dark"
                                      type="submit"
                                      disabled={runningPatrimonyTests}
                                    >
                                      {runningPatrimonyTests ? <Spinner animation="border" size="sm" /> : t("Button.Conclude")}
                                    </Button>
                                  </Form.Group>
                                </Form>
                              )
                            }
                          </span>
                        </div>
                      </li>
                    </>
                  ) : null
                }
                {
                  patrimonyTest ? (
                    patrimonyTest.positionsAux.map((positionAux, indexAux) => {
                      return (
                        <React.Fragment key={`lps-patrimony-patrimonyTest-main-${indexAux}`}>
                          {
                            positionAux.itens.map((position, index) => {
                              return (
                                <React.Fragment key={`lps-patrimony-patrimonyTest-title-${index}`}>
                                  {
                                    index === 0 ? (
                                      <li className="niple-box-title-full">
                                        <div>{t(positionAux.title)}:</div>
                                      </li>
                                    ) : null
                                  }
                                  <li className="grid-3-2 gray">
                                    <div>{t(`Title.PatrimonyTest.${position.tag.replace(`_${position.tracker.serial}`, "")}`)}:</div>
                                    {
                                      patrimonyTest.status !== "CON" ? (
                                        <div><span>{position.success ? <>{t("Title.Success")} <i className="fas fa-check" /></> : <>{position.manual ? <SwitchDefault selected={checkPositionsPatrimonyTests(position.tag)} small={true} onClick={() => togglePositionsPatrimonyTests(position.tag)} /> : <>{t("Title.Waiting")} <i className="fas fa-spinner" /></>}</>}</span></div>
                                      ) : (
                                        <div><span>{position.success ? <>{t("Title.Success")} <i className="fas fa-check" /><br /></> : <>{t("Title.Fail")} <i className="fas fa-exclamation-triangle" /></>}</span></div>
                                      )
                                    }
                                  </li>
                                  {
                                    position.success ? (
                                      <li className="grid-3-2 gray">
                                        <div>{t("Title.Date")}:</div>
                                        <div><span>{DateUtil.to({ date: new Date(position.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                                      </li>
                                    ) : (
                                      position.manual && checkPositionsPatrimonyTests(position.tag) ? (
                                        <li className="grid-3-2 gray">
                                          <div>{t("Title.Date")}:</div>
                                          <div><span>{DateUtil.to({ date: new Date(checkPositionsPatrimonyTests(position.tag, false).date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                                        </li>
                                      ) : null
                                    )
                                  }
                                </React.Fragment>
                              );
                            })
                          }
                        </React.Fragment>
                      );
                    })
                  ) : null
                }
              </ul>
            </li>
          ) : null
        }
        {
          isPatrimonyId(patrimony.id) ? (
            <li className="niple-box" onClick={e => e.stopPropagation()}>
              <ul>
                <li className="niple-box-title-full">
                  <div>
                    {t("Title.QRCode")}: <Button
                      variant="dark"
                      type="button"
                      style={{ float: "right" }}
                      onClick={() => { enableQrcode(patrimony.id) }}>{t("Title.Download")}</Button>
                  </div>
                </li>
                <li className="niple-box-title-full qrcode">
                  <QRCode
                    id={`canvas-qrcode-${patrimony.id}`}
                    value={patrimony.id}
                    qrStyle={"square"}
                    fgColor={"#000000"}
                    size={150}
                  />
                </li>
              </ul>
            </li>
          ) : null
        }
      </ul>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    lastPatrimonyTests: state.lastPatrimonyTests.patrimonyTests
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, NotificationsActions, QrcodesActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastPositionSelectedItem);
