import React, { useState } from "react";
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as DatabaseTestActions } from "./../../store/ducks/databaseTest";
import { Creators as LastPositionActions } from "./../../store/ducks/lastPositions";
import { Creators as LoadersActions } from "./../../store/ducks/loaders";

import AddressService from "./../../services/Address";
import MaintenanceService from "./../../services/Maintenance";
import GyroscopeService from "./../../services/Gyroscope";
import TrackerService from "./../../services/Tracker";

import PinBoard from "./../PinBoard";

import { getDescriptionPatrimony, isPatrimonyId, isVessel } from "./../../utils/Patrimony";
import { isScopes } from "./../../services/Auth";

const DEFAULT_PIN_BOARD = {
  description: "",
  handleCancel: () => {},
  handleExecute: () => {},
  onlyConfirm: false,
  open: false
};

const MAINTENANCE_TYPE = {
  DEF: "DEF",
  SPE: "SPE",
}

const LastPositionSelectedItemCommandItem = ({
  options: { position },
  props: { currentUser, databaseTest },
  funcs: { delLastPosition, disableDatabaseTest, disableLoader, enableDatabaseTest, enableLoader }
}) => {
  const { t } = useTranslation();
  const [pinBoard, setPinBoard] = useState(DEFAULT_PIN_BOARD);

  const addressService = new AddressService();
  const gyroscopeService = new GyroscopeService();
  const maintenanceService = new MaintenanceService();
  const trackerService = new TrackerService();

  const resetPinBoard = () => {
    setPinBoard(DEFAULT_PIN_BOARD);
  };

  const handleReverseGeocodeAddress = async () => {
    if (position.gps.addressReverseGeocode === false) {
      enableLoader();
      const response = await addressService.reverseGeocode({
        data: {
          address: position.gps.address,
          coordinate: position.gps.coordinate,
          tracker: {
            serial: position.tracker.serial
          }
        },
        token: currentUser.token
      });
      position.gps.addressReverseGeocode = true;
      disableLoader();
      return response;
    }
  };

  const handleCreateMaintenance = async ({ passwordPin, position, type = "DEF" }) => {
    enableLoader();
    const response = await maintenanceService.create({
      data: {
        coordinate: position.gps.coordinate,
        patrimony: {
          client: position.patrimony.client,
          description: getDescriptionPatrimony(position.patrimony),
          id: position.patrimony.id,
          trackers: position.patrimony.trackers
        },
        tracker: {
          serial: position.tracker.serial
        },
        type
      },
      passwordPin,
      token: currentUser.token
    });
    if (response.success) {
      position.tracker.maintenance = !position.tracker.maintenance;
    }
    disableLoader();
    return response;
  };

  const handleCreateGyroscope = async ({ passwordPin, position }) => {
    enableLoader();
    const response = await gyroscopeService.create({
      data: {
        patrimony: {
          client: position.patrimony.client,
          description: getDescriptionPatrimony(position.patrimony),
          id: position.patrimony.id,
          trackers: position.patrimony.trackers
        },
        tracker: {
          serial: position.tracker.serial
        }
      },
      passwordPin,
      token: currentUser.token
    });
    if (response.success) {
      position.tracker.gyroscope = !position.tracker.gyroscope;
    }
    disableLoader();
    return response;
  };

  const handleClearCache = async ({ passwordPin, position }) => {
    enableLoader();
    const response = await trackerService.clearCache({
      data: {
        trackers: position.patrimony.trackers.map(tracker => tracker.serial)
      },
      passwordPin,
      token: currentUser.token
    });
    if (response.success) {
      delLastPosition(position.tracker.serial);
    }
    disableLoader();
    return response;
  };

  const handleToggleInstalled = async ({ databaseTest, passwordPin, position }) => {
    enableLoader();
    const response = await trackerService.toggleInstalled({
      data: {
        main: databaseTest,
        trackers: position.patrimony.trackers
      },
      passwordPin,
      token: currentUser.token
    });
    if (databaseTest) {
      disableDatabaseTest();
      setTimeout(() => {
        enableDatabaseTest();
      }, 100);
    }
    else {
      enableDatabaseTest();
      setTimeout(() => {
        disableDatabaseTest();
      }, 100);
    }
    disableLoader();
    return response;
  };

  return (
    <>
      <ul>
        {
          !isScopes({ currentUser, scopes: ["is:guest"], every: false }) && isPatrimonyId(position.patrimony.id) ? (
            <li>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{position.tracker.maintenance ? t("Title.Maintenance.Disable") : t("Title.Maintenance.Enable")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <button
                  className={`commands-button ${position.tracker.maintenance ? "active" : ""}`}
                  type="button"
                  onClick={() => {
                    setPinBoard(pinBoard => {
                      return {
                        ...pinBoard,
                        description: (
                          <>
                            <i className="fas fa-wrench" />
                            {position.tracker.maintenance ? t("Title.Maintenance.Disable") : t("Title.Maintenance.Enable")}
                            <span style={{ margin: "auto", display: "block", textAlign: "center" }}>{getDescriptionPatrimony(position.patrimony)}</span>
                          </>
                        ),
                        handleCancel: () => resetPinBoard(),
                        handleExecute: async ({ passwordPin }) => await handleCreateMaintenance({ passwordPin, position, type: MAINTENANCE_TYPE.DEF }),
                        open: true
                      };
                    });
                  }}
                >
                  <div className="commands-icon"><i className="fas fa-wrench" /></div>
                </button>
              </OverlayTrigger>
            </li>
          ) : null
        }
        {
          !isScopes({ currentUser, scopes: ["is:guest"], every: false }) && isPatrimonyId(position.patrimony.id) && isVessel(position.patrimony) && !position.tracker.maintenance ? (
            <li>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{position.tracker.maintenance ? t("Title.Maintenance.Special.Disable") : t("Title.Maintenance.Special.Enable")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <button
                  className={`commands-button ${position.tracker.maintenance ? "active" : ""}`}
                  type="button"
                  onClick={() => {
                    setPinBoard(pinBoard => {
                      return {
                        ...pinBoard,
                        description: (
                          <>
                            <i className="fa-solid fa-screwdriver-wrench" />
                            {position.tracker.maintenance ? t("Title.Maintenance.Special.Disable") : t("Title.Maintenance.Special.Enable")}
                            <span style={{ margin: "auto", display: "block", textAlign: "center" }}>{getDescriptionPatrimony(position.patrimony)}</span>
                          </>
                        ),
                        handleCancel: () => resetPinBoard(),
                        handleExecute: async ({ passwordPin }) => await handleCreateMaintenance({ passwordPin, position, type: MAINTENANCE_TYPE.SPE }),
                        open: true
                      };
                    });
                  }}
                >
                  <div className="commands-icon"><i className="fa-solid fa-screwdriver-wrench" /></div>
                </button>
              </OverlayTrigger>
            </li>
          ) : null
        }
        {
          !isScopes({ currentUser, scopes: ["is:guest"], every: false }) ? (
            <li>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{position.gps.addressReverseGeocode ? `${t("Title.Address.Found")}: ${position.gps.address}` : t("Title.Address.Search")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <button
                  className={`commands-button ${position.gps.addressReverseGeocode ? "active" : ""}`}
                  type="button"
                  onClick={() => {
                    setPinBoard(pinBoard => {
                      return {
                        ...pinBoard,
                        description: (
                          <>
                            <i className="fas fa-map-marked-alt" />
                            {position.gps.addressReverseGeocode ? `${t("Title.Address.Found")}: ${position.gps.address}` : t("Title.Address.Search")}
                            <span style={{ margin: "auto", display: "block", textAlign: "center" }}>{getDescriptionPatrimony(position.patrimony)}</span>
                          </>
                        ),
                        handleCancel: () => resetPinBoard(),
                        handleExecute: async () => await handleReverseGeocodeAddress(),
                        onlyConfirm: true,
                        open: true
                      };
                    });
                  }}
                >
                  <div className="commands-icon"><i className="fas fa-map-marked-alt" /></div>
                </button>
              </OverlayTrigger>
            </li>
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master"], every: false }) && isPatrimonyId(position.patrimony.id) ? (
            <li>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{position.tracker.gyroscope ? t("Title.Gyroscope.Disable") : t("Title.Gyroscope.Enable")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <button
                  className={`commands-button ${position.tracker.gyroscope ? "active" : ""}`}
                  type="button"
                  onClick={() => {
                    setPinBoard(pinBoard => {
                      return {
                        ...pinBoard,
                        description: (
                          <>
                            <i className="fas fa-wrench" />
                            {position.tracker.gyroscope ? t("Title.Gyroscope.Disable") : t("Title.Gyroscope.Enable")}
                            <br />
                            <span style={{ margin: "auto", display: "block", textAlign: "center" }}>{getDescriptionPatrimony(position.patrimony)}</span>
                          </>
                        ),
                        handleCancel: () => resetPinBoard(),
                        handleExecute: async ({ passwordPin }) => await handleCreateGyroscope({ passwordPin, position }),
                        open: true
                      };
                    })
                  }}
                >
                  <div className="commands-icon"><i className="fas fa-wrench" /></div>
                </button>
              </OverlayTrigger>
            </li>
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master"], every: false }) && isPatrimonyId(position.patrimony.id) ? (
            <li>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{databaseTest ? t("Title.Database.ChangeToMain") : t("Title.Database.ChangeToTest")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <button
                  className={"commands-button"}
                  type="button"
                  onClick={() => {
                    setPinBoard(pinBoard => {
                      return {
                        ...pinBoard,
                        description: (
                          <>
                            <i className="fas fa-database" />
                            {databaseTest ? t("Title.Database.ChangeToMain") : t("Title.Database.ChangeToTest")}
                            <br />
                            <span style={{ margin: "auto", display: "block", textAlign: "center" }}>{getDescriptionPatrimony(position.patrimony)}</span>
                          </>
                        ),
                        handleCancel: () => resetPinBoard(),
                        handleExecute: async ({ passwordPin }) => await handleToggleInstalled({ databaseTest, passwordPin, position }),
                        open: true
                      };
                    });
                  }}
                >
                  <div className="commands-icon"><i className="fas fa-database" /></div>
                </button>
              </OverlayTrigger>
            </li>
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master"], every: false }) ? (
            <li>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{t("Title.ClearCache")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <button
                  className={"commands-button"}
                  type="button"
                  onClick={() => {
                    setPinBoard(pinBoard => {
                      return {
                        ...pinBoard,
                        description: (
                          <>
                            <i className="fas fa-eraser" />
                            {t("Title.ClearCache")}
                            <br />
                            <span style={{ margin: "auto", display: "block", textAlign: "center" }}>{getDescriptionPatrimony(position.patrimony)}</span>
                          </>
                        ),
                        handleCancel: () => resetPinBoard(),
                        handleExecute: async ({ passwordPin }) => await handleClearCache({ passwordPin, position }),
                        open: true
                      };
                    })
                  }}
                >
                  <div className="commands-icon"><i className="fas fa-eraser" /></div>
                </button>
              </OverlayTrigger>
            </li>
          ) : null
        }
      </ul>
      {
        (pinBoard.open && (
          <PinBoard
            description={pinBoard.description}
            handleCancel={pinBoard.handleCancel}
            handleExecute={pinBoard.handleExecute}
            onlyConfirm={pinBoard.onlyConfirm}
          />
        ))
      }
    </>
  );
};

const mapStateToProps = state => ({
  props: {
    databaseTest: state.databaseTest.databaseTest,
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, DatabaseTestActions, LastPositionActions, LoadersActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastPositionSelectedItemCommandItem);
