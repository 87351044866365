import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";

import { Creators as DatabaseTestActions } from "./../../store/ducks/databaseTest";
import { Creators as LastDateCorrectionActions } from "./../../store/ducks/lastDateCorrections";
import { Creators as LastDelayedLocationActions } from "./../../store/ducks/lastDelayedLocations";
import { Creators as LastExporterErrorActions } from "./../../store/ducks/lastExporterErrors";
import { Creators as LastNotificationActions } from "./../../store/ducks/lastNotifications";
import { Creators as LastPatrimonyTestsActions } from "./../../store/ducks/lastPatrimonyTests";
import { Creators as LastPositionActions } from "./../../store/ducks/lastPositions";
import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as NotificationsActions } from "./../../store/ducks/notifications";
import { Creators as UsersActions } from "./../../store/ducks/users";

import Ws from "./../../services/Ws";
import DateUtil from "./../../utils/Date";
import { getCommadName } from "../../utils/Command";

const WebSocket = ({
  props: {
    currentToken,
    currentUser,
    databaseMounted,
    databaseRefresh,
    databaseTest,
    lastPositionsLoad
  },
  funcs: {
    addLastDelayedLocation,
    addLastExporterError,
    addLastNotification,
    addLastPosition,
    addLastPatrimonyTest,
    addNotice,
    checkExpiredLastNotifications,
    disableDatabaseRefresh,
    disableLoader,
    enableDatabaseRefresh,
    enableLoader,
    setCurrentUser,
    setLastDateCorrection,
    setLastDelayedLocation,
    setLastExporterError,
    setLastNotification,
    setLastPatrimonyTest,
    setLastPosition,
    toggleDatabaseTest,
    updateCurrentUser,
    updateLastPosition
  }
}) => {
  const { t } = useTranslation();
  const [ws, setWs] = useState(false);

  useEffect(() => {
    initWebsocket();
  }, []); /* eslint-disable-line */

  useEffect(() => {
    if(currentUser) {
      if(lastPositionsLoad) {
        disableLoader();
      }
      else {
        enableLoader();
      }
    }
  }, [lastPositionsLoad]); /* eslint-disable-line */

  const initWebsocket = () => {
    const ws = new Ws({
      callbacks: [
        {
          key: "connect",
          action: (e) => {}
        },
        {
          key: "disconnect",
          action: () => {}
        },
        {
          key: "commandHistory",
          action: (e) => {
            const active = e.active ?? false;
            const canceled = e.canceled ?? false;
            const error = e.error ?? false;
            const started = e.started ?? false;
            let status = null;
            if (active) status = t("Success.Command.Generated");
            else if (canceled) {
              if (error) status = `${t("Error.Command.Generated")} ${t("Title.ReasonCommand")}: ${e.reason}.`;
              else status = `${t("Error.Command.Generated.Canceled")} ${t("Title.ReasonCommand")}: ${e.reason}.`;
            }
            else if (!canceled && error) status = t("Error.Command.Generated.NotFound");
            else if (started) status = t("Success.Command.Generated.Running");

            if (status !== null) {
              addNotice({
                refId: `command-id-${e.id}`,
                title: t("Title.Commands"),
                content: `${getCommadName(e.type).label} do ${e.patrimony.description}: ${status}`
              });
            }
          }
        },
        {
          key: "expiredNotification",
          action: () => {
            checkExpiredLastNotifications();
          }
        },
        {
          key: "exporterError",
          action: (e) => {
            addLastExporterError(e);
          }
        },
        {
          key: "position",
          action: (e) => {
            addLastDelayedLocation(e);
            addLastPosition(e);
          }
        },
        {
          key: "lastExporterErrors",
          action: (e) => {
            const lastExporterErrors = [];
            e.lastExporterErrors.map(exporterErrors => exporterErrors.map(ee => lastExporterErrors.push(ee)));
            setLastExporterError(lastExporterErrors);
          }
        },
        {
          key: "lastNotifications",
          action: (e) => {
            setLastNotification(e.lastNotifications);
          }
        },
        {
          key: "lastPatrimonyTests",
          action: (e) => {
            setLastPatrimonyTest(e.lastPatrimonyTests);
          }
        },
        {
          key: "lastPositions",
          action: (e) => {
            setLastDateCorrection(e.lastPositions.filter(x => x.tracker.dateCorrection));
            setLastDelayedLocation(e.lastPositions.filter(x => x.tracker.delayedLocation));
            setLastPosition(e.lastPositions);
          }
        },
        {
          key: "notification",
          action: (e) => {
            if(e.users.findIndex(x => x.id === currentUser.id) !== -1) {
              addLastNotification(e);
              addNotice({
                title: e.title,
                content: e.body
              });
            }
          }
        },
        {
          key: "patrimonyTest",
          action: (e) => {
            if(currentUser.scopes.findIndex(x => x.scope === "is:installer") !== -1) {
              addLastPatrimonyTest(e);
            }
          }
        },
        {
          key: "report",
          action: (e) => {
            if(e.user.id === currentUser.id) {
              const active = e.active ?? false;
              const canceled = e.canceled ?? false;
              const error = e.error ?? false;
              const progress =  e.progress ?? -1;
              const endedAtEstimated = e.endedAtEstimated ?? -1;
              const started = e.started ?? false;
              let status = null;
              if (active) status = "Success.Report.Generated";
              else if (canceled) {
                if (error) status = "Error.Report.Generated";
                else status = "Error.Report.Generated.Canceled";
              }
              else if (!canceled && error) status = "Error.Report.Generated.NotFound";
              else if (started) status = "Success.Report.Generated.Running";

              if (status === "Success.Report.Generated.Running") {
                if (progress === -1) status = null;
                else {
                  const estimatedSeconds = DateUtil.diff({ dateA: endedAtEstimated, dateB: (new Date()).toISOString(), format: "seconds", tz: "America/Sao_Paulo"});
                  const estimated = DateUtil.toHHMMSS(estimatedSeconds >= 0 ? estimatedSeconds : (estimatedSeconds * -1));
                  status = t(status);
                  status = status.replace("{ESTIMATED}", `${estimated}`);
                  status = status.replace("{PERCENT}", `${progress}%`);
                }
              }
              else if (status !== null) status = t(status);

              if (status !== null) {
                addNotice({
                  refId: `report-id-${e.id}`,
                  title: t("Title.Reports"),
                  content: status
                });
              }
            }
          }
        },
        {
          key: "reportProgress",
          action: (e) => {
            if(e.user.id === currentUser.id) {
              const active = e.active ?? false;
              const canceled = e.canceled ?? false;
              const error = e.error ?? false;
              const progress =  e.progress ?? -1;
              const endedAtEstimated = e.endedAtEstimated ?? -1;
              const started = e.started ?? false;
              let status = null;
              if (active) status = "Success.Report.Generated";
              else if (canceled) {
                if (error) status = "Error.Report.Generated";
                else status = "Error.Report.Generated.Canceled";
              }
              else if (!canceled && error) status = "Error.Report.Generated.NotFound";
              else if (started) status = "Success.Report.Generated.Running";

              if (status === "Success.Report.Generated.Running") {
                if (progress === -1) status = null;
                else {
                  const estimatedSeconds = DateUtil.diff({ dateA: endedAtEstimated, dateB: (new Date()).toISOString(), format: "seconds", tz: "America/Sao_Paulo"});
                  const estimated = DateUtil.toHHMMSS(estimatedSeconds >= 0 ? estimatedSeconds : (estimatedSeconds * -1));
                  status = t(status);
                  status = status.replace("{ESTIMATED}", `${estimated}`);
                  status = status.replace("{PERCENT}", `${progress}%`);
                }
              }
              else if (status !== null) status = t(status);

              if (status !== null) {
                addNotice({
                  refId: `report-id-${e.id}`,
                  title: t("Title.Reports"),
                  content: status
                });
              }
            }
          }
        },
        {
          key: "user",
          action: (e) => {
            if(e.active) {
              e.token = currentUser.token;
              updateCurrentUser(e);
              enableDatabaseRefresh();
              setTimeout(disableDatabaseRefresh, 1000);
            }
            else {
              setCurrentUser(false);
            }
          }
        },
        {
          key: "userNewTerminal",
          action: () => {
            setCurrentUser(false);
          }
        },
        {
          key: "userProfile",
          action: (e) => {
            if(e.active) {
              e.token = currentUser.token;
              updateCurrentUser(e);
            }
            else {
              setCurrentUser(false);
            }
          }
        },
        {
          key: "userUnauthorized",
          action: () => {
            setCurrentUser(false);
          }
        }
      ],
      mounted: databaseMounted ? true : false,
      url: databaseTest ? process.env.REACT_APP_APP_WS_TEST_URL : process.env.REACT_APP_APP_WS_URL
    });
    if(currentUser) {
      ws.connect(currentUser.token, currentToken);
    }
    setWs(ws);
  };

  useEffect(() => {
    if(ws) {
      if(databaseRefresh) {
        return ws.disconnect();
      }
      const url = databaseTest ? process.env.REACT_APP_APP_WS_TEST_URL : process.env.REACT_APP_APP_WS_URL;
      const mounted = databaseMounted ? true : false;
      if((ws.getUrl() !== url) || (ws.getMounted() !== mounted)) {
        ws.setMounted(mounted);
        ws.setUrl(url);
        ws.disconnect();
        return ws.connect(currentUser.token, currentToken);
      }
      if(currentUser) {
        return ws.connect(currentUser.token, currentToken);
      }
      return ws.disconnect();
    }
  }, [currentUser, currentToken, databaseMounted, databaseRefresh, databaseTest]); /* eslint-disable-line */

  return (
    null
  );
};

const mapStateToProps = state => ({
  props: {
    currentToken: state.users.currentToken,
    currentUser: state.users.currentUser,
    databaseMounted: state.databaseTest.databaseMounted,
    databaseRefresh: state.databaseTest.databaseRefresh,
    databaseTest: state.databaseTest.databaseTest,
    lastPositionsLoad: state.lastPositions.positionsLoad
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(
    Object.assign(
      {},
      DatabaseTestActions,
      LastDateCorrectionActions,
      LastDelayedLocationActions,
      LastExporterErrorActions,
      LastNotificationActions,
      LastPatrimonyTestsActions,
      LastPositionActions,
      LoadersActions,
      NotificationsActions,
      UsersActions
    ),
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(WebSocket);
