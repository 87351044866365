import Api, { ApiV2 } from "./../Api";
import { maskCNPJ } from "./../../utils/CNPJ";
import { maskCPF } from "./../../utils/CPF";

export default class Client {
  async authorizationTerm({ data, id, token }) {
    const response = await ApiV2.post(`/client/authorization/term/${id}`, data, {
      headers: {"Authorization": "bearer " + token},
      responseType: "blob"
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async contract({ data, id, token }) {
    const response = await ApiV2.post(`/client/contract/${id}`, data, {
      headers: {"Authorization": "bearer " + token},
      responseType: "blob"
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async create({ data, token }) {
    const response = await Api.post("/client", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async findAllGrid({ data, token }) {
    const response = await Api.post("/client/find-all", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return this.findAllGridTranslate({ data: response.data });
    }
    return this.findAllGridTranslate({ data: {
      docs: [],
      limit: 1,
      page: 1,
      pages: false
    }});
  }

  findAllGridTranslate({ data }) {
    data.cols = [{
      width: "50%",
      type: "string",
      label: "Title.Name"
    }, {
      width: "25%",
      type: "string",
      label: "Title.CPFCNPJ"
    }, {
      width: "25%",
      type: "string",
      label: "Title.Active"
    }];
    data.rows = [];
    data.docs.map(item => {
      const itemAux = [{
        id: item.id,
        value: ((item.type === "LEG") ? item.legal.socialReason : item.personal.name)
      }, {
        value: ((item.type === "LEG") ? maskCNPJ(item.legal.cnpj) : maskCPF(item.personal.cpf))
      }, {
        value: this.findAllGridTranslateItem({ data: item, item: "active" }) !== undefined ? (item.active ? "%{Title.Active}%" : "%{Title.Inactive}%") : ""
      }];
      return data.rows.push(itemAux);
    });
    return data;
  }

  findAllGridTranslateItem({ data, item }) {
    try {
      var itemAux = item.split(".");
      var dataAux = data;
      itemAux.map(it => {
        return dataAux = dataAux[it];
      });
      return dataAux;
    }
    catch (e) {
      return false;
    }
  }

  async findAutocomplete({ data, token }) {
    const response = await Api.post("/client/find-autocomplete", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return response.data.docs;
    }
    return [];
  }

  async lending({ data, id, token }) {
    const response = await ApiV2.post(`/client/lending/${id}`, data, {
      headers: {"Authorization": "bearer " + token},
      responseType: "blob"
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async tradingTerm({ data, id, token }) {
    const response = await ApiV2.post(`/client/trading/term/${id}`, data, {
      headers: {"Authorization": "bearer " + token},
      responseType: "blob"
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async update({ id, data, token }) {
    const response = await Api.put(`/client/${id}`, data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }
}
