import firebase from "firebase/app";
import "firebase/messaging";

const config = {
  apiKey: "AIzaSyCS8Hijh8i6GggRFxSrg2tFPFhtk8nWwTA",
  authDomain: "visaototal.firebaseapp.com",
  databaseURL: "https://visaototal.firebaseio.com",
  projectId: "visaototal",
  storageBucket: "visaototal.appspot.com",
  messagingSenderId: "27549336142",
  appId: "1:27549336142:web:6de4f2ea58fab14f84f91f"
};

firebase.initializeApp(config);
const messaging = firebase.messaging.isSupported() ? firebase.messaging() : false;

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    if(!messaging) {
      return reject(false);
    }
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    if(!messaging) {
      return reject(false);
    }
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
