import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import InputMask from "react-input-mask";
import DatePicker, { registerLocale } from "react-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import Select from "react-select";

import { isScopes } from "./../../services/Auth";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as MenusActions } from "./../../store/ducks/menus";
import { Creators as RoutesActions } from "./../../store/ducks/routes";
import { Creators as UsersActions } from "./../../store/ducks/users";
import MenuSingleRouteItem from "./../MenuSingleRouteItem";
import PositionService from "./../../services/Position";
import DateUtil from "../../utils/Date";
import { getPump, sortPositionsPolyline } from "../../utils/Position";
import { isEmpty, getFixed, getKm } from "./../../utils/String";

registerLocale("pt-BR", ptBR);

const MenuSingleRoute = ({
  props: {
    currentUser,
    databaseTest,
    lastPositionSelected,
    singleRoute,
    singleRouteStartEnd,
    singleRouteMenu,
    singleRouteMenuSelected,
    singleRouteMenuGpsSignalSelected,
    singleRouteMenuPositionsSelected,
    singleRouteTracker
  },
  funcs: {
    addSingleRoute,
    changeSingleRouteDowntime,
    changeSingleRouteSpeed,
    closeMenuGpsSignal,
    closeMenuPositions,
    disableLoader,
    enableLoader,
    menuHandleClose,
    menuHandleOpen,
    openMenuGpsSignal,
    openMenuPositions,
    removeSingleRoute,
    selectMenuSingleRoute,
    selectMenuSingleRouteCroped,
    selectSingleRouteTracker
  }
}) => {
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const { t } = useTranslation();
  const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
  const [datePickerOpen, setDatePickerOpen] = useState(true);
  const [dateForCreated, setDateForCreated] = useState(false);
  const [routesOpen, setRoutesOpen] = useState(false);
  const [routeInfoOpen, setRouteInfoOpen] = useState(false);
  const [routeFix, setRouteFix] = useState(false);
  const [routeTimeIgOn, setRouteTimeIgOn] = useState(false);
  const [routeTimeIgOff, setRouteTimeIgOff] = useState(false);
  const [startDate, setStartDate] = useState(new Date(`${todayDate} 00:00:00`));
  const [startDateFocus, setStartDateFocus] = useState(false);
  const [endDate, setEndDate] = useState(new Date(`${todayDate} 23:55:00`));
  const [endDateFocus, setEndDateFocus] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [orderAsc, setOrderAsc] = useState(false);
  const [trackersOptions, setTrackersOptions] = useState([]);
  const [downtimeTolerance, setDowntimeTolerance] = useState("00:04:00");
  const [downtimeParameterOpen, setDowntimeParameterOpen] = useState(false);
  const [speedTolerance, setSpeedTolerance] = useState(100);
  const [speedParameterOpen, setSpeedParameterOpen] = useState(false);

  const [trackerTypesOptions] = useState([
    { value: "HAT", label: t("Title.Hatch") },
    { value: "NIP", label: t("Title.Niple") },
    { value: "TRA", label: t("Title.Tracker") },
    { value: "TRA_NIP", label: `${t("Title.Tracker")}/${t("Title.Niple")}` }
  ]);

  useEffect(() => {
    const { trackers, parameters } = lastPositionSelected.position.patrimony;
    const trackersOptionsAux = [{
      value: false,
      label: t("Title.All")
    }];
    trackers.map(tracker => {
      const labelType = trackerTypesOptions.find(x => x.value === tracker.type);
      return trackersOptionsAux.push({
        value: tracker.serial,
        label: `${typeof labelType !== "undefined" ? labelType.label : t("Title.Tracker")}: ${tracker.serial}`
      });
    });
    setTrackersOptions(trackersOptionsAux);
    if (singleRoute) {
      const pageAux = singleRoute.page;
      if (pageAux !== page) {
        setPage(pageAux);
      }
      const pagesAux = singleRoute.pages;
      if (pagesAux !== pages) {
        setPages(pagesAux);
      }
    }
    if (singleRouteStartEnd) {
      const startAux = new Date(singleRouteStartEnd.start);
      if (startAux.getTime() !== startDate.getTime()) {
        setStartDate(startAux);
      }
      const endAux = new Date(singleRouteStartEnd.end);
      if (endAux.getTime() !== endDate.getTime()) {
        setEndDate(endAux);
      }
    }
    if (singleRoute) {
      const orderAscAux = singleRoute.orderAsc;
      if (orderAscAux !== orderAsc) {
        setOrderAsc(orderAscAux);
      }
    }
    if (typeof parameters.downtimeTolerance !== "undefined") {
      setDowntimeTolerance(parameters.downtimeTolerance);
    }
    if (typeof parameters.speedTolerance !== "undefined") {
      setSpeedTolerance(parameters.speedTolerance);
    }
  }, []); /* eslint-disable-line */

  const handleSearchRoute = async (page, orderAsc) => {
    enableLoader();
    const patrimony = lastPositionSelected.position.patrimony.id;
    const response = databaseTest ? await PositionService.findRouteTest({
      dateForCreated,
      endDate,
      orderAsc,
      page,
      patrimony,
      startDate,
      token: currentUser.token
    }) : await PositionService.findRoute({
      dateForCreated,
      endDate,
      orderAsc,
      page,
      patrimony,
      startDate,
      token: currentUser.token
    });
    const { docs, page: pageReq, pages: pagesReq } = response.positions;
    setPage(pageReq);
    setPages(pagesReq);
    addSingleRoute(endDate, { orderAsc, page: pageReq, pages: pagesReq }, lastPositionSelected, docs, startDate, { downtimeTolerance, speedTolerance });
    menuHandleClose();
    disableLoader();
  };

  useEffect(() => {
    handleSearchRoute(page, orderAsc);
  }, [dateForCreated]);

  const downloadFile = (data, fileName, type) => {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(
      new Blob([data], { type })
    );
    a.setAttribute("download", fileName);
    a.setAttribute("target", "_blank");
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  };

  const handleRouteGpx = async (page, orderAsc) => {
    enableLoader();
    const patrimony = lastPositionSelected.position.patrimony.id;
    const response = await PositionService.findRouteGpx({
      endDate,
      orderAsc,
      page,
      patrimony,
      startDate,
      token: currentUser.token
    });
    if (response) {
      downloadFile(response, `Route-${(new Date()).getTime()}.gpx`, "application/gpx+xml");
    }
    disableLoader();
  };

  const getIgnitionChanges = (cropedPosition, on = true, detailed = true) => {
    const positionsSort = sortPositionsPolyline(JSON.parse(JSON.stringify(cropedPosition.positions)), true);
    const positionsSumTimeIgnitionOnOff = [];
    for (let i = 0; i < positionsSort.length; i++) {
      const posNow = positionsSort[i];
      const posNext = positionsSort[i + 1];
      if (on) {
        if (posNow.tracker.ignition) {
          const newItem = {
            startDateType: "ON",
            startDate: new Date(posNow.gps.date),
            endDateType: "OFF",
            endDate: null,
            maintenanceDateType: posNow.tracker.maintenance ? "MAI" : undefined,
            diffSeconds: 0
          };
          if (!posNext) {
            newItem.endDate = new Date(posNow.gps.date);
            newItem.endDateType = "ON";
            if (!posNow.tracker.maintenance) {
              newItem.diffSeconds = (newItem.endDate.getTime() - newItem.startDate.getTime()) / 1000;
            }
          }
          else if (posNext.tracker.ignition) {
            newItem.endDate = new Date(posNext.gps.date);
            newItem.endDateType = "ON";
            if (!posNow.tracker.maintenance) {
              newItem.diffSeconds = (newItem.endDate.getTime() - newItem.startDate.getTime()) / 1000;
            }
          }
          positionsSumTimeIgnitionOnOff.push(newItem);
        }
        else if (!posNow.tracker.ignition && positionsSumTimeIgnitionOnOff.length > 0) {
          const lastItem = positionsSumTimeIgnitionOnOff[positionsSumTimeIgnitionOnOff.length - 1];
          if (lastItem && !lastItem.endDate) {
            lastItem.endDate = new Date(posNow.gps.date);
            if (!posNow.tracker.maintenance) {
              lastItem.diffSeconds = (lastItem.endDate.getTime() - lastItem.startDate.getTime()) / 1000;
            }
          }
        }
      }
      else {
        if (!posNow.tracker.ignition) {
          const newItem = {
            startDateType: "OFF",
            startDate: new Date(posNow.gps.date),
            endDateType: "ON",
            endDate: null,
            maintenanceDateType: posNow.tracker.maintenance ? "MAI" : undefined,
            diffSeconds: 0
          };
          if (!posNext) {
            newItem.endDate = new Date(posNow.gps.date);
            newItem.endDateType = "OFF";
            if (!posNow.tracker.maintenance) {
              newItem.diffSeconds = (newItem.endDate.getTime() - newItem.startDate.getTime()) / 1000;
            }
          }
          else if (!posNext.tracker.ignition) {
            newItem.endDate = new Date(posNext.gps.date);
            newItem.endDateType = "OFF";
            if (!posNow.tracker.maintenance) {
              newItem.diffSeconds = (newItem.endDate.getTime() - newItem.startDate.getTime()) / 1000;
            }
          }
          positionsSumTimeIgnitionOnOff.push(newItem);
        } else if (posNow.tracker.ignition && positionsSumTimeIgnitionOnOff.length > 0) {
          const lastItem = positionsSumTimeIgnitionOnOff[positionsSumTimeIgnitionOnOff.length - 1];
          if (lastItem && !lastItem.endDate) {
            lastItem.endDate = new Date(posNow.gps.date);
            if (!posNow.tracker.maintenance) {
              lastItem.diffSeconds = (lastItem.endDate.getTime() - lastItem.startDate.getTime()) / 1000;
            }
          }
        }
      }
    }
    if (detailed) {
      return {
        positions: positionsSumTimeIgnitionOnOff,
        total: Number(getFixed(positionsSumTimeIgnitionOnOff.reduce((accumulator, currentValue) => accumulator + currentValue.diffSeconds, 0) / (60 * 60), 0))
      };
    }
    const positionsSumTimeIgnitionOnOffOnlyChange = [];
    for (let j = 0; j < positionsSumTimeIgnitionOnOff.length; j++) {
      const posNow = positionsSumTimeIgnitionOnOff[j];
      if (on) {
        if (posNow.startDateType === "ON" && posNow.endDateType === "ON") {
          if (positionsSumTimeIgnitionOnOffOnlyChange.length === 0 || (positionsSumTimeIgnitionOnOffOnlyChange.length > 0 && positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].endDateType === "OFF")) {
            positionsSumTimeIgnitionOnOffOnlyChange.push(posNow);
          }
          else {
            if (positionsSumTimeIgnitionOnOffOnlyChange.length > 0) {
              const diffSeconds = positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].diffSeconds;
              positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].diffSeconds = diffSeconds + posNow.diffSeconds;
              positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].endDate = posNow.endDate;
              positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].endDateType = "ON";
            }
          }
        }
        else if (posNow.startDateType === "ON" && posNow.endDateType === "OFF") {
          if (positionsSumTimeIgnitionOnOffOnlyChange.length > 0 && positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].endDateType === "ON") {
            const diffSeconds = positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].diffSeconds;
            positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].diffSeconds = diffSeconds + posNow.diffSeconds;
            positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].endDate = posNow.endDate;
            positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].endDateType = "OFF";
          }
          else positionsSumTimeIgnitionOnOffOnlyChange.push(posNow);
        }
      }
      else {
        if (posNow.startDateType === "OFF" && posNow.endDateType === "OFF") {
          if (positionsSumTimeIgnitionOnOffOnlyChange.length === 0 || (positionsSumTimeIgnitionOnOffOnlyChange.length > 0 && positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].endDateType === "ON")) {
            positionsSumTimeIgnitionOnOffOnlyChange.push(posNow);
          }
          else {
            if (positionsSumTimeIgnitionOnOffOnlyChange.length > 0) {
              const diffSeconds = positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].diffSeconds;
              positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].diffSeconds = diffSeconds + posNow.diffSeconds;
              positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].endDate = posNow.endDate;
              positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].endDateType = "OFF";
            }
          }
        }
        else if (posNow.startDateType === "OFF" && posNow.endDateType === "ON") {
          if (positionsSumTimeIgnitionOnOffOnlyChange.length > 0 && positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].endDateType === "OFF") {
            const diffSeconds = positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].diffSeconds;
            positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].diffSeconds = diffSeconds + posNow.diffSeconds;
            positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].endDate = posNow.endDate;
            positionsSumTimeIgnitionOnOffOnlyChange[positionsSumTimeIgnitionOnOffOnlyChange.length-1].endDateType = "ON";
          }
          else positionsSumTimeIgnitionOnOffOnlyChange.push(posNow);
        }
      }
    }
    return {
      positions: positionsSumTimeIgnitionOnOffOnlyChange,
      total: Number(getFixed(positionsSumTimeIgnitionOnOffOnlyChange.reduce((accumulator, currentValue) => accumulator + currentValue.diffSeconds, 0) / (60 * 60), 0))
    };
  };

  const getCountIgnitions = (cropedPosition, on = true) => {
    const positionsSort = sortPositionsPolyline(JSON.parse(JSON.stringify(cropedPosition.positions)), true);
    const positionsOnOff = [];
    for (let i = 0; i < positionsSort.length; i++) {
      if (i > 0) {
        const posNow = positionsSort[i];
        const posBack = positionsSort[i - 1];
        if (on) {
          if (posNow.tracker.ignition && !posBack.tracker.ignition) {
            positionsOnOff.push(posNow);
          }
        }
        else {
          if (!posNow.tracker.ignition && posBack.tracker.ignition) {
            positionsOnOff.push(posNow);
          }
        }
      }
    }
    return positionsOnOff.length;
  };

  const getCountPositions = (cropedPosition) => {
    return cropedPosition.positions.length;
  };

  const getFirstIgnition = (cropedPosition) => {
    const ignitions = cropedPosition.positions.filter(x => x.tracker.ignition === true);
    if (ignitions.length > 0) return DateUtil.to({ date: new Date(ignitions[0].gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"});
    return "--";
  };

  const getFirstMoving = (cropedPosition) => {
    const movings = cropedPosition.positions.filter(x => x.tracker.ignition === true && x.gps.speed > 0);
    if (movings.length > 0) return DateUtil.to({ date: new Date(movings[0].gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"});
    return "--";
  };

  const getFirstPosition = (cropedPosition) => {
    const positions = cropedPosition.positions;
    if (positions.length > 0) return DateUtil.to({ date: new Date(positions[0].gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"});
    return "--";
  };

  const getLastIgnition = (cropedPosition) => {
    const ignitions = cropedPosition.positions.filter(x => x.tracker.ignition === true);
    if (ignitions.length > 0) return DateUtil.to({ date: new Date(ignitions[ignitions.length-1].gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"});
    return "--";
  };

  const getLastMoving = (cropedPosition) => {
    const movings = cropedPosition.positions.filter(x => x.tracker.ignition === true && x.gps.speed > 0);
    if (movings.length > 0) return DateUtil.to({ date: new Date(movings[movings.length-1].gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"});
    return "--";
  };

  const getLastPosition = (cropedPosition) => {
    const positions = cropedPosition.positions;
    if (positions.length > 0) return DateUtil.to({ date: new Date(positions[positions.length-1].gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"});
    return "--";
  };

  const dynamicOptions = [
    {
      id: "msr-option-route",
      title: t("Title.Route"),
      icon: <i className="fas fa-route" />,
      getCount: () => singleRoute.route.length,
      onClick: () => selectMenuSingleRoute("route"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "route",
      scoped: () => true
    },
    {
      id: "msr-option-course",
      title: t("Title.Course"),
      icon: <i className="far fa-compass" />,
      getCount: () => singleRouteMenu.course.length,
      onClick: () => selectMenuSingleRoute("course"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "course",
      scoped: () => true
    },
    {
      id: "msr-option-ignition-on",
      title: t("Title.Ignition.On"),
      icon: <i className="fas fa-key" style={{ color: "#087d06" }} />,
      getCount: () => singleRouteMenu.ignitionOn.length,
      onClick: () => selectMenuSingleRoute("ignitionOn"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "ignitionOn",
      scoped: () => true
    },
    {
      id: "msr-option-ignition-off",
      title: t("Title.Ignition.Off"),
      icon: <i className="fas fa-key" style={{ color: "#ff0000" }} />,
      getCount: () => singleRouteMenu.ignitionOff.length,
      onClick: () => selectMenuSingleRoute("ignitionOff"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "ignitionOff",
      scoped: () => true
    },
    {
      id: "msr-option-stop",
      title: t("Title.Downtime"),
      icon: <i className="fas fa-hourglass-half" />,
      parameter: {
        icon: <i className="fa fa-edit" />,
        onClick: () => toggleDowntimeParameterOpen(),
        selected: () => downtimeParameterOpen
      },
      getCount: () => singleRouteMenu.stop.length,
      onClick: () => selectMenuSingleRoute("stop"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "stop",
      scoped: () => true
    },
    {
      id: "msr-option-stop-rework",
      title: `${t("Title.Downtime")} - Nova versão`,
      icon: <i className="fas fa-hourglass-half" />,
      parameter: {
        icon: <i className="fa fa-edit" />,
        onClick: () => toggleDowntimeParameterOpen(),
        selected: () => downtimeParameterOpen
      },
      getCount: () => singleRouteMenu.stopRework.length,
      onClick: () => selectMenuSingleRoute("stopRework"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "stopRework",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      id: "msr-option-speed",
      title: t("Title.Speeding"),
      icon: <i className="fas fa-tachometer-alt" />,
      parameter: {
        icon: <i className="fa fa-edit" />,
        onClick: () => toggleSpeedParameterOpen(),
        selected: () => speedParameterOpen
      },
      getCount: () => singleRouteMenu.speed.length,
      onClick: () => selectMenuSingleRoute("speed"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "speed",
      scoped: () => true
    },
    {
      id: "msr-option-anchor",
      title: t("Title.Anchor"),
      icon: <i className="fas fa-anchor" />,
      getCount: () => singleRouteMenu.anchor.length,
      onClick: () => selectMenuSingleRoute("anchor"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "anchor",
      scoped: () => true
    },
    {
      id: "msr-option-block",
      title: t("Title.Block"),
      icon: <i className="fas fa-lock" />,
      getCount: () => singleRouteMenu.block.length,
      onClick: () => selectMenuSingleRoute("block"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "block",
      scoped: () => true
    },
    {
      id: "msr-option-pump",
      title: t("Title.Pump"),
      icon: <i className="fas fa-tint" />,
      getCount: () => singleRouteMenu.pump.length,
      onClick: () => selectMenuSingleRoute("pump"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "pump",
      scoped: () => getPump(lastPositionSelected.position)
    },
    {
      id: "msr-option-battery",
      title: t("Title.Battery"),
      icon: <i className="fas fa-battery-empty" />,
      getCount: () => singleRouteMenu.battery.length,
      onClick: () => selectMenuSingleRoute("battery"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "battery",
      scoped: () => true
    },
    {
      id: "msr-option-panic",
      title: t("Title.Panic"),
      icon: <i className="fas fa-skull-crossbones" />,
      getCount: () => singleRouteMenu.panic.length,
      onClick: () => selectMenuSingleRoute("panic"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "panic",
      scoped: () => true
    },
    {
      id: "msr-option-jammer",
      title: t("Title.Jammer"),
      icon: <i className="fas fa-broadcast-tower" />,
      getCount: () => singleRouteMenu.jammer.length,
      onClick: () => selectMenuSingleRoute("jammer"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "jammer",
      scoped: () => true
    },
    {
      id: "msr-option-maintenance",
      title: t("Title.Niple.Maintenance"),
      icon: <span>M</span>,
      getCount: () => singleRouteMenu.maintenance.length,
      onClick: () => selectMenuSingleRoute("maintenance"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "maintenance",
      scoped: () => true
    },
    {
      id: "msr-option-esp-restarted",
      title: t("Title.Reason.ESPRestarted"),
      icon: <span>R</span>,
      getCount: () => singleRouteMenu.espRestarted.length,
      onClick: () => selectMenuSingleRoute("espRestarted"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "espRestarted",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      id: "msr-option-test-tpw",
      title: t("Title.Niple.Test"),
      icon: <span style={{ color: "#087d06" }}>T</span>,
      getCount: () => singleRouteMenu.nipleTestTpw.length,
      onClick: () => selectMenuSingleRoute("nipleTestTpw"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "nipleTestTpw",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      id: "msr-option-test-tpe",
      title: t("Title.Niple.Test"),
      icon: <span style={{ color: "#ff0000" }}>T</span>,
      getCount: () => singleRouteMenu.nipleTestTpe.length,
      onClick: () => selectMenuSingleRoute("nipleTestTpe"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "nipleTestTpe",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      id: "msr-option-niple-fraud",
      title: t("Title.Niple.Fraud"),
      icon: <span>B</span>,
      getCount: () => singleRouteMenu.nipleFraud.length,
      onClick: () => selectMenuSingleRoute("nipleFraud"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "nipleFraud",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      id: "msr-option-niple-closed",
      title: t("Title.Niple.Closed"),
      icon: <span>F</span>,
      getCount: () => singleRouteMenu.nipleClosed.length,
      onClick: () => selectMenuSingleRoute("nipleClosed"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "nipleClosed",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      id: "msr-option-niple-closed-basic",
      title: t("Title.Niple.Closed"),
      icon: <span>F</span>,
      getCount: () => singleRouteMenu.nipleClosedBasic.length,
      onClick: () => selectMenuSingleRoute("nipleClosedBasic"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "nipleClosedBasic",
      scoped: () => isScopes({ currentUser, scopes: ["read:patrimonies:basic"], every: false })
    },
    {
      id: "msr-option-niple-discharge",
      title: t("Title.Niple.Discharge"),
      icon: <span>D</span>,
      getCount: () => singleRouteMenu.nipleDischarge.length,
      onClick: () => selectMenuSingleRoute("nipleDischarge"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "nipleDischarge",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      id: "msr-option-niple-discharge-test",
      title: t("Title.Niple.DischargeTest"),
      icon: <span style={{ color: "#999999" }}>D</span>,
      getCount: () => singleRouteMenu.nipleDischargeTest.length,
      onClick: () => selectMenuSingleRoute("nipleDischargeTest"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "nipleDischargeTest",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      id: "msr-option-niple-discharge-basic",
      title: t("Title.Niple.Discharge"),
      icon: <span>D</span>,
      getCount: () => singleRouteMenu.nipleDischargeBasic.length,
      onClick: () => selectMenuSingleRoute("nipleDischargeBasic"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "nipleDischargeBasic",
      scoped: () => isScopes({ currentUser, scopes: ["read:patrimonies:basic"], every: false })
    },
    {
      id: "msr-option-niple-charge",
      title: t("Title.Niple.Charge"),
      icon: <span>C</span>,
      getCount: () => singleRouteMenu.nipleCharge.length,
      onClick: () => selectMenuSingleRoute("nipleCharge"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "nipleCharge",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      id: "msr-option-niple-charge-test",
      title: t("Title.Niple.ChargeTest"),
      icon: <span style={{ color: "#999999" }}>C</span>,
      getCount: () => singleRouteMenu.nipleChargeTest.length,
      onClick: () => selectMenuSingleRoute("nipleChargeTest"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "nipleChargeTest",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      id: "msr-option-niple-charge-basic",
      title: t("Title.Niple.Charge"),
      icon: <span>C</span>,
      getCount: () => singleRouteMenu.nipleChargeBasic.length,
      onClick: () => selectMenuSingleRoute("nipleChargeBasic"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "nipleChargeBasic",
      scoped: () => isScopes({ currentUser, scopes: ["read:patrimonies:basic"], every: false })
    },
    {
      id: "msr-option-hatch-open",
      title: t("Title.Hatch.HatchOpen"),
      icon: <span>A</span>,
      getCount: () => singleRouteMenu.hatchOpen.length,
      onClick: () => selectMenuSingleRoute("hatchOpen"),
      selected: () => singleRouteMenuSelected && singleRouteMenuSelected.id === "hatchOpen",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    }
  ];

  const fixedOptions = [
    {
      id: "msr-option-close",
      title: t("Title.Close"),
      icon: <i className="fas fa-times" />,
      onClick: () => {
        removeSingleRoute();
        menuHandleOpen();
      },
      selected: () => true,
      scoped: () => true
    },
    {
      id: "msr-option-date",
      title: t("Title.Calendar"),
      icon: <i className="far fa-calendar-alt" />,
      onClick: () => setDatePickerOpen(state => !state),
      selected: () => datePickerOpen,
      scoped: () => true
    },
    {
      id: "msr-option-created",
      title: t("Title.Calendar"),
      icon: <i className="fa-solid fa-calendar-day" />,
      onClick: () => setDateForCreated(state => !state),
      selected: () => dateForCreated,
      scoped: () => false //isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      id: "msr-option-positions",
      title: t("Title.Positions"),
      icon: <i className="fas fa-clipboard-list" />,
      onClick: () => {
        if (singleRouteMenuPositionsSelected) return closeMenuPositions();
        return openMenuPositions();
      },
      selected: () => singleRouteMenuPositionsSelected,
      scoped: () => true
    },
    {
      id: "msr-option-valid",
      title: t("Title.Gps.Valid"),
      icon: <i className="fas fa-globe" />,
      onClick: () => {
        if (singleRouteMenuGpsSignalSelected) return closeMenuGpsSignal();
        return openMenuGpsSignal();
      },
      selected: () => singleRouteMenuGpsSignalSelected,
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      id: "msr-option-routes",
      title: t("Title.Routes"),
      icon: <i className="fa-solid fa-map" />,
      getCount: () => singleRoute ? singleRoute.cropedPositions ? singleRoute.cropedPositions.length : 0 : 0,
      onClick: () => toggleRoutesOpen(),
      selected: () => routesOpen,
      scoped: () => true
    },
    {
      id: "msr-option-route-info",
      title: t("Title.Route.Info"),
      icon: <i className="fa-solid fa-info" />,
      onClick: () => toggleRouteInfoOpen(),
      selected: () => routeInfoOpen,
      scoped: () => true
    },
    // {
    //   id: "msr-option-route-fix",
    //   title: t("Title.Route.Fix"),
    //   icon: <i className="fa-solid fa-bug-slash" />,
    //   onClick: () => toggleRouteFix(),
    //   selected: () => routeFix,
    //   scoped: () => isScopes({ currentUser, scopes: ["is:master"], every: false })
    // }
  ];

  const toggleRoutesOpen = () => {
    setDowntimeParameterOpen(false);
    setRouteInfoOpen(false);
    setRouteFix(false);
    setRouteTimeIgOn(false);
    setRouteTimeIgOff(false);
    setRoutesOpen(state => !state);
    setSpeedParameterOpen(false);
  };

  const toggleRouteInfoOpen = () => {
    setDowntimeParameterOpen(false);
    setRoutesOpen(false);
    setRouteInfoOpen(state => !state);
    setRouteFix(false);
    setRouteTimeIgOn(false);
    setRouteTimeIgOff(false);
    setSpeedParameterOpen(false);
  };

  const toggleRouteFix = () => {
    setDowntimeParameterOpen(false);
    setRoutesOpen(false);
    setRouteInfoOpen(false);
    setRouteFix(state => !state);
    setRouteTimeIgOn(false);
    setRouteTimeIgOff(false);
    setSpeedParameterOpen(false);
  };

  const toggleDowntimeParameterOpen = () => {
    setDowntimeParameterOpen(state => !state);
    setRoutesOpen(false);
    setRouteInfoOpen(false);
    setRouteFix(false);
    setRouteTimeIgOn(false);
    setRouteTimeIgOff(false);
    setSpeedParameterOpen(false);
  };

  const toggleSpeedParameterOpen = () => {
    setDowntimeParameterOpen(false);
    setRoutesOpen(false);
    setRouteInfoOpen(false);
    setRouteFix(false);
    setRouteTimeIgOn(false);
    setRouteTimeIgOff(false);
    setSpeedParameterOpen(state => !state);
  };

  return (
    <div className="menu-single-route">
      <div className={`msr-route-options-absolute ${datePickerOpen ? "active" : ""}`}>
        <div className="msr-rpa-fields">
          <form onSubmit={e => e.preventDefault()}>
            <Form.Group>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  selected={startDate}
                  onCalendarOpen={() => setStartDateFocus(true)}
                  onCalendarClose={() => setStartDateFocus(false)}
                  onChange={date => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  minDate={new Date(`${process.env.REACT_APP_APP_START_YEAR}-01-02`)}
                  maxDate={new Date()}
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  showTimeSelect
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd/MM/yyyy HH:mm:ss"
                  className="react-datepicker-form-control"
                  popperPlacement="top-center"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px"
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport"
                    }
                  }}
                  locale="pt-BR"
                  ref={startDateRef}
                />
                {
                  (startDateFocus && (
                    <div className="react-datepicker-close-button">
                      <button type="button" onClick={() => {
                        if (startDateRef.current !== null && typeof startDateRef.current.setOpen !== "undefined") {
                          startDateRef.current.setOpen(false);
                        }
                      }}><i className="fa fa-times" /></button>
                    </div>
                  ))
                }
                <div className="react-datepicker-form-control-shaddow" />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  selected={endDate}
                  onCalendarOpen={() => setEndDateFocus(true)}
                  onCalendarClose={() => setEndDateFocus(false)}
                  onChange={date => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  maxDate={new Date()}
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  showTimeSelect
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd/MM/yyyy HH:mm:ss"
                  className="react-datepicker-form-control"
                  popperPlacement="top-center"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px"
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport"
                    }
                  }}
                  locale="pt-BR"
                  ref={endDateRef}
                />
                {
                  (endDateFocus && (
                    <div className="react-datepicker-close-button">
                      <button type="button" onClick={() => {
                        if (endDateRef.current !== null && typeof endDateRef.current.setOpen !== "undefined") {
                          endDateRef.current.setOpen(false);
                        }
                      }}><i className="fa fa-times" /></button>
                    </div>
                  ))
                }
                <div className="react-datepicker-form-control-shaddow" />
              </InputGroup>
            </Form.Group>
          </form>
        </div>
        <div className="msr-rpa-close">
          <button type="button" onClick={e => setDatePickerOpen(!datePickerOpen)}><i className="far fa-times-circle" /></button>
        </div>
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && <div className="msr-rpa-options">
            <Form.Group>
              <Select
                className="menu-outer-bottom"
                classNamePrefix="select"
                isDisabled={false}
                onChange={e => selectSingleRouteTracker(e !== null ? e.value : false)}
                options={trackersOptions}
                value={trackersOptions.find(x => x.value === singleRouteTracker)}
              />
            </Form.Group>
          </div>
        }
        <div className="msr-rpa-search">
          <button type="button" onClick={() => handleSearchRoute(page, orderAsc)}><i className="fas fa-search" /></button>
          {
            !databaseTest && !dateForCreated && isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
              <button className="gpx-download" type="button" onClick={() => handleRouteGpx(page, orderAsc)}><i className="fa-solid fa-map-location-dot" /></button>
            ) : null
          }
        </div>
        <div className="msr-rpa-pagination">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Back")}</Tooltip>}
            trigger={isMobile || (page <= 1) ? null : ["hover", "focus"]}
          >
            <button type="button"
              disabled={page <= 1}
              onClick={() => {
                if (page > 1) {
                  const newPage = page - 1;
                  setPage(newPage);
                  handleSearchRoute(newPage, orderAsc);
                }
              }}
            >
              <i className="fas fa-chevron-left" />
            </button>
          </OverlayTrigger>
          <p>{t("Title.Page")} {singleRoute.page} {t("Title.Of")} {singleRoute.pages}</p>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{!orderAsc ? t("Title.Sort.TimeAsc") : t("Title.Sort.TimeDesc")}</Tooltip>}
            trigger={isMobile ? null : ["hover", "focus"]}
          >
            <button type="button"
              onClick={e => {
                setOrderAsc(!orderAsc);
                handleSearchRoute(page, !orderAsc);
              }}
            >
              {
                (!orderAsc && (
                  <i className="fas fa-hourglass-start" />
                ))
              }
              {
                (orderAsc && (
                  <i className="fas fa-hourglass-end" />
                ))
              }
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Next")}</Tooltip>}
            trigger={isMobile || (page >= pages) ? null : ["hover", "focus"]}
          >
            <button type="button"
              disabled={page >= pages}
              onClick={e => {
                if (page < pages) {
                  const newPage = page + 1;
                  setPage(newPage);
                  handleSearchRoute(newPage, orderAsc);
                }
              }}
            >
              <i className="fas fa-chevron-right" />
            </button>
          </OverlayTrigger>
        </div>
      </div>
      <div className="msr-route-options-fixed">
        <ul>
          {
            fixedOptions.map(option => {
              if(option.scoped()) {
                return (
                  <MenuSingleRouteItem key={option.id} {...option} onClick={option.onClick} />
                );
              }
              return null;
            })
          }
        </ul>
      </div>
      <div className="msr-route-options-dynamic">
        <ul>
          {
            dynamicOptions.map(option => {
              if(option.scoped()) {
                return (
                  <MenuSingleRouteItem key={option.id} {...option} onClick={option.onClick} />
                );
              }
              return null;
            })
          }
        </ul>
      </div>
      {
        (routesOpen && (
          <div className="msr-route-route-parameter">
            <div className="msr-route-route-parameter-close">
              <button type="button" onClick={() => toggleRoutesOpen()}><i className="fa fa-times" /></button>
            </div>
            <Form.Group controlId="msr-route-route-parameter-tolerance">
              <Form.Label>{t("Label.Routes")}:</Form.Label>
              <Select
                className="menu-outer-top"
                classNamePrefix="select"
                isDisabled={false}
                onChange={e => selectMenuSingleRouteCroped(e ? e.value : null)}
                options={singleRoute.cropedPositions.map((cropedPosition) => ({
                  value: cropedPosition.id,
                  label: `${t("Title.Route")} ${cropedPosition.id + 1}: ${cropedPosition.id === 0 ? t("Title.All") : t(cropedPosition.ignition ? "Title.Ignition.On" : "Title.Ignition.Off")}`
                }))}
                value={singleRoute.cropedPosition ? {
                  value: singleRoute.cropedPosition.id,
                  label: `${t("Title.Route")} ${singleRoute.cropedPosition.id + 1}: ${singleRoute.cropedPosition.id === 0 ? t("Title.All") : t(singleRoute.cropedPosition.ignition ? "Title.Ignition.On" : "Title.Ignition.Off")}`
                } : null}
                loadingMessage={() => t("React.Select.Wait")}
                noOptionsMessage={() => t("React.Select.NoOptions")}
                placeholder={t("Title.Routes.Select")}
              />
            </Form.Group>
          </div>
        ))
      }
      {
        (routeInfoOpen && (
          <div className="msr-route-info-parameter">
            <div className="msr-route-info-parameter-close">
              <button type="button" onClick={() => toggleRouteInfoOpen()}><i className="fa fa-times" /></button>
            </div>
            <div className="msr-route-info-content">
              {
                (singleRoute.cropedPosition && (
                  <ul>
                    <li>
                      <p><i className="fas fa-route" /> Informações de rota</p>
                      <ul>
                        <li><strong>Primeiro</strong> {getFirstPosition(singleRoute.cropedPosition)}</li>
                        <li><strong>Último</strong> {getLastPosition(singleRoute.cropedPosition)}</li>
                        <li><strong>Informações</strong> {getCountPositions(singleRoute.cropedPosition)}</li>
                        <li><strong>Ignições</strong> {getCountIgnitions(singleRoute.cropedPosition, true)}</li>
                      </ul>
                    </li>
                    <li>
                      <p><i className="fas fa-route" /> Informações de ignição</p>
                      <ul>
                        <li><strong>Primeira</strong> {getFirstIgnition(singleRoute.cropedPosition)}</li>
                        <li><strong>Última</strong> {getLastIgnition(singleRoute.cropedPosition)}</li>
                        <li className="ul-show-hide-info">
                          <strong>Tempo lig.</strong> {getIgnitionChanges(singleRoute.cropedPosition, true, false).total}h <i className={`fa-solid fa-info ul-show-hide-toggle ${routeTimeIgOn ? "active" : ""}`} onClick={() => setRouteTimeIgOn(prevState => !prevState)} />
                          {
                            (routeTimeIgOn && (
                              <ul className="ul-show-hide">
                                {
                                  getIgnitionChanges(singleRoute.cropedPosition, true, false).positions.map((position, index) => {
                                    return (
                                      <li key={`ignition-changes-on-${index}`}>
                                        {(index + 1)}: {DateUtil.to({ date: position.startDate, format: "DD/MM/YY HH:mm:ss", tz: "America/Sao_Paulo"})} ({position.startDateType === "ON" ? <i className="fas fa-key" style={{ color: "#087d06" }} /> : <i className="fas fa-key" style={{ color: "#ff0000" }} />})
                                        - {DateUtil.to({ date: position.endDate, format: "DD/MM/YY HH:mm:ss", tz: "America/Sao_Paulo"})} ({position.endDateType === "ON" ? <i className="fas fa-key" style={{ color: "#087d06" }} /> : <i className="fas fa-key" style={{ color: "#ff0000" }} />})
                                        = {position.diffSeconds >= 3600 ? `${Number(getFixed(position.diffSeconds / (60 * 60), 0))}h` : null} {position.diffSeconds >= 60 && position.diffSeconds < 3600 ? `${Number(getFixed(position.diffSeconds / (60), 0))}m` : null} {position.diffSeconds < 60 ? `${position.diffSeconds}s` : null}
                                      </li>
                                    );
                                  })
                                }
                              </ul>
                            ))
                          }
                        </li>
                        <li className="ul-show-hide-info">
                          <strong>Tempo des.</strong> {getIgnitionChanges(singleRoute.cropedPosition, false, false).total}h <i className={`fa-solid fa-info ul-show-hide-toggle ${routeTimeIgOff ? "active" : ""}`} onClick={() => setRouteTimeIgOff(prevState => !prevState)} />
                          {
                            (routeTimeIgOff && (
                              <ul className="ul-show-hide">
                                {
                                  getIgnitionChanges(singleRoute.cropedPosition, false, false).positions.map((position, index) => {
                                    return (
                                      <li key={`ignition-changes-off-${index}`}>
                                        {(index + 1)}: {DateUtil.to({ date: position.startDate, format: "DD/MM/YY HH:mm:ss", tz: "America/Sao_Paulo"})} ({position.startDateType === "ON" ? <i className="fas fa-key" style={{ color: "#087d06" }} /> : <i className="fas fa-key" style={{ color: "#ff0000" }} />})
                                        - {DateUtil.to({ date: position.endDate, format: "DD/MM/YY HH:mm:ss", tz: "America/Sao_Paulo"})} ({position.endDateType === "ON" ? <i className="fas fa-key" style={{ color: "#087d06" }} /> : <i className="fas fa-key" style={{ color: "#ff0000" }} />})
                                        = {position.diffSeconds >= 3600 ? `${Number(getFixed(position.diffSeconds / (60 * 60), 0))}h` : null} {position.diffSeconds >= 60 && position.diffSeconds < 3600 ? `${Number(getFixed(position.diffSeconds / (60), 0))}m` : null} {position.diffSeconds < 60 ? `${position.diffSeconds}s` : null}
                                      </li>
                                    );
                                  })
                                }
                              </ul>
                            ))
                          }
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p><i className="fas fa-route" /> Informações de deslocamento</p>
                      <ul>
                        <li><strong>Primeiro</strong> {getFirstMoving(singleRoute.cropedPosition)}</li>
                        <li><strong>Último</strong> {getLastMoving(singleRoute.cropedPosition)}</li>
                        <li><strong>Distância</strong> {getKm(singleRoute.cropedPosition.km, 2)} Km</li>
                        <li><strong>Vel. máxima</strong> {parseInt(singleRoute.cropedPosition.maxSpeed)} Km/h</li>
                        <li><strong>Vel. média</strong> {parseInt(singleRoute.cropedPosition.medianSpeed)} Km/h</li>
                        <li><strong>Vel. mínima</strong> {parseInt(singleRoute.cropedPosition.minSpeed)} Km/h</li>
                      </ul>
                    </li>
                  </ul>
                ))
              }
              {
                (!singleRoute.cropedPosition && (
                  <ul>
                    <li>Nenhum resultado encontrado.</li>
                  </ul>
                ))
              }
            </div>
          </div>
        ))
      }
      {
        (singleRouteMenuSelected && (singleRouteMenuSelected.id === "stop" || singleRouteMenuSelected.id === "stopRework") && downtimeParameterOpen && (
          <div className="msr-route-downtime-parameter">
            <div className="msr-route-downtime-parameter-close">
              <button type="button" onClick={() => toggleDowntimeParameterOpen()}><i className="fa fa-times" /></button>
            </div>
            <Form.Group controlId="msr-route-downtime-parameter-tolerance">
              <Form.Label>{t("Label.DowntimeTolerance")}:</Form.Label>
              <InputMask
                id="msr-route-downtime-parameter-tolerance"
                className={`form-control ${(!isEmpty(downtimeTolerance) && DateUtil.isTime(downtimeTolerance)) ? "is-valid" : "is-invalid"}`}
                type="text"
                placeholder={t("Placeholder.DowntimeTolerance")}
                onChange={e => {
                  const downtimeTolerance = e.target.value;
                  setDowntimeTolerance(downtimeTolerance);
                  if (!isEmpty(downtimeTolerance) && DateUtil.isTime(downtimeTolerance)) {
                    changeSingleRouteDowntime(downtimeTolerance);
                  }
                }}
                mask="99:99:99"
                autoComplete="off"
                defaultValue={downtimeTolerance}
              />
            </Form.Group>
          </div>
        ))
      }
      {
        (singleRouteMenuSelected && singleRouteMenuSelected.id === "speed" && speedParameterOpen && (
          <div className="msr-route-speed-parameter">
            <div className="msr-route-speed-parameter-close">
              <button type="button" onClick={() => toggleSpeedParameterOpen()}><i className="fa fa-times" /></button>
            </div>
            <Form.Group controlId="msr-route-speed-parameter-tolerance">
              <Form.Label>{t("Label.SpeedTolerance")}:</Form.Label>
              <InputMask
                id="msr-route-speed-parameter-tolerance"
                className={`form-control ${(!isEmpty(speedTolerance) && !isNaN(Number(speedTolerance))) ? "is-valid" : "is-invalid"}`}
                type="number"
                placeholder={t("Placeholder.SpeedTolerance")}
                onChange={e => {
                  const speedTolerance = Number(e.target.value);
                  setSpeedTolerance(speedTolerance);
                  if (!isEmpty(speedTolerance) && !isNaN(Number(speedTolerance))) {
                    changeSingleRouteSpeed(speedTolerance);
                  }
                }}
                autoComplete="off"
                defaultValue={speedTolerance}
              />
            </Form.Group>
          </div>
        ))
      }
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    databaseTest: state.databaseTest.databaseTest,
    lastPositionSelected: state.lastPositions.positionSelected,
    singleRoute: state.routes.singleRoute,
    singleRouteStartEnd: state.routes.singleRouteStartEnd,
    singleRouteMenu: state.routes.singleRouteMenu,
    singleRouteMenuSelected: state.routes.singleRouteMenuSelected,
    singleRouteMenuGpsSignalSelected: state.routes.singleRouteMenuGpsSignalSelected,
    singleRouteMenuPositionsSelected: state.routes.singleRouteMenuPositionsSelected,
    singleRouteTracker: state.routes.singleRouteTracker
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, MenusActions, RoutesActions, UsersActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuSingleRoute);
