import React, { memo, useEffect, useState } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as MenusActions } from "./../../store/ducks/menus";

import { isScopes } from "./../../services/Auth";
import Grid from "./../Grid";
import MenuRegistersItem from "./../MenuRegistersItem";

import { isAutomobileBoardAutoVaccum } from "./../../utils/Patrimony";

import ClientAuthorizationTerm from "./../../forms/Client/AuthorizationTerm";
import ClientContract from "./../../forms/Client/Contract";
import ClientLending from "./../../forms/Client/Lending";
import ClientTradingTerm from "./../../forms/Client/TradingTerm";

import PatrimonyImaCertificate from "./../../forms/Patrimony/ImaCertificate";
import PatrimonyInstallationStatement from "./../../forms/Patrimony/InstallationStatement";
import PatrimonyMaintenanceSchedulingTerm from "../../forms/Patrimony/MaintenanceSchedulingTerm";

import ChassiForm from "./../../forms/Chassi";
import ChipForm from "./../../forms/Chip";
import ClientForm from "./../../forms/Client";
import ContractForm from "./../../forms/Contract";
import DriverForm from "./../../forms/Driver";
import PatrimonyForm from "./../../forms/Patrimony";
import PatrimonyTestForm from "./../../forms/PatrimonyTest";
import ProfileForm from "./../../forms/Profile";
import RfidForm from "./../../forms/Rfid";
import SupportPointForm from "./../../forms/SupportPoint";
import TrackerForm from "./../../forms/Tracker";
import UserForm from "./../../forms/User";
import VirtualFenceForm from "./../../forms/VirtualFence";
import WorkingWeekForm from "./../../forms/WorkingWeek";

import ChassiService from "./../../services/Chassi";
import ChipService from "./../../services/Chip";
import ClientService from "./../../services/Client";
import ContractService from "./../../services/Contract";
import DriverService from "./../../services/Driver";
import PatrimonyService from "./../../services/Patrimony";
import PatrimonyTestService from "./../../services/PatrimonyTest";
import ProfileService from "./../../services/Profile";
import RfidService from "./../../services/Rfid";
import SupportPointService from "./../../services/SupportPoint";
import TrackerService from "./../../services/Tracker";
import UserService from "./../../services/User";
import VirtualFenceService from "./../../services/VirtualFence";
import WorkingWeekService from "./../../services/WorkingWeek";

const MenuRegisters = ({
  props: { currentUser },
  funcs: { menuHandleOpen, menuRegistersHandleClose }
}) => {
  const { t } = useTranslation();
  const [selectedRegister, setSelectedRegister] = useState({
    selected: false,
    options: false
  });

  useEffect(() => {
    if(selectedRegister.selected) {
      if(selectedRegister.options.scoped(currentUser) === false) {
        changeSelectedRegister(false);
      }
    }
  }, [currentUser]); /* eslint-disable-line */

  const changeSelectedRegister = (item) => {
    setSelectedRegister({
      selected: false,
      options: false
    });
    if(item !== false) {
      setTimeout(() => {
        setSelectedRegister(item);
      }, 100);
    }
  };

  const dynamicOptions = [
    {
      id: "mrg-option-client",
      title: t("Title.Client"),
      icon: <i className="fas fa-building" />,
      onClick: () => {
        const id = "mrg-option-client";
        if(selectedRegister.selected === id) {
          return setSelectedRegister(false);
        }
        changeSelectedRegister({
          selected: id,
          options: {
            api: new ClientService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <ClientForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:clients", "create:clients:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <ClientForm options={{ client: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:clients", "update:clients:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <ClientContract options={{ client: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "read:clients", "read:clients:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Contract")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-file-contract" /></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Contract")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <ClientLending options={{ client: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "read:clients", "read:clients:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Lending")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-file-contract" /></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Lending")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <ClientTradingTerm options={{ client: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "read:clients", "read:clients:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.TradingTerm")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-file-signature" /></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.TradingTerm")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <ClientAuthorizationTerm options={{ client: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "read:clients", "read:clients:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.AuthorizationTerm")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-file-signature" /></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.AuthorizationTerm")
              }
            ],
            title: t("Title.Client"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:registers:clients"], every: false })
          }
        });
      },
      selected: () => selectedRegister.selected === "mrg-option-client",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers:clients"], every: false })
    },
    {
      id: "mrg-option-profile",
      title: t("Title.Profile"),
      icon: <i className="fas fa-id-badge" />,
      onClick: () => {
        const id = "mrg-option-profile";
        if(selectedRegister.selected === id) {
          return setSelectedRegister(false);
        }
        changeSelectedRegister({
          selected: id,
          options: {
            api: new ProfileService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <ProfileForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:profiles", "create:profiles:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <ProfileForm options={{ profile: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:profiles", "update:profiles:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              }
            ],
            title: t("Title.Profile"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:registers:profiles"], every: false })
          }
        });
      },
      selected: () => selectedRegister.selected === "mrg-option-profile",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers:profiles"], every: false })
    },
    {
      id: "mrg-option-user",
      title: t("Title.User"),
      icon: <i className="fas fa-user" />,
      onClick: () => {
        const id = "mrg-option-user";
        if(selectedRegister.selected === id) {
          return setSelectedRegister(false);
        }
        changeSelectedRegister({
          selected: id,
          options: {
            api: new UserService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <UserForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:users", "create:users:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <UserForm options={{ user: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:users", "update:users:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              }
            ],
            title: t("Title.User"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:registers:users"], every: false })
          }
        });
      },
      selected: () => selectedRegister.selected === "mrg-option-user",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers:users"], every: false })
    },
    {
      id: "mrg-option-workingWeek",
      title: t("Title.WorkingWeek"),
      icon: <i className="fa-solid fa-calendar-week" />,
      onClick: () => {
        const id = "mrg-option-workingWeek";
        if(selectedRegister.selected === id) {
          return setSelectedRegister(false);
        }
        changeSelectedRegister({
          selected: id,
          options: {
            api: new WorkingWeekService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <WorkingWeekForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:workingWeeks", "create:workingWeeks:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <WorkingWeekForm options={{ workingWeek: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:workingWeeks", "update:workingWeeks:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              }
            ],
            title: t("Title.WorkingWeek"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:registers:workingWeeks"], every: false })
          }
        });
      },
      selected: () => selectedRegister.selected === "mrg-option-workingWeek",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers:workingWeeks"], every: false })
    },
    {
      id: "mrg-option-driver",
      title: t("Title.Driver"),
      icon: <i className="fas fa-user-tag" />,
      onClick: () => {
        const id = "mrg-option-driver";
        if(selectedRegister.selected === id) {
          return setSelectedRegister(false);
        }
        changeSelectedRegister({
          selected: id,
          options: {
            api: new DriverService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <DriverForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:drivers", "create:drivers:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <DriverForm options={{ driver: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:drivers", "update:drivers:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              }
            ],
            title: t("Title.Driver"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:registers:drivers"], every: false })
          }
        });
      },
      selected: () => selectedRegister.selected === "mrg-option-driver",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers:drivers"], every: false })
    },
    {
      id: "mrg-option-chip",
      title: t("Title.Chip"),
      icon: <i className="fas fa-sim-card" />,
      onClick: () => {
        const id = "mrg-option-chip";
        if(selectedRegister.selected === id) {
          return setSelectedRegister(false);
        }
        changeSelectedRegister({
          selected: id,
          options: {
            api: new ChipService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <ChipForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:chips", "create:chips:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <ChipForm options={{ chip: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:chips", "update:chips:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              }
            ],
            title: t("Title.Chip"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:registers:chips"], every: false })
          }
        });
      },
      selected: () => selectedRegister.selected === "mrg-option-chip",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers:chips"], every: false })
    },
    {
      id: "mrg-option-rfid",
      title: t("Title.Rfid"),
      icon: <i className="fas fa-id-card-alt" />,
      onClick: () => {
        const id = "mrg-option-rfid";
        if(selectedRegister.selected === id) {
          return setSelectedRegister(false);
        }
        changeSelectedRegister({
          selected: id,
          options: {
            api: new RfidService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <RfidForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:rfids", "create:rfids:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <RfidForm options={{ rfid: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:rfids", "update:rfids:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              }
            ],
            title: t("Title.Rfid"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:registers:rfids"], every: false })
          }
        });
      },
      selected: () => selectedRegister.selected === "mrg-option-rfid",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers:rfids"], every: false })
    },
    {
      id: "mrg-option-chassi",
      title: t("Title.Chassi"),
      icon: <i className="fas fa-cubes" />,
      onClick: () => {
        const id = "mrg-option-chassi";
        if(selectedRegister.selected === id) {
          return setSelectedRegister(false);
        }
        changeSelectedRegister({
          selected: id,
          options: {
            api: new ChassiService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <ChassiForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:chassis", "create:chassis:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <ChassiForm options={{ chassi: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:chassis", "update:chassis:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              }
            ],
            title: t("Title.Chassi"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:registers:chassis"], every: false })
          }
        });
      },
      selected: () => selectedRegister.selected === "mrg-option-chassi",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers:chassis"], every: false })
    },
    {
      id: "mrg-option-tracker",
      title: t("Title.Tracker"),
      icon: <i className="fas fa-broadcast-tower" />,
      onClick: () => {
        const id = "mrg-option-tracker";
        if(selectedRegister.selected === id) {
          return setSelectedRegister(false);
        }
        changeSelectedRegister({
          selected: id,
          options: {
            api: new TrackerService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <TrackerForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:trackers", "create:trackers:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <TrackerForm options={{ tracker: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:trackers", "update:trackers:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              }
            ],
            title: t("Title.Tracker"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:registers:trackers"], every: false })
          }
        });
      },
      selected: () => selectedRegister.selected === "mrg-option-tracker",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers:trackers"], every: false })
    },
    {
      id: "mrg-option-contract",
      title: t("Title.Contract"),
      icon: <i className="fas fa-file-contract" />,
      onClick: () => {
        const id = "mrg-option-contract";
        if(selectedRegister.selected === id) {
          return setSelectedRegister(false);
        }
        changeSelectedRegister({
          selected: id,
          options: {
            api: new ContractService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <ContractForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:contracts", "create:contracts:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <ContractForm options={{ contract: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:contracts", "update:contracts:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              }
            ],
            title: t("Title.Contract"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:registers:contracts"], every: false })
          }
        });
      },
      selected: () => selectedRegister.selected === "mrg-option-contract",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers:contracts"], every: false })
    },
    {
      id: "mrg-option-patrimony",
      title: t("Title.Patrimony"),
      icon: <i className="fas fa-car" />,
      onClick: () => {
        const id = "mrg-option-patrimony";
        if(selectedRegister.selected === id) {
          return setSelectedRegister(false);
        }
        changeSelectedRegister({
          selected: id,
          options: {
            api: new PatrimonyService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <PatrimonyForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:patrimonies", "create:patrimonies:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <PatrimonyForm options={{ patrimony: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:patrimonies", "update:patrimonies:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <PatrimonyImaCertificate options={{ patrimony: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 && isAutomobileBoardAutoVaccum(selectedRows[0]) ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "read:patrimonies", "read:patrimonies:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.ImaCertificate")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-file-contract" /></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.ImaCertificate")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <PatrimonyInstallationStatement options={{ patrimony: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 && isAutomobileBoardAutoVaccum(selectedRows[0]) ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "read:patrimonies", "read:patrimonies:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.InstallationStatement")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-file-contract" /></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.InstallationStatement")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <PatrimonyMaintenanceSchedulingTerm options={{ patrimony: JSON.parse(JSON.stringify(selectedRows)), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  var checkEnabled = selectedRows.length > 0;
                  if (checkEnabled) {
                    selectedRows.map(selectedRow => {
                      if (!isAutomobileBoardAutoVaccum(selectedRow)) {
                        checkEnabled = false;
                      }
                      return selectedRow;
                    });
                  }
                  return (isScopes({ currentUser, scopes: ["is:master", "read:patrimonies", "read:patrimonies:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.MaintenanceSchedulingTerm")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-file-contract" /></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.MaintenanceSchedulingTerm")
              }
            ],
            title: t("Title.Patrimony"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:registers:patrimonies"], every: false })
          }
        });
      },
      selected: () => selectedRegister.selected === "mrg-option-patrimony",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers:patrimonies"], every: false })
    },
    {
      id: "mrg-option-patrimony-test",
      title: t("Title.PatrimonyTest"),
      icon: <i className="fas fa-vial" />,
      onClick: () => {
        const id = "mrg-option-patrimony-test";
        if(selectedRegister.selected === id) {
          return setSelectedRegister(false);
        }
        changeSelectedRegister({
          selected: id,
          options: {
            api: new PatrimonyTestService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <PatrimonyTestForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:patrimonyTests", "create:patrimonyTests:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <PatrimonyTestForm options={{ patrimonyTest: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:patrimonyTests", "update:patrimonyTests:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              }
            ],
            title: t("Title.PatrimonyTest"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:registers:patrimonyTests"], every: false })
          }
        });
      },
      selected: () => selectedRegister.selected === "mrg-option-patrimony-test",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers:patrimonyTests"], every: false })
    },
    {
      id: "mrg-option-virtual-fence",
      title: t("Title.VirtualFence"),
      icon: <i className="fas fa-sign" />,
      onClick: () => {
        const id = "mrg-option-virtual-fence";
        if(selectedRegister.selected === id) {
          return setSelectedRegister(false);
        }
        changeSelectedRegister({
          selected: id,
          options: {
            api: new VirtualFenceService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <VirtualFenceForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:virtualFences", "create:virtualFences:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <VirtualFenceForm options={{ virtualFence: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:virtualFences", "update:virtualFences:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              }
            ],
            title: t("Title.VirtualFence"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:registers:virtualFences"], every: false })
          }
        });
      },
      selected: () => selectedRegister.selected === "mrg-option-virtual-fence",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers:virtualFences"], every: false })
    },
    {
      id: "mrg-option-support-point",
      title: t("Title.SupportPoint"),
      icon: <i className="fas fa-map-location" />,
      onClick: () => {
        const id = "mrg-option-support-point";
        if(selectedRegister.selected === id) {
          return setSelectedRegister(false);
        }
        changeSelectedRegister({
          selected: id,
          options: {
            api: new SupportPointService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <SupportPointForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:supportPoints", "create:supportPoints:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <SupportPointForm options={{ supportPoint: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:supportPoints", "update:supportPoints:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              }
            ],
            title: t("Title.SupportPoint"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:registers:supportPoints"], every: false })
          }
        });
      },
      selected: () => selectedRegister.selected === "mrg-option-support-point",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers:supportPoints"], every: false })
    }
  ];

  const fixedOptions = [{
    id: "mrg-option-close",
    title: t("Title.Close"),
    icon: <i className="fas fa-times" />,
    onClick: () => {
      menuRegistersHandleClose();
      menuHandleOpen();
    },
    selected: () => true,
    scoped: () => true
  }];

  return (
    <div className="menu-registers">
      <div className={`mrg-registers-options-absolute ${selectedRegister.selected ? "active" : ""}`}>
        <div className="mrg-registers-options-absolute-content">
          <div className="mrg-roa-content-title">{selectedRegister.selected && <h3>{selectedRegister.options.title}</h3>}</div>
          <div className="mrg-roa-content-body">{selectedRegister.selected && <Grid options={selectedRegister.options} />}</div>
        </div>
      </div>
      <div className="mrg-registers-options-fixed">
        <ul>
          {
            fixedOptions.map(option => {
              if(option.scoped()) {
                return (
                  <MenuRegistersItem key={option.id} {...option} onClick={option.onClick} />
                );
              }
              return null;
            })
          }
        </ul>
      </div>
      <div className="mrg-registers-options-dynamic">
        <ul>
          {
            dynamicOptions.map(option => {
              if(option.scoped()) {
                return (
                  <MenuRegistersItem key={option.id} {...option} onClick={option.onClick} />
                );
              }
              return null;
            })
          }
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, MenusActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(MenuRegisters));
