import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

import { Creators as UsersActions } from "./../../../store/ducks/users";

import Loader from "./../../Loader";
import Login from "./../../Login";
import Map from "./../../Map";
import Menu from "./../../Menu";
import MenuFooter from "./../../MenuFooter";
import MenuOptions from "./../../MenuOptions";
import Notice from "./../../NoticeList";
import QRCodeDownload from "./../../QRCodeDownload";
import Storage from "./../../../services/Storage";
import WebSocket from "./../../WebSocket";

import { checkToken } from "./../../../services/Auth";
import { onMessageListener } from "./../../../firebaseInit";
import { requestFirebaseNotificationPermission } from "./../../../firebaseInit";

const MainHome = ({
  props: {
    currentUser,
    loader,
    qrcode
  },
  funcs: {
    setCurrentToken,
    setCurrentUser
  }
}) => {

  const [asyncRenderExec, setAsyncRender] = useState(false);
  const [renderMap, setRenderMap] = useState(false);
  const [renderWebsocket, setRenderWebsocket] = useState(false);
  const [refreshWebsocket, setRefreshWebsocket] = useState(false);
  const [requestPermissionNotification, setRequestPermissionNotification] = useState(false);

  useEffect(() => {
    if(!asyncRenderExec) {
      setAsyncRender(true);
      asyncRender();
    }
  }, []); /* eslint-disable-line */

  useEffect(() => {
    if(currentUser) {
      asyncCheckStorage();
      if(!refreshWebsocket) {
        setTimeout(() => {
          setRefreshWebsocket(true);
        }, 100);
      }
    }
    else {
      if(renderWebsocket) {
        setTimeout(() => {
          setRefreshWebsocket(false);
        }, 100);
      }
    }
  }, [currentUser]); /* eslint-disable-line */

  const asyncCheckStorage = () => {
    if(currentUser) {
      const stg = new Storage();
      if(!stg.check()) {
        setCurrentUser(false);
      }
      setTimeout(asyncCheckStorage, 2000);
    }
  };

  const asyncCheckUser = async () => {
    if(currentUser) {
      const user = await checkToken({ token: currentUser.token });
      if(user.success) {
        return setCurrentUser(user.data);
      }
      return setCurrentUser(false);
    }
  };

  const asyncRender = async () => {
    await asyncCheckUser();
    if(!renderMap) {
      setTimeout(() => {
        setRenderMap(true);
      }, 4500);
    }
    if(!renderWebsocket) {
      setTimeout(() => {
        setRenderWebsocket(true);
      }, 5000);
    }
  };

  const asyncRequestNotificationPermission = () => {
    setRequestPermissionNotification(true);
    requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        setCurrentToken(firebaseToken);
      })
      .catch((err) => {
        return err;
      });
  };

  if(requestPermissionNotification) {
    onMessageListener()
      .then((payload) => {
        return payload;
      })
      .catch((err) => {
        return err;
      });
  }

  return (
    <main className="main-home">
      {renderMap && <Map />}
      {!currentUser && <Login />}
      {currentUser && <Menu />}
      {currentUser && <MenuOptions />}
      <Notice />
      {currentUser && <MenuFooter />}
      {renderWebsocket && refreshWebsocket && <WebSocket />}
      {loader && <Loader />}
      {qrcode && <QRCodeDownload />}
      {!requestPermissionNotification && asyncRequestNotificationPermission()}
    </main>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    loader: state.loaders.loader,
    qrcode: state.qrcodes.qrcode
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(UsersActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MainHome);
