import React, { memo, useEffect, useState } from "react";
import {
  Form
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";

import { trim } from "./../../utils/String";
import { useForceUpdate } from "./../../utils/Hooks";

const Grid = ({
  options,
  props: { currentUser },
  funcs: { enableLoader, disableLoader }
}) => {
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();

  const {
    actions: __actions = [],
    api: __api = false,
    cols: __cols = [],
    docs: __docs = [],
    limit: __limit = 50,
    limits: __limits = [1, 10, 25, 50, 100],
    multipleSelect: __multipleSelect = false,
    paginate: __paginate = true,
    rows: __rows = [],
    search: __search = true,
    onSelect: __onSelect = () => {},
    onDeselect: __onDeselect = () => {},
    onSearch: __onSearch = () => {}
  } = options;

  const [actions, setActions] = useState(__actions);
  const [actionShow, setActionShow] = useState(false);
  const [api] = useState(__api);
  const [cols, setCols] = useState(!__api ? __cols : []);
  const [docs, setDocs] = useState(!__api ? __docs : []);
  const [limit, setLimit] = useState(__paginate ? __limit : 1000000);
  const [limits] = useState(__paginate ? __limits : []);
  const [multipleSelect] = useState(__multipleSelect);
  const [page, setPage] = useState(1);
  const [pageBoxId] = useState(`grid-page-box-${(Math.random().toString(36).substr(2, 9))}`);
  const [pages, setPages] = useState(__paginate ? false : 1);
  const [paginate] = useState(__paginate);
  const [rows, setRows] = useState(!__api ? __rows : []);
  const [searchedDocs, setSearchedDocs] = useState([]);
  const [searchedRows, setSearchedRows] = useState([]);
  const [search] = useState(__search);
  const [searchWord, setSearchWord] = useState("");
  const [searching, setSearching] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setTimeout(async () => {
      await loadData({ limit, page, search: searchWord });
    }, 100);
  }, []); /* eslint-disable-line */

  useEffect(() => {
    if(!api) {
      if(trim(searchWord) === "") {
        setSearching(false);
        setSearchedDocs([]);
        setSearchedRows([]);
      }
      else {
        const { docs, rows } = __onSearch(searchWord);
        setSearching(true);
        setSearchedDocs(docs);
        setSearchedRows(rows);
      }
    }
    else {
      setTimeout(async () => {
        await loadData({ limit, page, search: searchWord });
      }, 100);
    }
  }, [searchWord]); /* eslint-disable-line */

  const checkRowSelected = ({ row, boolean = true } = {}) => {
    try {
      if(boolean === false) {
        return selectedRows.findIndex(x => x.id === row.id);
      }
      return (selectedRows.findIndex(x => x.id === row.id) !== -1) ? true : false;
    } catch(error) {
      return false;
    }
  };

  const loadData = async ({ limit, page, search }) => {
    enableLoader();
    if(api) {
      const data = await api.findAllGrid({
        data: {
          limit,
          page,
          search
        },
        token: currentUser.token
      });
      if(data) {
        const { cols, docs, pages, rows } = data;
        setCols(cols);
        setDocs(docs);
        setPages(pages);
        setRows(rows);
      }
    }
    else {
      setPages(Math.ceil(searching ? searchedDocs.length : docs.length / limit));
    }
    disableLoader();
  };

  const onChangeLimit = ({ limit, page }) => {
    setLimit(limit);
    setPage(page);
    loadData({ limit, page, search: searchWord });
  };

  const onChangePage = ({ page, pageBox = false } = {}) => {
    if(page !== false) {
      setPage(page);
      loadData({ limit, page, search: searchWord });
    }
    if(pageBox) {
      document.getElementById(pageBoxId).value = null;
    }
  };

  const onChangePageCheck = ({ page }) => {
    if(page !== false) {
      page = parseInt(page);
      if(page >= 1 && page <= pages) {
        return page;
      }
    }
    return false;
  };

  const renderActions = () => {
    return (
      <ul className="grid-footer-actions">
        { renderActionsItens() }
      </ul>
    );
  };

  const renderActionsContents = () => {
    const index = actions.findIndex(x => x.show === true);
    if(index !== -1) {
      return actions[index].content({ resetActionsForced, selectedRows });
    }
    return null;
  };

  const renderActionsItens = () => {
    return actions.map((action, index) => {
      return action.render({ index, selectedRows, showAction });
    });
  };

  const renderActionsTitles = () => {
    const index = actions.findIndex(x => x.show === true);
    if(index !== -1) {
      return (
        <h3>{actions[index].title(this, index)}</h3>
      );
    }
    return null;
  };

  const renderCols = () => {
    return cols.map((col, index) => {
      return (
        <td style={{ width: col.width }} data-order="" data-type={col.type} data-index={index} key={`grid-header-${index}-${(Math.random().toString(36).substr(2, 9))}}`}><div>{t(col.label)}</div></td>
      );
    });
  };

  const renderPages = () => {
    if(pages !== false && paginate) {
      return (
        <ul className="grid-footer-pagination">
          { renderPagesFirst() }
          { renderPagesBack() }
          { renderPagesNow() }
          { renderPagesNext() }
          { renderPagesLast() }
          { renderPagesLimit() }
        </ul>
      );
    }
    return null;
  };

  const renderPagesBack = () => {
    let back = false;
    if((page - 1) >= 1) {
      back = page - 1;
    }
    return (
      <li className={`${back !== false ? "active" : ""}`} onClick={e => onChangePage({ page: back })}>
        <i className="fas fa-chevron-left"></i>
      </li>
    );
  };

  const renderPagesFirst = () => {
    let first = false;
    if(page > 1) {
      first = 1;
    }
    return (
      <li className={`${first !== false ? "active" : ""}`} onClick={e => onChangePage({ page: first })}>
        <i className="fas fa-angle-double-left"></i>
      </li>
    );
  };

  const renderPagesLast = () => {
    let last = false;
    if(page !== pages) {
      last = pages;
    }
    return (
      <li className={`${last !== false ? "active" : ""}`} onClick={e => onChangePage({ page: last })}>
        <i className="fas fa-angle-double-right"></i>
      </li>
    );
  };

  const renderPagesLimit = () => {
    return (
      <li className="active limit">
        <Form.Control
          as="select"
          onChange={e => onChangeLimit({ limit: parseInt(e.target.value), page: 1 })}
          value={limit}
        >
          { renderPagesLimitItens() }
        </Form.Control>
      </li>
    );
  };

  const renderPagesLimitItens = () => {
    return limits.map((limit) => {
      return (
        <option value={limit} key={`grid-footer-limit-${limit}-${(Math.random().toString(36).substr(2, 9))}}`}>{limit}</option>
      );
    });
  };

  const renderPagesNext = () => {
    let next = false;
    if((page + 1) <= pages) {
      next = page + 1;
    }
    return (
      <li className={`${next !== false ? "active" : ""}`} onClick={e => onChangePage({ page: next })}>
        <i className="fas fa-chevron-right"></i>
      </li>
    );
  };

  const renderPagesNow = () => {
    return (
      <li>
        <Form.Control
          id={pageBoxId}
          type="text"
          placeholder={page}
          onKeyUp={e => {
            if(e.keyCode === 13) {
              onChangePage({ page: onChangePageCheck({ page: e.target.value }), pageBox: true });
            }
          }}
          onBlur={e => {
            onChangePage({ page: onChangePageCheck({ page: e.target.value }), pageBox: true });
          }}
        />
      </li>
    );
  };

  const renderRows = () => {
    let rowsAux = rows;
    if(!api) {
      const offset = ((page - 1) * limit);
      const offsetLimit = limit * page;
      rowsAux = searching ? searchedRows.slice(offset, offsetLimit) : rows.slice(offset, offsetLimit);
    }
    return rowsAux.map((row, rindex) => {
      const mod = (rindex % 2) === 1;
      const __checkRow = typeof row[0].id !== "undefined" && row[0].id !== "" ? docs.find(x => x.id === row[0].id) : docs[rindex];
      return (
        <tr className={`${checkRowSelected({ row: __checkRow }) ? "active" : ""} ${mod ? "grid-odd-class" : ""}`} key={`grid-body-${rindex}-${(Math.random().toString(36).substr(2, 9))}}`} onClick={e => selectRow({ e, row: __checkRow })}>
          {
            cols.map((col, cindex) => {
              return (
                <td style={{ width: col.width }} data-order="" data-type={col.type} data-index={rindex} key={`grid-body-item-${rindex}-${(Math.random().toString(36).substr(2, 9))}}`}><div>{renderRowsReplace({ selected: checkRowSelected({ row: __checkRow }), value: row[cindex].value })}</div></td>
              );
            })
          }
        </tr>
      );
    });
  };

  const renderRowsReplace = ({ selected, value }) => {
    const selectedMessage = (selected ? "Title.Yes" : "Title.Not");
    value = String(value).replace(/%{ITEM.SELECTED}%/g, t(selectedMessage));
    var textsReplace = value.match(/(%{+[a-zA-Z0-9._-]+}%)/gi);
    if(textsReplace) {
      for (var i = 0; i < textsReplace.length; i++) {
        var str = textsReplace[i].replace(/%{/g, "");
        str = str.replace(/}%/g, "");
        var replace = new RegExp(textsReplace[i], "g");
        value = value.replace(replace, t(str));
      }
    }
    return value;
  };

  const renderSearch = () => {
    if(search) {
      return (
        <ul className="grid-footer-search">
          <li className="active">
            <Form.Control
              id=""
              type="text"
              placeholder={t("Placeholder.Search")}
              onChange={e => setSearchWord(e.target.value)}
              value={searchWord}
            />
          </li>
        </ul>
      );
    }
    return null;
  };

  const resetActions = () => {
    actions.map(action => {
      action.show = false;
      return action;
    });
    setActions(actions);
    setActionShow(false);
  };

  const resetActionsForced = () => {
    loadData({ limit, page, search: searchWord });
    resetActions();
    setSelectedRows([]);
  };

  const selectRow = ({ e, row, ignoreEvent = false } = {}) => {
    const index = checkRowSelected({ row, boolean: false });
    if(index !== -1) {
      selectedRows.splice(index, 1);
    }
    if(multipleSelect || e.shiftKey) {
      if(index === -1) {
        selectedRows.push(row);
      }
    }
    else {
      if(index === -1) {
        selectedRows[0] = row;
      }
      if (selectedRows.length > 1) {
        selectedRows.splice(1);
      }
    }
    setSelectedRows(selectedRows);
    forceUpdate();
    if(ignoreEvent === false) {
      if(index === -1) {
        __onSelect(row);
      }
      else {
        __onDeselect(row);
      }
    }
  };

  const showAction = ({ index }) => {
    resetActions();
    actions[index].show = true;
    setActions(actions);
    setActionShow(true);
  };

  return (
    <div className="grid-all">
      <div className="grid-header">
        <table>
          <thead>
            <tr>
              { renderCols() }
            </tr>
          </thead>
        </table>
      </div>
      <div className="grid-body">
        <table>
          <tbody>
            { renderRows() }
          </tbody>
        </table>
      </div>
      <div className="grid-footer">
        { renderActions() }
        { renderSearch() }
        { renderPages() }
      </div>
      <div className={`grid-action-content ${actionShow ? "active" : ""}`}>
        <div className="grid-action-content-title">
          <div className="menu-icon-action-content" onClick={resetActions}>
            <img className="menu-icon-close-action-content" src={process.env.PUBLIC_URL + "/images/menu-icon-close.png"} alt="" />
          </div>
          { renderActionsTitles() }
        </div>
        <div className="grid-action-content-data">
          { renderActionsContents() }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Grid));
