import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Spinner
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as NotificationsActions } from "./../../store/ducks/notifications";

import { isScopes } from "./../../services/Auth";
import { getDefaultOptionsPatrimony } from "./../../utils/Notification";
import { clearObject, mergeObject } from "./../../utils/Object";
import { ucFirstAll } from "./../../utils/String";
import { jsonToForm } from "./../../utils/User";

import Item from "./Item";
import Option from "./../Option";

import UserService from "./../../services/User";
import UserSchema from "./../../services/User/Schema";

const Notification = ({
  options,
  props: {
    currentUser,
    patrimonies,
    patrimoniesSearchSelected
  },
  funcs: {
    addNotice,
    enableLoader,
    disableLoader
  }
}) => {
  const { t } = useTranslation();
  const userService = new UserService();

  const {
    user: __user = {}
  } = options;

  const [user, setUser] = useState(jsonToForm(mergeObject(JSON.parse(JSON.stringify(UserSchema)), {
    ...__user,
    password: ""
  })));

  useEffect(() => {
    const newUser = JSON.parse(JSON.stringify(currentUser));
    setUser({
      ...newUser,
      password: ""
    });
  }, [currentUser]);

  const [running, setRunning] = useState(false);

  const [selectedNotification, setSelectedNotification] = useState({
    selected: false,
    options: false,
    values: false
  });

  const buttonDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const changeSelectedNotification = (item) => {
    setSelectedNotification({
      selected: false,
      options: false,
      values: false
    });
    if(item !== false) {
      setTimeout(() => {
        const options = getDefaultOptionsPatrimony();
        item.options = mergeObject(options, item.options);
        setSelectedNotification(item);
      }, 100);
    }
  };

  const getPatrimoniesSearchSelected = () => {
    let patrimoniesSelecteds = [];
    if(patrimonies.length !== patrimoniesSearchSelected.length) {
      if(patrimoniesSearchSelected.length === 0) {
        return false;
      }
      patrimoniesSearchSelected.map(item => {
        return patrimoniesSelecteds.push(item.id);
      });
    }
    return patrimoniesSelecteds;
  };

  const getUserProfile = () => {
    const { profile } = user;
    const patrimoniesSelected = getPatrimoniesSearchSelected();
    const notificationIndex = profile.notification.group.findIndex(x => x.scope === selectedNotification.selected);
    if(patrimoniesSelected === false) {
      if(notificationIndex !== -1) {
        profile.notification.group.splice(notificationIndex, 1);
      }
    }
    else {
      if(notificationIndex !== -1) {
        profile.notification.group[notificationIndex].patrimonies = patrimoniesSelected;
      }
      else {
        profile.notification.group.push({
          patrimonies: patrimoniesSelected,
          scope: selectedNotification.selected
        });
      }
    }
    return profile;
  };

  const getUserPreProfile = ({ id }) => {
    const { profile } = user;
    const notificationIndex = profile.notification.group.findIndex(x => x.scope === id);
    if(notificationIndex === -1) {
      return false;
    }
    return profile.notification.group[notificationIndex].patrimonies;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let response = await userService.update({
        id: user.id,
        data: handleSubmitForm({ user }),
        token: currentUser.token
      });

      if(response.success) {
        addNotice({
          title: t("Title.Notifications"),
          content: t("Success.Update")
        });
      }
      else {
        addNotice({
          title: t("Title.Notifications"),
          content: t(`Error.Notifications.${response.error.type}.${ucFirstAll(response.error.details[0].path)}`)
        });
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ user }) => {
    let newUser = Object.assign({}, JSON.parse(JSON.stringify(user)));
    newUser = clearObject({ data: newUser });
    newUser.profile = getUserProfile();
    newUser.id = undefined;
    return newUser;
  };

  const notifications = [
    {
      id: "notifications:patrimonies:general:speeding",
      title: t("Title.Speeding"),
      icon: <i className="fas fa-tachometer-alt" />,
      onClick: () => {
        const id = "notifications:patrimonies:general:speeding";
        if(selectedNotification.selected === id) {
          return setSelectedNotification(false);
        }
        changeSelectedNotification({
          selected: id,
          options: {
            filterMultiple: true,
            filterPreSelected: getUserPreProfile({ id })
          },
          values: {
            id: "notifications:patrimonies:general:speeding",
            title: t("Title.Speeding")
          }
        });
      },
      selected: () => selectedNotification.selected === "notifications:patrimonies:general:speeding",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "notifications:patrimonies:general:speeding"], every: false })
    },
    {
      id: "notifications:patrimonies:general:ignition",
      title: t("Title.Ignition"),
      icon: <i className="fas fa-key" />,
      onClick: () => {
        const id = "notifications:patrimonies:general:ignition";
        if(selectedNotification.selected === id) {
          return setSelectedNotification(false);
        }
        changeSelectedNotification({
          selected: id,
          options: {
            filterMultiple: true,
            filterPreSelected: getUserPreProfile({ id })
          },
          values: {
            id: "notifications:patrimonies:general:ignition",
            title: t("Title.Ignition")
          }
        });
      },
      selected: () => selectedNotification.selected === "notifications:patrimonies:general:ignition",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "notifications:patrimonies:general:ignition"], every: false })
    },
    {
      id: "notifications:patrimonies:general:ignitionOutHour",
      title: t("Title.IgnitionOutHour"),
      icon: <i className="fas fa-key" />,
      onClick: () => {
        const id = "notifications:patrimonies:general:ignitionOutHour";
        if(selectedNotification.selected === id) {
          return setSelectedNotification(false);
        }
        changeSelectedNotification({
          selected: id,
          options: {
            filterMultiple: true,
            filterPreSelected: getUserPreProfile({ id })
          },
          values: {
            id: "notifications:patrimonies:general:ignitionOutHour",
            title: t("Title.IgnitionOutHour")
          }
        });
      },
      selected: () => selectedNotification.selected === "notifications:patrimonies:general:ignitionOutHour",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "notifications:patrimonies:general:ignitionOutHour"], every: false })
    },
    {
      id: "notifications:patrimonies:general:block",
      title: t("Title.Block"),
      icon: <i className="fas fa-lock" />,
      onClick: () => {
        const id = "notifications:patrimonies:general:block";
        if(selectedNotification.selected === id) {
          return setSelectedNotification(false);
        }
        changeSelectedNotification({
          selected: id,
          options: {
            filterMultiple: true,
            filterPreSelected: getUserPreProfile({ id })
          },
          values: {
            id: "notifications:patrimonies:general:block",
            title: t("Title.Block")
          }
        });
      },
      selected: () => selectedNotification.selected === "notifications:patrimonies:general:block",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "notifications:patrimonies:general:block"], every: false })
    },
    {
      id: "notifications:patrimonies:general:panic",
      title: t("Title.Panic"),
      icon: <i className="fas fa-skull-crossbones" />,
      onClick: () => {
        const id = "notifications:patrimonies:general:panic";
        if(selectedNotification.selected === id) {
          return setSelectedNotification(false);
        }
        changeSelectedNotification({
          selected: id,
          options: {
            filterMultiple: true,
            filterPreSelected: getUserPreProfile({ id })
          },
          values: {
            id: "notifications:patrimonies:general:panic",
            title: t("Title.Panic")
          }
        });
      },
      selected: () => selectedNotification.selected === "notifications:patrimonies:general:panic",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "notifications:patrimonies:general:panic"], every: false })
    },
    {
      id: "notifications:patrimonies:general:delayedLocation",
      title: t("Title.DelayedLocation"),
      icon: <i className="fas fa-clock" />,
      onClick: () => {
        const id = "notifications:patrimonies:general:delayedLocation";
        if(selectedNotification.selected === id) {
          return setSelectedNotification(false);
        }
        changeSelectedNotification({
          selected: id,
          options: {
            filterMultiple: true,
            filterPreSelected: getUserPreProfile({ id })
          },
          values: {
            id: "notifications:patrimonies:general:delayedLocation",
            title: t("Title.DelayedLocation")
          }
        });
      },
      selected: () => selectedNotification.selected === "notifications:patrimonies:general:delayedLocation",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "notifications:patrimonies:general:delayedLocation"], every: false })
    },
    {
      id: "notifications:patrimonies:general:maintenanceNeed",
      title: t("Title.MaintenanceNeed"),
      icon: <i className="fas fa-screwdriver-wrench" />,
      onClick: () => {
        const id = "notifications:patrimonies:general:maintenanceNeed";
        if(selectedNotification.selected === id) {
          return setSelectedNotification(false);
        }
        changeSelectedNotification({
          selected: id,
          options: {
            filterMultiple: true,
            filterPreSelected: getUserPreProfile({ id })
          },
          values: {
            id: "notifications:patrimonies:general:maintenanceNeed",
            title: t("Title.MaintenanceNeed")
          }
        });
      },
      selected: () => selectedNotification.selected === "notifications:patrimonies:general:maintenanceNeed",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "notifications:patrimonies:general:maintenanceNeed"], every: false })
    },
    {
      id: "notifications:patrimonies:niple:hatch",
      title: t("Title.Hatch"),
      icon: "A | F | B",
      onClick: () => {
        const id = "notifications:patrimonies:niple:hatch";
        if(selectedNotification.selected === id) {
          return setSelectedNotification(false);
        }
        changeSelectedNotification({
          selected: id,
          options: {
            filterMultiple: true,
            filterNiple: true,
            filterPreSelected: getUserPreProfile({ id })
          },
          values: {
            id: "notifications:patrimonies:niple:hatch",
            title: t("Title.Hatch")
          }
        });
      },
      selected: () => selectedNotification.selected === "notifications:patrimonies:niple:hatch",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "notifications:patrimonies:niple:hatch"], every: false })
    },
    {
      id: "notifications:patrimonies:niple:fraud",
      title: `${t("Title.Niple")}: ${t("Title.Niple.Fraud")}`,
      icon: "B",
      onClick: () => {
        const id = "notifications:patrimonies:niple:fraud";
        if(selectedNotification.selected === id) {
          return setSelectedNotification(false);
        }
        changeSelectedNotification({
          selected: id,
          options: {
            filterMultiple: true,
            filterNiple: true,
            filterPreSelected: getUserPreProfile({ id })
          },
          values: {
            id: "notifications:patrimonies:niple:fraud",
            title: `${t("Title.Niple")}: ${t("Title.Niple.Fraud")}`
          }
        });
      },
      selected: () => selectedNotification.selected === "notifications:patrimonies:niple:fraud",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "notifications:patrimonies:niple:fraud"], every: false })
    },
    {
      id: "notifications:patrimonies:niple:operation",
      title: `${t("Title.Niple")}: ${t("Title.Niple.Operation")}`,
      icon: "F | D | C",
      onClick: () => {
        const id = "notifications:patrimonies:niple:operation";
        if(selectedNotification.selected === id) {
          return setSelectedNotification(false);
        }
        changeSelectedNotification({
          selected: id,
          options: {
            filterMultiple: true,
            filterNiple: true,
            filterPreSelected: getUserPreProfile({ id })
          },
          values: {
            id: "notifications:patrimonies:niple:operation",
            title: `${t("Title.Niple")}: ${t("Title.Niple.Operation")}`
          }
        });
      },
      selected: () => selectedNotification.selected === "notifications:patrimonies:niple:operation",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "notifications:patrimonies:niple:operation"], every: false })
    },
    {
      id: "notifications:patrimonies:niple:test",
      title: `${t("Title.Niple")}: ${t("Title.Niple.Test")}`,
      icon: "T",
      onClick: () => {
        const id = "notifications:patrimonies:niple:test";
        if(selectedNotification.selected === id) {
          return setSelectedNotification(false);
        }
        changeSelectedNotification({
          selected: id,
          options: {
            filterMultiple: true,
            filterNiple: true,
            filterPreSelected: getUserPreProfile({ id })
          },
          values: {
            id: "notifications:patrimonies:niple:test",
            title: `${t("Title.Niple")}: ${t("Title.Niple.Test")}`
          }
        });
      },
      selected: () => selectedNotification.selected === "notifications:patrimonies:niple:test",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "notifications:patrimonies:niple:test"], every: false })
    },
    {
      id: "notifications:patrimonies:niple:failure",
      title: `${t("Title.Niple")}: ${t("Title.Niple.Failure")}`,
      icon: <i className="fas fa-bug" />,
      onClick: () => {
        const id = "notifications:patrimonies:niple:failure";
        if(selectedNotification.selected === id) {
          return setSelectedNotification(false);
        }
        changeSelectedNotification({
          selected: id,
          options: {
            filterMultiple: true,
            filterNiple: true,
            filterPreSelected: getUserPreProfile({ id })
          },
          values: {
            id: "notifications:patrimonies:niple:failure",
            title: `${t("Title.Niple")}: ${t("Title.Niple.Failure")}`
          }
        });
      },
      selected: () => selectedNotification.selected === "notifications:patrimonies:niple:failure",
      scoped: () => false
      //isScopes({ currentUser, scopes: ["is:master", "notifications:patrimonies:niple:failure"], every: false })
    },
    {
      id: "notifications:patrimonies:general:virtualFence",
      title: t("Title.VirtualFence"),
      icon: <i className="fas fa-sign" />,
      onClick: () => {
        const id = "notifications:patrimonies:general:virtualFence";
        if(selectedNotification.selected === id) {
          return setSelectedNotification(false);
        }
        changeSelectedNotification({
          selected: id,
          options: {
            filterMultiple: true,
            filterPreSelected: getUserPreProfile({ id })
          },
          values: {
            id: "notifications:patrimonies:general:virtualFence",
            title: t("Title.VirtualFence")
          }
        });
      },
      selected: () => selectedNotification.selected === "notifications:patrimonies:general:virtualFence",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "notifications:patrimonies:general:virtualFence"], every: false })
    },
  ];

  useEffect(() => {
    if(selectedNotification.selected) {
      const notification = notifications.find(x => x.id === selectedNotification.selected);
      if(notification && !notification.scoped()) {
        changeSelectedNotification(false);
      }
    }
  }, [notifications]); /* eslint-disable-line */

  return (
    <div className="user-profiles-notification">
      <Card>
        <Card.Header>{t("Title.Notifications")}</Card.Header>
        <Card.Body>
          <div className="user-profiles-notification-all">
            {
              <ul className="user-profiles-notification-lists">
                {
                  notifications.map(notification => {
                    return notification.scoped() ? (
                      <Item key={notification.id} {...notification} onClick={notification.onClick} />
                    ) : null;
                  })
                }
              </ul>
            }
          </div>
        </Card.Body>
      </Card>
      <div className="user-profiles-notification-options">
        {
          selectedNotification.selected ? (
            <>
              <Option {...selectedNotification} />
              <Form.Group className="default-form-button">
                <Button
                  variant="dark"
                  type="button"
                  disabled={buttonDisabled()}
                  onClick={handleSubmit}
                >
                  {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
                </Button>
              </Form.Group>
            </>
          ) : (
            <h3 className="user-profiles-notification-not-selected">{t("Title.Notifications.NotSelected")}</h3>
          )
        }
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    patrimonies: state.patrimonies.patrimonies,
    patrimoniesSearchSelected: state.patrimonies.patrimoniesSearchSelected
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Notification));
